import {Box, Tab, Tabs, Typography} from "@mui/material";
import SearchFilter from "../../filter/SearchFilter";
import {a11yProps, CustomTabPanel} from "../../../utils/TabsUtils";
import {SyntheticEvent, useEffect, useState} from "react";
import {loadItem} from "../../../modules/project/features/actions";
import {useAppDispatch} from "../../../app/hooks";
import CatalogItemSkuListing from "../CatalogItemSkuListing";
import {api} from "../../../utils/Axios";
import Loader from "../../Loader";

const ItemCategoryCatalogueTab = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [skuSearchQuery, setSkuSearchQuery] = useState(props.searchQuery);
    const [appliedFilters, setAppliedFilters] = useState(props.appliedFilters);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemCategoryTabValue, setItemCategoryTabValue] = useState("");
    const [filteredItemCategories, setFilteredItemCategories] = useState([]);
    const [itemCategoriesData, setItemCategoriesData] = useState<any>([]);
    const [itemsData, setItemsData] = useState<any>([]);

    const dispatch = useAppDispatch();

    const handleItemCategorySearchFilter = (query: any) => {
        const queryString = typeof query === 'string' ? query : '';
        setSearchQuery(queryString);
        if (!queryString) {
            setFilteredItemCategories(itemCategoriesData);
            setItemCategoryTabValue(itemCategoriesData[0]?.id || "");
            return;
        }
        const filtered = itemCategoriesData.filter((category: any) =>
            category.name.toLowerCase().includes(queryString?.toLowerCase())
        );
        setFilteredItemCategories(filtered);
        setItemCategoryTabValue(filtered.length > 0 ? filtered[0]?.id || "" : itemCategoriesData[0]?.id || "");
    };

    const handleItemCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setItemCategoryTabValue(newValue);
    };

    const loadItemCategoriesWithFilters = () => {
        if (props.subCategoryTabValue != "") {
            const params = new URLSearchParams({
                searchQuery: skuSearchQuery
            });

            if (Object.keys(appliedFilters).length > 0) {
                Object.entries(appliedFilters).map(([name, value]: any) => {
                    params.append(name, value);
                });
            }
            setIsLoading(true);
            api.get(`/catalogue/item-category/filter-list/${props.subCategoryTabValue}`, {params})
                .then((response) => response.data)
                .then((response: any) => {
                    setItemCategoriesData(response);
                })
                .catch((ex) => {
                    console.log(ex);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const loadItems = () => {
        if (itemCategoryTabValue != "") {
            const params = new URLSearchParams({
                searchQuery: skuSearchQuery
            });

            if (Object.keys(appliedFilters).length > 0) {
                Object.entries(appliedFilters).map(([name, value]: any) => {
                    params.append(name, value);
                });
            }
            setIsLoading(true);
            api.get(`/catalogue/item/list/${itemCategoryTabValue}`, {params})
                .then((response) => response.data)
                .then((response: any) => {
                    setItemsData(response);
                })
                .catch((ex) => {
                    console.log(ex);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    useEffect(() => {
        if (itemCategoryTabValue !== "") {
            loadItems();
        }
    }, [itemCategoryTabValue]);

    useEffect(() => {
        if (itemCategoriesData && itemCategoriesData.length > 0) {
            setItemCategoryTabValue(itemCategoriesData[0].id);
            setFilteredItemCategories(itemCategoriesData);
        }
    }, [itemCategoriesData]);


    useEffect(() => {
        if (props.subCategoryTabValue !== "") {
            loadItemCategoriesWithFilters();
        }
    }, [props.subCategoryTabValue]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Box sx={{display: "flex"}}>
            <CustomTabPanel width={"15%"} value={props.subCategoryTabValue} index={props.subCategoryTabValue}>
                <Box sx={{pr: 2, borderRight: 1, borderColor: "divider"}}>
                    <Box mt={2}>
                        <SearchFilter query={searchQuery} handleSearchFilter={handleItemCategorySearchFilter}
                                      showSearch={true}/>
                    </Box>
                    <Box mt={2}>
                        <Typography sx={{py: 3, px: 2, fontWeight: 700}}>
                            Item Category
                        </Typography>
                    </Box>
                    <Tabs
                        orientation={"vertical"}
                        variant={"scrollable"}
                        value={itemCategoryTabValue}
                        onChange={handleItemCategoryTabChange}
                        aria-label="Item Category Tabs"
                        className="tab-sidebar"
                    >
                        {filteredItemCategories && filteredItemCategories.length > 0 ? (
                            filteredItemCategories.map((itemCategory: any, itemCategoryIndex: number) => {
                                if (itemCategory.totalSkus > 0) {
                                    return (
                                        <Tab
                                            sx={{
                                                alignItems: "flex-start",
                                                minHeight: "35px",
                                                borderRadius: 1,
                                                color: "info.main",
                                                '&.Mui-selected': {
                                                    color: "info.main",
                                                    fontWeight: 700,
                                                },
                                            }}
                                            key={"item-category-tab-" + itemCategoryIndex}
                                            value={itemCategory.id}
                                            {...a11yProps(itemCategory.id, false)}
                                            label={
                                                <Typography className="tab-sidebar-label">
                                                    {itemCategory.name}&nbsp;
                                                    <Typography component={"sup"}
                                                                sx={{fontSize: '10px'}}>({itemCategory.totalSkus})</Typography>
                                                </Typography>
                                            }
                                            wrapped
                                            className="tab-sidebar-item"
                                        />
                                    )
                                }
                            })
                        ) : (
                            itemCategoriesData && itemCategoriesData.length > 0 && itemCategoriesData.map((itemCategory: any, itemCategoryIndex: number) => {
                                if (itemCategory.totalSkus > 0) {
                                    return (
                                        <Tab
                                            sx={{
                                                alignItems: "flex-start",
                                                minHeight: "35px",
                                                borderRadius: 1,
                                                color: "info.main",
                                                '&.Mui-selected': {
                                                    color: "info.main",
                                                    fontWeight: 700,
                                                },
                                            }}
                                            key={"item-category-tab-" + itemCategoryIndex}
                                            value={itemCategory.id}
                                            {...a11yProps(itemCategory.id, false)}
                                            label={
                                                <Typography className="tab-sidebar-label">
                                                    {itemCategory.name}&nbsp;
                                                    <Typography component={"sup"}
                                                                sx={{fontSize: '10px'}}>({itemCategory.totalSkus})</Typography>
                                                </Typography>
                                            }
                                            wrapped
                                            className="tab-sidebar-item"
                                        />
                                    )
                                }
                            })
                        )}
                    </Tabs>
                </Box>
            </CustomTabPanel>
            {itemCategoryTabValue !== "" && (
                <CustomTabPanel width={"85%"} value={itemCategoryTabValue} index={itemCategoryTabValue}>
                    <Box sx={{p: 2}}>
                        {itemsData &&
                            itemsData.map((item: any, itemIndex: number) => {
                                return (
                                    <Box key={"item-block-" + itemIndex}>
                                        {item?.id ? (
                                            <>
                                                <CatalogItemSkuListing type={"all"} item={item} searchQuery={skuSearchQuery} appliedFilters={appliedFilters} filters={props.filters} handleFilterChange={props.handleFilterChange}/>
                                            </>
                                        ) : (
                                            <Box className={"center-aligned"}>
                                                <Typography>No Items and SKUs found</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                    </Box>
                </CustomTabPanel>
            )}
        </Box>
    )
}

export default ItemCategoryCatalogueTab;