import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {openSnackbar} from "../../../common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../../constants";
import {api} from "../../../../utils/Axios";
import {useAppDispatch} from "../../../../app/hooks";
import Loader from "../../../Loader";

const VendorAssignmentDetails = (props: any) => {
    const {vendors, selectedCategory, revision, brands, project} = props;
    const dispatch = useAppDispatch();
    const [isLoadingSelectedItemCategory, setIsLoadingSelectedItemCategory] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [itemCategories, setItemCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [brandData, setBrandData] = useState<any>({});
    const [gcValues, setGcValues] = useState<any>({});
    const [showGc, setShowGc] = useState<any>({});
    const [selectedVendorDetails, setSelectedVendorDetails] = useState<any>({})
    const [selectedVendor, setSelectedVendor] = useState("");

    const onVendorChangeHandler = (event: any) => {
        setSelectedVendor(event.target.value);
    }

    const handleRadioChangeForAssignmentType = async (entityId: any) => {
        const newShowGc = {...showGc, [entityId]: !showGc[entityId]};
        setShowGc(newShowGc);
        if(gcValues[entityId] == "") {
            const newGcValues = {...gcValues, [entityId]: 20};
            setGcValues(newGcValues);
        }
    };

    const handleGCPercentage = (event: any, entityId: any) => {
        const newGcValues = {...gcValues, [entityId]: event.target.value};
        setGcValues(newGcValues);
    }

    const onBrandChangeHandler = (event: any, entityId: any) => {
        const newBrandData = {...brandData, [entityId]: event.target.value};
        setBrandData(newBrandData);
    }

    const handleSaveVendor = () => {
        if (!selectedVendor) {
            dispatch(
                openSnackbar({
                    message:
                        "Please select vendor to proceed",
                    backgroundColor: ERROR_COLOR,
                })
            );
            return;
        }
        let entityCategories: any = [];
        let errorFlag = false;

        let entityDetails = itemCategories;
        if (selectedCategory.type == "sub-category") {
            entityDetails = subCategories;
        }

        entityDetails.map((details: any) => {
            if (brandData[details.id]) {
                let gcType = 'direct';
                let gcValue = 0;
                if (showGc[details.id]) {
                    gcType = 'via-gc';
                    if (gcValues[details.id] > 0) {
                        gcValue = gcValues[details.id];
                    } else {
                        dispatch(
                            openSnackbar({
                                message:
                                    "GC Percentage value missing for " + details.name,
                                backgroundColor: ERROR_COLOR,
                            })
                        );
                        errorFlag = true;
                        return;
                    }
                }
                entityCategories.push({
                    brandId: brandData[details.id], gcType, gcValue, entityCategoryId: details.id
                });
            }
        });

        if (errorFlag) {
            return;
        }
        setIsLoading(true);

        api.post(`/procurement/estimation-vendor/assign-vendor/${revision.id}`, {
            vendorCategory: selectedCategory.id,
            vendor: selectedVendor,
            entityCategories: entityCategories
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response) {
                dispatch(
                    openSnackbar({
                        message: "Vendor and Brand details saved successfully",
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                props.updateVendorSelection();
            }
        }).catch(ex => {
            dispatch(
                openSnackbar({
                    message: "Oops! Something went wrong, please try again later.",
                    backgroundColor: ERROR_COLOR
                })
            );
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const loadSelectedEstimationVendorDetails = (categoryId: string) => {
        setIsLoadingSelectedItemCategory(true);
        api.get(`/procurement/estimation-vendor/get-selected-vendor-details/${revision.id}/${categoryId}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response) {
                setSelectedVendorDetails(response);
                setSelectedVendor(response?.vendorId);
            }
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoadingSelectedItemCategory(false);
        });
    }


    const loadSubCategories = (mappedSubCategories: any) => {
        setIsLoading(true);
        api.post(`/catalogue/material-master/get-sub-categories-by-ids`, {
            ids: mappedSubCategories
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response) {
                setSubCategories(response);
            }
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const loadItemCategories = (mappedItemCategories: any) => {
        setIsLoading(true);
        api.post(`/catalogue/item-category/get-by-ids`, {
            ids: mappedItemCategories
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response) {
                setItemCategories(response);
            }
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        let newShowGc: any = {};
        let newBrandData: any = {};
        let newGcValues: any = {};
        if (selectedCategory.type == "sub-category") {
            subCategories.length > 0 && subCategories.map((subCategory: any) => {
                let showGcForItemCategory = false;
                let brandId = ""
                let gcValue = "";
                if (Object.keys(selectedVendorDetails).length > 0) {
                    selectedVendorDetails.hasOwnProperty("subCategories") && selectedVendorDetails?.subCategories.map((selectedSubCategory: any) => {
                        if (subCategory.id == selectedSubCategory.subCategoryId) {
                            brandId = selectedSubCategory.brandId;
                            if (selectedSubCategory?.assignmentType == "via-gc") {
                                showGcForItemCategory = true;
                                gcValue = selectedSubCategory?.assignmentValue;
                            }
                        }
                    });
                }
                newShowGc[subCategory.id] = showGcForItemCategory;
                newGcValues[subCategory.id] = gcValue;
                newBrandData[subCategory.id] = brandId;
            });
        } else {
            itemCategories.length > 0 && itemCategories.map((itemCategory: any) => {
                let showGcForItemCategory = false;
                let brandId = ""
                let gcValue = "";
                if (Object.keys(selectedVendorDetails).length > 0) {
                    selectedVendorDetails.itemCategories.map((selectedItemCategory: any) => {
                        if (itemCategory.id == selectedItemCategory.itemCategoryId) {
                            brandId = selectedItemCategory.brandId;
                            if (selectedItemCategory?.assignmentType == "via-gc") {
                                showGcForItemCategory = true;
                                gcValue = selectedItemCategory?.assignmentValue;
                            }
                        }
                    });
                }
                newShowGc[itemCategory.id] = showGcForItemCategory;
                newGcValues[itemCategory.id] = gcValue;
                newBrandData[itemCategory.id] = brandId;
            });
        }

        setShowGc(newShowGc);
        setBrandData(newBrandData);
        setGcValues(newGcValues);
    }, [itemCategories, subCategories, selectedVendorDetails]);

    useEffect(() => {
        if (Object.keys(selectedCategory).length > 0) {
            setItemCategories([]);
            setSubCategories([]);
            setBrandData({});
            setShowGc({});
            setGcValues({});
            setSelectedVendor("");
            setSelectedVendorDetails({});
            loadSelectedEstimationVendorDetails(selectedCategory.id);
            if (selectedCategory.type == "sub-category") {
                loadSubCategories(selectedCategory?.mappedSubCategoriesForBrands);
            } else {
                if (selectedCategory?.mappedItemCategoriesForBrands != null && selectedCategory?.mappedItemCategoriesForBrands.length > 0)
                    loadItemCategories(selectedCategory?.mappedItemCategoriesForBrands);
            }
        }
    }, [selectedCategory]);

    if (isLoading || isLoadingSelectedItemCategory) {
        return <Loader/>
    }

    return (
        <Box sx={{pt: 2, pb: 5, width: '70%'}}>
            {vendors.length > 0 && (
                <>
                    <Box sx={{width: '50%', pb: 2}}>
                        <Typography sx={{pb: 0.5}}>Select Vendor</Typography>
                        <Select
                            labelId={"demo-simple-select-label" + selectedCategory.id}
                            id={"demo-simple-select" + selectedCategory.id}
                            name={"vendor"}
                            required={true}
                            readOnly={revision.status == "PENDING" ? false : true}
                            onChange={(event: any) => onVendorChangeHandler(event)}
                            value={selectedVendor ?? ""}
                            style={{height: '40px', width: '100%'}}
                        >
                            {vendors.map((vendor: any, vendorIndex: number) => {
                                    if (selectedCategory?.mappedVendorNames.some((mappedVendor: any) => mappedVendor.toLowerCase() == vendor.name.toLowerCase())) {
                                        return (
                                            <MenuItem key={"vendor-" + vendorIndex} value={vendor.id}>
                                                {vendor.name}
                                            </MenuItem>
                                        )
                                    }
                                }
                            )}
                        </Select>
                    </Box>
                    {selectedCategory.hasOwnProperty("mappedItemCategoriesForBrands") && selectedCategory?.mappedItemCategoriesForBrands != null && selectedCategory?.mappedItemCategoriesForBrands.length > 0 && (
                        <Box sx={{pb: 2}}>
                            <Typography sx={{pb: 0.5}}>Assign Brands</Typography>
                            {selectedCategory.type == 'sub-category' ? (
                                subCategories.map((subCategory: any) => {
                                    let subCategoryBrands: any[] = selectedCategory?.mappedBrandNames[subCategory.code];
                                    return (
                                        <Box key={"item-category-" + subCategory.id} sx={{py: 1}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs
                                                      className={"vertically-center-aligned"}>{subCategory.name}</Grid>
                                                <Grid item xs>
                                                    {subCategoryBrands != undefined && subCategoryBrands.length > 0 && (
                                                        <Box sx={{width: '80%'}}>
                                                            <Select
                                                                labelId={"demo-simple-select-label-brand-" + subCategory.id}
                                                                id={"demo-simple-select-brand-" + subCategory.id}
                                                                name={"brand"}
                                                                readOnly={revision.status == "PENDING" ? false : true}
                                                                required={true}
                                                                onChange={(event: any) => onBrandChangeHandler(event, subCategory.id)}
                                                                value={brandData[subCategory.id] ?? ""}
                                                                style={{
                                                                    height: '40px',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                {brands.length > 0 && brands.map((brand: any, brandIndex: number) => {
                                                                    if (subCategoryBrands.some((subCategoryBrand: any) => subCategoryBrand.toLowerCase() == brand.name.toLowerCase())) {
                                                                        return (
                                                                            <MenuItem key={"brand-" + brandIndex}
                                                                                      value={brand.id}>
                                                                                {brand.name}
                                                                            </MenuItem>
                                                                        )
                                                                    }
                                                                })}
                                                            </Select>
                                                        </Box>
                                                    )}
                                                </Grid>
                                                <Grid item xs>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        value={showGc[subCategory.id] ? "via-gc" : 'direct'}
                                                        onChange={() => handleRadioChangeForAssignmentType(subCategory.id)}
                                                        sx={{fontSize: '11px'}}
                                                    >
                                                        <FormControlLabel
                                                            disabled={revision.status == "PENDING" ? false : true}
                                                            value="direct"
                                                            control={<Radio/>}
                                                            label="Direct"
                                                        />
                                                        <FormControlLabel
                                                            disabled={revision.status == "PENDING" ? false : true}
                                                            value="via-gc"
                                                            control={<Radio/>}
                                                            label="Via GC"
                                                        />
                                                    </RadioGroup>
                                                </Grid>
                                            </Grid>
                                            {showGc[subCategory.id] && (
                                                <Box sx={{pt: 0.5}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs/>
                                                        <Grid item xs
                                                              className={"vertically-center-aligned"}>
                                                            GC Value %
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Box sx={{width: '80%'}}>
                                                                <TextField
                                                                    disabled={revision.status == "PENDING" ? false : true}
                                                                    fullWidth
                                                                    size={"small"}
                                                                    variant={"outlined"}
                                                                    className={"inputBox"}
                                                                    type={"number"}
                                                                    id={"gc-value"}
                                                                    onWheel={(e: any) => e.target.blur()}
                                                                    name={"gc-value"}
                                                                    value={gcValues[subCategory.id] || "20"}
                                                                    placeholder={"GC Value In Percentage"}
                                                                    onChange={(e) => handleGCPercentage(e, subCategory.id)}
                                                                    InputProps={{
                                                                        style: {
                                                                            borderRadius: "5px",
                                                                            borderColor:
                                                                                'primary.light'
                                                                        },
                                                                        inputProps: {min: 0}
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                    </Grid>
                                                </Box>
                                            )}
                                        </Box>
                                    )
                                })
                            ) : (
                                itemCategories.map((itemCategory: any) => {
                                    let itemCategoryBrands: any[] = selectedCategory?.mappedBrandNames[itemCategory.code];
                                    return (
                                        <Box key={"item-category-" + itemCategory.id}
                                             sx={{py: 1}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs
                                                      className={"vertically-center-aligned"}>{itemCategory.name}</Grid>
                                                <Grid item xs>
                                                    {itemCategoryBrands != undefined && itemCategoryBrands.length > 0 && (
                                                        <Box sx={{width: '80%'}}>
                                                            <Select
                                                                labelId={"demo-simple-select-label-brand-" + itemCategory.id}
                                                                id={"demo-simple-select-brand-" + itemCategory.id}
                                                                name={"brand"}
                                                                readOnly={revision.status == "PENDING" ? false : true}
                                                                required={true}
                                                                onChange={(event: any) => onBrandChangeHandler(event, itemCategory.id)}
                                                                value={brandData[itemCategory.id] ?? ""}
                                                                style={{
                                                                    height: '40px',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                {brands.length > 0 && brands.map((brand: any, brandIndex: number) => {
                                                                    if (itemCategoryBrands.some((itemCategoryBrand: any) => itemCategoryBrand.toLowerCase() == brand.name.toLowerCase())) {
                                                                        return (
                                                                            <MenuItem key={"brand-" + brandIndex}
                                                                                      value={brand.id}>
                                                                                {brand.name}
                                                                            </MenuItem>
                                                                        )
                                                                    }
                                                                })}
                                                            </Select>
                                                        </Box>
                                                    )}
                                                </Grid>
                                                <Grid item xs>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        value={showGc[itemCategory.id] ? "via-gc" : 'direct'}
                                                        onChange={() => handleRadioChangeForAssignmentType(itemCategory.id)}
                                                        sx={{fontSize: '11px'}}
                                                    >
                                                        <FormControlLabel
                                                            value="direct"
                                                            disabled={revision.status == "PENDING" ? false : true}
                                                            control={<Radio/>}
                                                            label="Direct"
                                                        />
                                                        <FormControlLabel
                                                            disabled={revision.status == "PENDING" ? false : true}
                                                            value="via-gc"
                                                            control={<Radio/>}
                                                            label="Via GC"
                                                        />
                                                    </RadioGroup>
                                                </Grid>
                                            </Grid>
                                            {showGc[itemCategory.id] && (
                                                <Box sx={{pt: 0.5}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs/>
                                                        <Grid item xs
                                                              className={"vertically-center-aligned"}>
                                                            GC Value %
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Box sx={{width: '80%'}}>
                                                                <TextField
                                                                    fullWidth
                                                                    size={"small"}
                                                                    disabled={revision.status == "PENDING" ? false : true}
                                                                    variant={"outlined"}
                                                                    className={"inputBox"}
                                                                    type={"number"}
                                                                    id={"gc-value"}
                                                                    onWheel={(e: any) => e.target.blur()}
                                                                    name={"gc-value"}
                                                                    value={gcValues[itemCategory.id] || "20"}
                                                                    placeholder={"GC Value In Percentage"}
                                                                    onChange={(e) => handleGCPercentage(e, itemCategory.id)}
                                                                    InputProps={{
                                                                        style: {
                                                                            borderRadius: "5px",
                                                                            borderColor:
                                                                                'primary.light'
                                                                        },
                                                                        inputProps: {min: 0}
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                    </Grid>
                                                </Box>
                                            )}
                                        </Box>
                                    )
                                })
                            )}
                        </Box>
                    )}

                    {revision.status == "PENDING" && (
                        <Box sx={{px: 2, pt: 0.5}}>
                            <Box className={"right-align-content"}>
                                <Box className={"vertically-center-aligned"}>
                                    <Button variant={"contained"} onClick={handleSaveVendor}
                                            sx={{py: 0.5}}>Save</Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

export default VendorAssignmentDetails;