import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {useState} from "react";
import ChangeEntityPrice from "./ChangeEntityPrice";

const EstimationSkuDetails = (props: any) => {
    const {sku, category, subCategory, vendorEntities, brandEntities} = props;

    const handleVendorSelection = () => {

    }

    return (
        <Box sx={{border: 1, borderTop: 0, borderColor: 'divider', padding: "2rem"}}>
            {sku.hasOwnProperty("id") &&
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography variant={"h4"} sx={{pb:2}}>Sku Details</Typography>
                            <Box display="flex" className={"show-view-details-sku"}>
                                <Box sx={{minWidth: "300px"}}>
                                    <Box display="flex" alignItems="flex-start"
                                         justifyContent={"space-between"} padding={"4px"}>
                                        <Typography textAlign={"start"}>Category Name</Typography>
                                        <Typography textAlign={"start"}
                                                    fontWeight={600}>{category.name}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="flex-start"
                                         justifyContent={"space-between"} padding={"4px"}>
                                        <Typography>Sub Category name</Typography>
                                        <Typography textAlign={"start"}
                                                    fontWeight={600}>{subCategory.name}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="flex-start"
                                         justifyContent={"space-between"} padding={"4px"}>
                                        <Typography>Sku Name</Typography>
                                        <Typography textAlign={"start"}
                                                    fontWeight={600}>{sku?.name}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="flex-start"
                                         justifyContent={"space-between"} padding={"4px"}>
                                        <Typography>Sku Code</Typography>
                                        <Typography textAlign={"start"}
                                                    fontWeight={600}>{sku?.code}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="flex-start"
                                         justifyContent={"space-between"} padding={"4px"}>
                                        <Typography>Sku UOM</Typography>
                                        <Typography textAlign={"start"}
                                                    fontWeight={600}>{sku?.uom}</Typography>
                                    </Box>
                                </Box>
                                {(sku?.skuOptions && sku.skuOptions.length > 0) &&
                                    <TableContainer sx={{width: "400px"}}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow
                                                    sx={{
                                                        th: {
                                                            border: 1,
                                                            borderColor: "divider",
                                                            backgroundColor: "info.light",
                                                            fontWeight: "700",
                                                        },
                                                    }}
                                                >
                                                    <TableCell>Sku Option Name</TableCell>
                                                    <TableCell>Sku Option Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {sku?.skuOptions.map((skuOption: any) => (
                                                    <TableRow sx={{border: 1, borderColor: 'divider'}}>
                                                        <TableCell
                                                            sx={{border: 1, borderColor: 'divider'}}>{skuOption?.name}</TableCell>
                                                        <TableCell
                                                            sx={{border: 1, borderColor: 'divider'}}>{skuOption?.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Box>
                        </Grid>
                        {(sku.hasOwnProperty("entityPrices") && sku.entityPrices != null && sku?.entityPrices.length > 0) && (
                            <Grid item xs>
                                <ChangeEntityPrice
                                    sku={sku}
                                    vendorEntities={vendorEntities}
                                    brandEntities={brandEntities}
                                    handleVendorSelection={handleVendorSelection}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            }
        </Box>
    )
}

export default EstimationSkuDetails;