export const rolePageConfig = {
    columns: [
        {field: 'id', headerName: 'ID', headerClassName: 'data-grid-header', flex: 1, maxWidth: 90},
        {field: 'name', headerName: 'Name', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'slug', headerName: 'Slug', headerClassName: 'data-grid-header', flex: 1,},
        {
            field: 'action', headerName: 'Action', headerClassName: 'data-grid-header data-grid-right-aligned-header',
            headerAlign: 'right',
            align: "right",
            sortable: false,
            flex: 1,
            show: 'button',
            buttons: [
                {
                    type: 'edit',
                    title: 'Edit Role',
                    permissions: ['edit-users'],
                    condition: (row: any) => true
                },
            ]
        }
    ]
};