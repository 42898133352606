import React, {SyntheticEvent, useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import Loader from "../../../components/Loader";
import {Box, Modal} from "@mui/material";
import SubCategoryTab from "../../../components/tabs/SubCategoryTab";
import {CustomTabPanel} from "../../../utils/TabsUtils";
import EstimationItemListing from "../../../components/estimation/EstimationItemListing";
import {getStyle} from "../../../utils/ModalUtils";
import PageHeading from "../../../components/page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import EstimationSummary from "../../../components/estimation/summary/EstimationSummary";

import SiteConditionTab from "../plan/tabs/SiteConditionTab";
import HeadCountTab from "../../../components/head-count/HeadCountTab";
import ECatalogTab from "../plan/tabs/ECatalogTab";
import ExcelJS from 'exceljs';
import {convertToRoman, getFormattedDate, getPriceInFormatted} from "../../../utils/Common";
import EstimationCategoryTab from "../../../components/tabs/EstimationCategoryTab";

const EstimationDetailsPopupNew = (props: any) => {
    const {revision, project, categories} = props;
    const style = getStyle(100, 85, 0, 0);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [headerButtons, setHeaderButtons] = useState<any>([]);

    const [currentRevision, setCurrentRevision] = useState<any>({});
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [selectedSubCategory, setSelectedSubCategory] = useState<any>({});
    const [revisionDesignType, setRevisionDesignType] = useState("");
    const [categoryTabValue, setCategoryTabValue] = useState("summary");
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");
    const [estimations, setEstimations] = useState<any>([]);
    const [estimationSummary, setEstimationSummary] = useState<any>([]);

    const [showProjectParameters, setShowProjectParameters] = useState(true);
    const [projectWithRevision, setProjectWithRevision] = useState(props.project);
    const [hideDownload, setHideDownload] = useState(false);

    const [tabs, setTabs] = useState(
        [
            {id: "site-condition", name: "Parameters", disabled: false},
            {id: "head-count", name: "HC", disabled: true},
            {id: "e-catalog", name: "E-Catalog", disabled: true},
            {id: "summary", name: "Summary", disabled: project.estimationType === 'scratch' ? false : true}
        ]
    );

    const handleCategoryTabChange = (_event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (_event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
    };

    const refreshRevision = () => {
        setOpenPopup(false);
        props.proceedTabChange("boq");
    }

    const loadEstimationDetails = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`/procurement/estimation/details/${currentRevision.id}`);
            const results = await response.data;
            setEstimations(results);

            setIsLoading(false);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    };

    const loadMEPSheetDetails = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`/procurement/estimation/download-mep-sheets/${project.id}?revisionId=${currentRevision.id}`);
            const results = await response.data;
            downloadMEPCSV(results);

            setIsLoading(false);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    };

    const fetchPartSummaryDetails = async () => {
        if (props.revision.id) {
            setIsSummaryLoading(true);

            api.get(`/procurement/estimation/get-summary/${currentRevision.id}`).then((response) => {
                return response.data;
            }).then((response: any) => {
                setEstimationSummary(response);
            }).catch(ex => {
                console.log(ex);
            }).finally(() => {
                setIsSummaryLoading(false);
            });
        }
    };

    const markDownloaded = () => {
        api.get(`/procurement/estimation/revision/mark-downloaded/${currentRevision.id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            setCurrentRevision(response);
            project.revision = response;
            setProjectWithRevision(project);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const downloadCSV = (boqBreakupDetails: any, parts: any) => {
        let workbook = new ExcelJS.Workbook();
        const sheetTabs: any = {
            "INDEX": {
                subHeading: "INDEX",
                lastColumn: 'F'
            },
            "P1 Cost Estimate": {
                subHeading: "OVERALL COST SUMMARY",
                lastColumn: 'F'
            },
            "P2 List of Exclusions": {
                subHeading: "LIST OF EXCLUSIONS",
                lastColumn: 'F'
            },
            "P3 List of Makes": {
                subHeading: "LIST OF APPROVED MAKES/MANUFACTURES OF MATERIALS FOR INTERIOR WORKS",
                lastColumn: 'F'
            },
            "P4- Cost Summary": {
                subHeading: "COST SUMMARY",
                lastColumn: 'F'
            },
            "P5- BOQ": {
                subHeading: "DETAILED BOQ",
                lastColumn: 'J'
            }
        }
        Object.keys(sheetTabs).map((key: string) => {
            let sheet = workbook.addWorksheet(key);
            sheet.state = 'visible';
            sheet.properties.defaultRowHeight = 80;
            let count = addExcelHeading(sheet, key, sheetTabs[key].subHeading, sheetTabs[key].lastColumn);
            if (key == "INDEX") {
                const allIndexes = [
                    {id: "P1", name: "Cost Estimate"},
                    {id: "P2", name: "List of Exclusions"},
                    {id: "P3", name: "List of Makes"},
                    {id: "P4", name: "Cost Summary"},
                    {id: "P5", name: "BOQ (Separate Sections C&I, MEP, FF&E)"},
                    {id: "P6", name: "Annexures (if any)"},
                ];

                allIndexes.map((indexDetails: any) => {
                    sheet.addRow([indexDetails.id, indexDetails.name]);
                });
            }
            if (key == "P1 Cost Estimate") {
                addCostEstimate(sheet, parts, sheetTabs[key].lastColumn);
            }
            if (key == "P2 List of Exclusions") {
                addListOfExclusions(sheet);
            }
            if (key == "P3 List of Makes") {
                addListOfMakes(sheet);
            }
            if (key == "P4- Cost Summary") {
                addCostSummary(sheet, parts);
            }
            if (key == "P5- BOQ") {
                addBoQ(sheet, boqBreakupDetails);
            }
        });

        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            let projectName = project.name.replace(" ", "-") + "-" + project.projectStudio.replace(" ", "-");
            anchor.setAttribute('download', `${projectName}-Estimation-Data.xlsx`);
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const downloadMEPCSV = (mepSheets: any) => {
        let workbook = new ExcelJS.Workbook();
        const sheetTabs: any = {
            "mep_chw_hvac_load": {
                heading: "CHW HVAC LOAD",
                subHeading: "CHW HVAC LOAD",
                lastColumn: 'H'
            },
            "mep_vrf_sys_load": {
                heading: "VRF SYS LOAD",
                subHeading: "VRF SYS LOAD",
                lastColumn: 'H'
            },
            "mep_elec_load": {
                heading: "ELEC LOAD",
                subHeading: "ELEC LOAD",
                lastColumn: 'H'
            },
            "mep_vrf_hvac_load": {
                heading: "VRF HVAC LOAD",
                subHeading: "VRF HVAC LOAD",
                lastColumn: 'H'
            },
            "mep_it_node_count": {
                heading: "IT NODE LOAD",
                subHeading: "IT NODE LOAD",
                lastColumn: 'H'
            },
            "mep_io_summary": {
                heading: "MEP IO SUMMARY",
                subHeading: "MEP IO SUMMARY",
                lastColumn: 'H'
            },
        }
        Object.keys(sheetTabs).map((key: string) => {
            let sheet = workbook.addWorksheet(sheetTabs[key].heading);
            sheet.state = 'visible';
            sheet.properties.defaultRowHeight = 80;
            let count = addExcelHeading(sheet, sheetTabs[key].heading, sheetTabs[key].subHeading, sheetTabs[key].lastColumn);

            mepSheets[key].map((row: any) => {
                let rowItem = row.split(",");
                sheet.addRow(rowItem);
            });

        });

        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            let projectName = project.name.replace(" ", "-") + "-" + project.projectStudio.replace(" ", "-");
            anchor.setAttribute('download', `${projectName}-MEP-Data.xlsx`);
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const addCostSummary = (sheet: any, boqBreakupDetails: any) => {
        let headerRow = sheet.addRow([
            "SL.NO", "PACKAGE", "AMOUNT"
        ])
        headerRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '999999'},
            bgColor: {argb: '000000'}
        };

        headerRow.font = {
            color: {
                argb: 'FFFFFF',
            },
            bold: true
        }

        boqBreakupDetails.map((publishedPart: any, partIndex: number) => {
            let partRow = sheet.addRow(["Part " + String.fromCharCode(partIndex + 65) + " - " + publishedPart.name]);
            partRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '000000'}
            };
            partRow.font = {
                color: {
                    argb: 'FFFFFF'
                },
                bold: true
            }
            partRow.alignment = {vertical: 'middle', horizontal: 'center'};
            let currentRowIdx = sheet.rowCount; // Find out how many rows are there currently
            let endColumnIdx = sheet.columnCount; // Find out how many columns are in the worksheet

            sheet.mergeCells(currentRowIdx, 1, currentRowIdx, endColumnIdx);

            let total = 0;
            let allParts: any[] = [];
            publishedPart.categories.map((category: any, index: any) => {
                allParts.push(convertToRoman(index + 1));
                let partTotal = 0;
                let categoryRow = sheet.addRow([
                    "", `PART ${convertToRoman(index + 1)} - ${category.name}`
                ])

                categoryRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'CCCCCC'}
                };

                categoryRow.font = {
                    bold: true
                }
                category.subCategories.map((subCategory: any, subCatIndex: number) => {
                    partTotal += subCategory.total;
                    sheet.addRow([
                        subCatIndex + 1,
                        subCategory.name,
                        getPriceInFormatted(subCategory.total, project.country)
                    ])
                })
                total += partTotal;
                sheet.addRow([])
            })
            let totalRow = sheet.addRow([
                "", `TOTAL AMOUNT (EXCLUDING TAXES)`,
                getPriceInFormatted(total, project.country)
            ])
            totalRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'd9ead3'}
            };
            totalRow.font = {
                bold: true
            }
        })
        let noteRow = sheet.addRow([
            "Note:",
            "\"GST@18% and BOCW @1.25% as applicable\n" +
            "BOCW 1% Cess is calculated on the total project cost value. .25% includes the external consultant charges, as SM appoint an Labour consultant to get the assessment order and payment of cess to the welfare board.This is applicable only if payment of cess is in SM scope.",
        ])

        noteRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'd9ead3'}
        };
        noteRow.font = {
            bold: true
        }
    }

    const addBoQ = (sheet: any, boqBreakupDetails: any) => {
        let boqHeaderRow = sheet.addRow([
            "SL.NO",
            "SKU LABEL",
            "MATERIAL CATEGORY",
            "MATERIAL SUBCATEGORY",
            "ITEM NAME",
            "ITEM DESCRIPTION",
            "ITEM SPECIFICATIONS",
            "UNIT",
            "QUANTITY",
            "RATE (INR)",
            "AMOUNT (INR)"
        ])
        boqHeaderRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '999999'},
            bgColor: {argb: '000000'}
        };

        boqHeaderRow.font = {
            color: {
                argb: 'FFFFFF',
            },
            bold: true
        }
        sheet.addRow([
            "", "SKU LABEL", "MATERIAL CATEGORY", "MATERIAL SUBCATEGORY", "ITEM CATEGORY", "DESCRIPTION", "SPECIFICATIONS", "UOM", " TOTAL QUANTITY", "GSV", "TOTAL GSV"
        ])
        sheet.addRow([])
        boqBreakupDetails.map((boqDetails: any, index: number) => {
            let categoryRow = sheet.addRow([
                convertToRoman(index + 1),
                boqDetails.name
            ])

            categoryRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '666666'},
                bgColor: {argb: '000000'}
            };

            categoryRow.font = {
                color: {
                    argb: 'FFFFFF',
                },
                bold: true
            }
            boqDetails.subCategories.map((subCategory: any, subCategoryIndex: number) => {
                let total = 0;
                let rowCount = (subCategoryIndex + 1);
                let subCategoryRow = sheet.addRow([rowCount.toFixed(3), subCategory.name])
                subCategoryRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'CCCCCC'},
                    bgColor: {argb: '000000'}
                };

                subCategoryRow.font = {
                    bold: true
                }
                subCategory.estimationItems.map((estimationItem: any) => {
                    estimationItem.estimationItemSkus && estimationItem.estimationItemSkus.length > 0 && estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                        rowCount += 0.001;
                        total += (estimationItemSku.sellingPrice * estimationItemSku.totalQuantity)
                        let options = "";
                        if (estimationItemSku.sku.options != null && estimationItemSku.sku.options.length > 0) {
                            estimationItemSku.sku.options.map((skuOption: any, index: number) => {
                                if (index > 0) {
                                    options = options + "\n";
                                }
                                let keyPair = skuOption.name + ": " + skuOption.value;
                                options = options + keyPair
                            })
                        }
                        sheet.addRow([
                            rowCount.toFixed(3),
                            estimationItemSku.sku.code ?? "",
                            boqDetails.name,
                            subCategory.name,
                            estimationItem.itemCategory.name ?? "",
                            estimationItemSku.sku.description ?? "",
                            options,
                            estimationItemSku.sku.uom ?? "",
                            estimationItemSku.totalQuantity ?? "",
                            estimationItemSku.sellingPrice ? getPriceInFormatted(estimationItemSku.sellingPrice, project.country) : "",
                            estimationItemSku.sellingPrice ? getPriceInFormatted((estimationItemSku.sellingPrice * estimationItemSku.totalQuantity), project.country) : ''
                        ])
                    });
                });

                sheet.addRow([])
                let subTotalRow = sheet.addRow([
                    "", `SUB TOTAL FOR ${subCategory.name}`, "", "", "", "", "", "", "", getPriceInFormatted(total, project.country)
                ])

                subTotalRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'd9ead3'},
                    bgColor: {argb: '000000'}
                };

                subTotalRow.font = {
                    bold: true
                }
                sheet.addRow([])
            });
        });

    }

    const addCostEstimate = (sheet: any, publishedParts: any, lastColumn: string) => {
        sheet.addRow([]);
        let subHeaderRow = sheet.addRow([
            'SL.NO', 'DESCRIPTION', 'AMOUNT IN INR WITHOUT TAXES', 'RATE / SQFT'
        ]);
        subHeaderRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'A9A9A9'}
        };
        subHeaderRow.font = {
            bold: true
        }
        subHeaderRow.alignment = {vertical: 'middle', horizontal: 'center'};
        let costEstimateTotal = 0;
        let partTotal = 0;
        let partSl: any[] = [];
        let partRatePerSqft = 0;
        let lastIndex = 0;
        estimationSummary.parts.map((part: any, index: number) => {
            partSl.push(String.fromCharCode(index + 65));
            partTotal += part.total;
            partRatePerSqft += (part.total / project?.carpetArea);
            sheet.addRow([
                String.fromCharCode(index + 65),
                part.name,
                getPriceInFormatted(part.total, project.country),
                getPriceInFormatted(part.total / project?.carpetArea, project.country)
            ])
            lastIndex = index;
        });
        sheet.addRow([
            "",
            `SUB TOTAL COST (${partSl.join("+")})`,
            getPriceInFormatted(partTotal, project.country),
            getPriceInFormatted(partRatePerSqft, project.country)
        ])
        costEstimateTotal += partTotal;
        sheet.addRow([
            String.fromCharCode((lastIndex + 1) + 65),
            "Consultancy Fees"
        ])
        partSl.push(String.fromCharCode((lastIndex + 1) + 65));
        let dactotal = project.carpetArea * project.projectDetails?.designConsultancyRate;

        let prelimTotal = project.carpetArea * project.projectDetails?.prelimsRate;
        let totalDesignFees = dactotal + prelimTotal;
        costEstimateTotal += totalDesignFees;
        let consultancyDetails = [
            "Design Fee",
            "LEED & WELL Consultancy",
            "Construction Waste Management",
            "Kitchen Consultancy",
            "Acoustic Consultancy",
            "AV Consultancy",
            "Other Consultancy"
        ];
        consultancyDetails.map((detail: any, index: number) => {
            if (detail == "Design Fee") {
                sheet.addRow([
                    index + 1,
                    detail,
                    getPriceInFormatted(totalDesignFees, project.country),
                    getPriceInFormatted(totalDesignFees / project?.carpetArea, project.country)
                ])
            } else {
                sheet.addRow([
                    index + 1,
                    detail
                ])
            }
        });
        let allPartsRow = sheet.addRow([
            "",
            `SUB TOTAL FOR PART ${String.fromCharCode((lastIndex + 1) + 65)}`,
            getPriceInFormatted(totalDesignFees, project.country),
            getPriceInFormatted(totalDesignFees / project?.carpetArea, project.country)
        ])

        allPartsRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'A9A9A9'}
        };

        allPartsRow.font = {
            bold: true
        }

        let localAuthorityCharges = 0;
        if (
            project.projectDetails?.isAuthorityApprovalCharges &&
            project.projectDetails?.authorityApprovalCharges > 0
        ) {
            localAuthorityCharges = project.projectDetails?.authorityApprovalCharges;
            costEstimateTotal += localAuthorityCharges;
        }

        let localAuthorityRow = sheet.addRow([
            String.fromCharCode((lastIndex + 2) + 65),
            "Local Authority Approval (If Applicable) extra",
            getPriceInFormatted(localAuthorityCharges, project.country),
            getPriceInFormatted(localAuthorityCharges / project?.carpetArea, project.country)
        ])
        partSl.push(String.fromCharCode((lastIndex + 2) + 65));

        localAuthorityRow.font = {
            bold: true
        }

        let bocwTotal = 0;
        let bocwName = "BOCW Charges";
        if (
            props.project.projectDetails?.bocwApplicable &&
            props.project.projectDetails?.bocwApplicableRate > 0
        ) {
            bocwName = "BOCW Charges @ " + props.project.projectDetails?.bocwApplicableRate + " %"
            bocwTotal = (costEstimateTotal * props.project.projectDetails?.bocwApplicableRate) / 100;
        }

        let bocRow = sheet.addRow([
            String.fromCharCode((lastIndex + 3) + 65),
            bocwName,
            getPriceInFormatted(bocwTotal, project.country),
            getPriceInFormatted(bocwTotal / project?.carpetArea, project.country)
        ])
        partSl.push(String.fromCharCode((lastIndex + 3) + 65));

        bocRow.font = {
            bold: true
        }

        let totalCostRow = sheet.addRow([
            "",
            `Total cost including Local Authority (if applicable)& BOCW (${partSl.join("+")})`,
            getPriceInFormatted(costEstimateTotal, project.country),
            getPriceInFormatted(costEstimateTotal / project?.carpetArea, project.country)
        ])

        totalCostRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '90EE90'}
        };

        totalCostRow.font = {
            bold: true
        }

        let taxTotal = (costEstimateTotal * 18) / 100;
        costEstimateTotal += taxTotal;

        let taxRow = sheet.addRow([
            String.fromCharCode((lastIndex + 4) + 65),
            "Taxes @ 18%",
            getPriceInFormatted(taxTotal, project.country),
            getPriceInFormatted(taxTotal / project?.carpetArea, project.country)
        ])
        partSl.push(String.fromCharCode((lastIndex + 4) + 65));

        taxRow.font = {
            bold: true
        }

        let totalRow = sheet.addRow([
            "",
            `GRAND Total Including Taxes (${partSl.join("+")})`,
            getPriceInFormatted(costEstimateTotal, project.country),
            getPriceInFormatted(costEstimateTotal / project?.carpetArea, project.country)
        ])

        totalRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFA500'}
        };
        totalRow.font = {
            bold: true
        }
        let nodeRow = sheet.addRow([
            "Note",
            "BOCW@1.25% will be extra as applicable.\n" +
            "BOCW 1% Cess is calculated on the total project cost value. 0.25% includes the external consultant charges, as SM appoint an Labour consultant to get the assessment order and payment of cess to the welfare board.This is applicable only if payment of cess is in SM scope"
        ])
        nodeRow.font = {
            bold: true
        }

        addPartToCostEstimate(sheet, publishedParts, lastColumn);
    }

    const addPartToCostEstimate = (sheet: any, publishedParts: any, lastColumn: string) => {
        publishedParts.map((publishedPart: any, partIndex: number) => {
            sheet.addRow([])
            let currentRowIdx = sheet.rowCount; // Find out how many rows are there currently
            let endColumnIdx = sheet.columnCount; // Find out how many columns are in the worksheet
            sheet.mergeCells(currentRowIdx, 1, currentRowIdx, endColumnIdx);

            let partRow = sheet.addRow([String.fromCharCode(partIndex + 65) + " - " + publishedPart.name]);
            partRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '000000'}
            };
            partRow.font = {
                color: {
                    argb: 'FFFFFF'
                },
                bold: true
            }
            partRow.alignment = {vertical: 'middle', horizontal: 'center'};
            currentRowIdx = sheet.rowCount; // Find out how many rows are there currently
            endColumnIdx = sheet.columnCount; // Find out how many columns are in the worksheet

            sheet.mergeCells(currentRowIdx, 1, currentRowIdx, endColumnIdx);
            let partHeaderRow = sheet.addRow([
                "SL.NO", "PACKAGE DETAIL", "AMOUNT IN INR", "RATE / SQFT", "BASIC SPECIFICATION"
            ])

            partHeaderRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '666666'},
                bgColor: {argb: '000000'}
            };

            partHeaderRow.font = {
                color: {
                    argb: 'FFFFFF',
                },
                bold: true
            }
            let total = 0;
            let allParts: any[] = [];
            publishedPart.categories.map((category: any, index: any) => {
                allParts.push(convertToRoman(index + 1));
                let partTotal = 0;
                sheet.addRow([
                    "", `PART ${convertToRoman(index + 1)} - ${category.name}`
                ])
                category.subCategories.map((subCategory: any, subCatIndex: number) => {
                    partTotal += subCategory.total;
                    sheet.addRow([
                        subCatIndex + 1,
                        subCategory.name,
                        getPriceInFormatted(subCategory.total, project.country),
                        getPriceInFormatted(subCategory.total / project?.carpetArea, project.country),
                        "As per attached Annexure BOQ"
                    ])
                })
                let subTotalRow = sheet.addRow([
                    "",
                    `SUB TOTAL FOR PART - ${convertToRoman(index + 1)}`,
                    getPriceInFormatted(partTotal, project.country),
                    getPriceInFormatted(partTotal / project?.carpetArea, project.country),
                ])
                subTotalRow.font = {
                    bold: true
                }
                subTotalRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'CCCCCC'}
                };
                total += partTotal;
                sheet.addRow([])
            })
            let totalRow = sheet.addRow([
                "", `TOTAL (${allParts.join("+")})`,
                getPriceInFormatted(total, project.country),
                getPriceInFormatted(total / project?.carpetArea, project.country),
            ])
            totalRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'd9ead3'}
            };

        })
    }

    const addListOfMakes = (sheet: any) => {
        const data = [
            {description: 'Maple wood', details: '1st class seasoned (as selected/approved)'},
            {description: 'Hard wood', details: 'Hollock/Red Maranti/Beech Wood'},
            {description: 'Plywood/Board (Commercial Grade)', details: 'Archidply/Greenply /Merino/Century ply/Duro'},
            {description: 'Plywood (Marine Grade)', details: 'Archidply/Greenply /Merino/Century ply/Duro'},
            {description: 'MDF Board- Exterior Grade', details: 'Green-Panelmax/Action Tessa/Green-Ecotec'},
            {description: 'Prelaminated MDF/Particle boards', details: 'Green-Panelmax/Action Tessa/Green-Ecotec'},
            {description: 'Laminate', details: 'Archidply/Greenlam/Formica/Merino'},
            {description: 'Wood Veneer', details: 'Archidply/Greenlam/Duro/Euro'},
            {description: 'Flush Doors', details: 'Archidply/Greenply /Merino/Kitply/Duro'},
            {description: 'Fire rated Doors', details: 'Bhawani/Sukri/Galaxy/Shakti Harmen'},
            {description: 'Plaster of Paris', details: 'Shriram/Sakarni (Superfine)'},
            {description: 'Putty', details: 'Birla/Asian/ICI/Nerolac'},
            {description: 'Paint', details: 'ICI Dulux /Asian/Berger/Nerolac'},
            {description: 'Wood Preservatives(Anti Termite Paint)', details: 'Wood Care/Wood Guard/Pidilite.'},
            {description: 'Fire Retardant paint', details: 'Navair Viper/Promat/Fire Flame'},
            {description: 'PU polish/ Melamine Polish', details: 'Timbertone of ICI Dulux/MRF/Wudfin of Pidilite Industries'},
            {description: 'Plain float glass', details: 'Saint-Gobain/Modiguard/Asahi'},
            {description: 'Plain mirror', details: 'Saint-Gobain/Modiguard/Asahi'},
            {description: 'Cabinet Hardware/ Drawer Slide System', details: 'Hettich/Hafele'},
            {description: 'Cabinet Locks', details: 'Hettich/Hafele'},
            {description: 'Door Hardware', details: 'Dorma/Geze or equivalent'},
            {description: 'Screws, Nails etc.', details: 'GKW, Nettle fold, SS Star Head Screw as per Manufacturers specs for Hardware/Fittings'}
        ];
        let p3HeaderRow = sheet.addRow([
            "SL.NO",
            "ITEM DESCRIPTION",
            "",
            "APPROVED MAKE/ MANUFACTURERS/ SUPPLIER"
        ])
        p3HeaderRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '999999'},
            bgColor: {argb: '000000'}
        };

        p3HeaderRow.font = {
            color: {
                argb: 'FFFFFF',
            },
            bold: true
        }
        data.map((row: any, index: number) => {
            if (index == 0) {
                const p3Row = sheet.addRow([
                    "",
                    "C&I"
                ])

                p3Row.font = {
                    bold: true
                }
            }
            sheet.addRow([
                index + 1,
                row.description,
                "",
                row.details
            ])
        })
    }

    const addListOfExclusions = (sheet: any) => {
        let p3HeaderRow = sheet.addRow([
            "SL.NO",
            "ITEM DESCRIPTION",
            "",
            "REMARKS"
        ])
        p3HeaderRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '999999'},
            bgColor: {argb: '000000'}
        };

        p3HeaderRow.font = {
            color: {
                argb: 'FFFFFF',
            },
            bold: true
        }
    }

    const addExcelHeading = (sheet: any, name: string, subHeading: string, lastColumn: string) => {
        sheet.mergeCells(`A1:${lastColumn}1`);
        const customCell = sheet.getCell("A1");
        customCell.value = name;

        customCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'B11E3D'}
        };
        customCell.font = {
            color: {
                argb: 'FFFFFF',
            },
            bold: true
        }
        customCell.alignment = {vertical: 'middle', horizontal: 'center'};

        sheet.mergeCells(`A2:${lastColumn}2`);
        sheet.mergeCells(`A3:${lastColumn}3`);
        const anotherRow = sheet.getCell("A3");
        anotherRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '000000'}
        };
        let count = 4;
        Object.keys(project).map((key: any) => {
            if (key == 'name') {
                sheet.mergeCells(`B${count}:D${count}`);
                const customProjectCell = sheet.getCell(`A${count}`);
                customProjectCell.font = {bold: true};
                customProjectCell.value = "PROJECT:";
                const customProjectValueCell = sheet.getCell(`B${count}`);
                customProjectValueCell.value = project.name;
                sheet.mergeCells(`E${count}:F${count}`);
                count++
            }
            if (key == 'location') {
                sheet.mergeCells(`B${count}:D${count}`);
                const customProjectCell = sheet.getCell(`A${count}`);
                customProjectCell.font = {bold: true};
                customProjectCell.value = "LOCATION:";
                const customProjectValueCell = sheet.getCell(`B${count}`);
                customProjectValueCell.value = project.LOCATION;
                sheet.mergeCells(`E${count}:F${count}`);
                count++
            }
            if (key == 'createdAt') {
                sheet.mergeCells(`B${count}:D${count}`);
                const customProjectCell = sheet.getCell(`A${count}`);
                customProjectCell.font = {bold: true};
                customProjectCell.value = "DATE:";
                const customProjectValueCell = sheet.getCell(`B${count}`);
                customProjectValueCell.value = getFormattedDate(project.createdAt);
                sheet.mergeCells(`E${count}:F${count}`);
                count++
            }
            if (key == 'projectDetails') {
                sheet.mergeCells(`B${count}:D${count}`);
                const customProjectCell = sheet.getCell(`A${count}`);
                customProjectCell.font = {bold: true};
                customProjectCell.value = "Super Builtup Area:";
                const customProjectValueCell = sheet.getCell(`B${count}`);
                customProjectValueCell.value = project.projectDetails.builtUpArea;
                sheet.mergeCells(`E${count}:F${count}`);
                count++
            }

        });

        sheet.mergeCells(`B${count}:D${count}`);
        const customProjectCell = sheet.getCell(`A${count}`);
        customProjectCell.font = {bold: true};
        customProjectCell.value = "REVISION:";
        const customProjectValueCell = sheet.getCell(`B${count}`);
        customProjectValueCell.value = revision.name;
        sheet.mergeCells(`E${count}:F${count}`);
        count++;
        sheet.mergeCells(`A${count}:${lastColumn}${count}`);
        count++;
        const indexRow = sheet.addRow([subHeading])
        indexRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '000000'}
        };
        indexRow.font = {color: {argb: 'FFFFFF'}, bold: true};
        indexRow.alignment = {vertical: 'middle', horizontal: 'center'};
        const indexRowIdx = sheet.rowCount; // Find out how many rows are there currently
        const indexEndColumnIdx = sheet.columnCount; // Find out how many columns are in the worksheet

        sheet.mergeCells(indexRowIdx, 1, indexRowIdx, indexEndColumnIdx);

        let lastRow = sheet.addRow([])
        const currentRowIdx = sheet.rowCount; // Find out how many rows are there currently
        const endColumnIdx = sheet.columnCount; // Find out how many columns are in the worksheet

        sheet.mergeCells(currentRowIdx, 1, currentRowIdx, endColumnIdx);
        return count;
    }

    const handleClose = () => {
        setOpenPopup(false);
        props.handleCallBack({event: 'close'});
    }

    const handleHeaderButtonClick = async (data: any) => {
        if (data === "download-estimation") {
            loadEstimationDetails();
            fetchPartSummaryDetails();
        }
        if (data === "download-mep-sheets") {
            console.log("download-mep-sheets");
            loadMEPSheetDetails();
        }
    }

    const proceedTabChange = (id: string, data: any) => {
        let hideEstimationTab = false;
        let hideEngineTabs = false;
        if (id == 'site-condition' && (data?.type == 'scratch' || data?.type == 'clone')) {
            if (project?.stage == 'DETAILS') {
                hideEngineTabs = true;
            }
            id = 'estimation';
            if (project?.sourceEstimationType == "engine") {
                id = 'site-condition';
            }
        } else {
            if (project?.stage == 'DETAILS') {
                hideEstimationTab = true;
            }
        }

        tabs.map((tab: any) => {
            if (hideEstimationTab && tab.id == 'estimation') {
                tab.disabled = true;
            }
            if (hideEngineTabs && (["site-condition", "head-count", "e-catalog"].includes(tab.id))) {
                tab.disabled = true;
            }

            if (tab.id == id) {
                tab.disabled = false;
            }
        });

        switch (id) {
            case "head-count":
                project.stage = "HEADCOUNT";
                break;
            case "e-catalog":
                project.stage = "CATALOG";
                break;
            case "summary":
                project.stage = "ESTIMATION";
                break;
        }

        setCategoryTabValue(id);
    }

    const handleDisableNextTabs = (id: string, changeTab: any) => {
        console.log("handleDisableNextTabs called");
    }

    const updateShowProjectParameters = () => {
        let showParameters = false;
        if (project.estimationType === "engine" || (project.estimationType === "clone" && project.sourceEstimationType === "engine")) {
            showParameters = true;
        }

        setShowProjectParameters(showParameters);
    }

    const getProjectRevisionDesignType = () => {
        api.get(`/procurement/project/parameter/design-type/${project.id}/${revision.id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            setRevisionDesignType(response);
        }).catch(ex => {
            console.log(ex);
        })
    }

    useEffect(() => {
        if (subCategoryTabValue != "" && categoryTabValue !== "summary") {
            categories.forEach((category: any) => {
                if (category.id == selectedCategory.id) {
                    category.subCategories.forEach((subCategory: any) => {
                        if (subCategoryTabValue == subCategory.id) {
                            setSelectedSubCategory(subCategory);
                        }
                    });
                }
            });
        }
    }, [subCategoryTabValue]);

    useEffect(() => {
        if (categoryTabValue == "summary") {
            setHeaderButtons([{
                id: "download-mep-sheets",
                label: "Download MEP Sheets",
                variant: "contained",
                color: "primary",
                permissions: ["super-admin", "edit-projects", "edit-estimation"],
            },
                {
                    id: "download-estimation",
                    label: "Download Estimation",
                    variant: "contained",
                    color: "primary",
                    permissions: ["super-admin", "edit-projects", "edit-estimation"],
                }]);
        } else {
            setHeaderButtons([]);
        }
        if (categoryTabValue !== "summary" && categoryTabValue !== "" && categories.length > 0) {
            categories.forEach((category: any) => {
                if (category.id === categoryTabValue) {
                    setSelectedCategory(category);
                    if (category.code == "FF") {
                        if (category.subCategories[0].code == "CA") {
                            setSubCategoryTabValue(category.subCategories[1]?.id || "");
                        } else {
                            setSubCategoryTabValue(category.subCategories[0]?.id || "");
                        }
                    } else {
                        setSubCategoryTabValue(category.subCategories[0]?.id || "");
                    }
                }
            });
        } else {
            setSubCategoryTabValue("");
        }
    }, [categoryTabValue, categories]);

    useEffect(() => {
        if (Object.keys(estimationSummary).length > 0 && estimations.length > 0) {
            if (!currentRevision?.downloaded) {
                markDownloaded();
            }
            if (props.categories.length > 0) {
                const structuredDetails: any[] = estimationSummary.parts.map((part: any) => {
                    let partTotal: number = 0;
                    const combinedCategories: any[] = categories.flatMap((category: any) => {
                        const subCategories = category.subCategories.flatMap((subCategory: any) => {
                            const estimation: any = estimations.find(
                                (estimation: any) =>
                                    estimation.materialSubCategoryId === subCategory.id
                            );

                            if (estimation) {
                                let subCategoryTotal: number = 0;
                                let subCategoryTotalSkus: number = 0;
                                let totalCogs = 0;
                                const matchedEstimationItems: any[] =
                                    estimation &&
                                    estimation.estimationItems &&
                                    estimation.estimationItems.length > 0 &&
                                    estimation.estimationItems.flatMap(
                                        (estimationItem: any) => {
                                            const matchedSkus: any[] =
                                                estimationItem.estimationItemSkus.filter(
                                                    (sku: any) =>
                                                        part &&
                                                        part.items &&
                                                        part.items.length > 0 &&
                                                        part.items.some(
                                                            (item: any) => item.skuId === sku.skuId
                                                        )
                                                );

                                            if (matchedSkus.length > 0) {
                                                subCategoryTotalSkus += matchedSkus.length;
                                                return {
                                                    ...estimationItem,
                                                    estimationItemSkus:
                                                        matchedSkus &&
                                                        matchedSkus.length > 0 &&
                                                        matchedSkus.map((sku: any) => {
                                                            subCategoryTotal += (sku.totalQuantity * sku.sellingPrice) + (sku.totalQuantity * sku.gsvDomainChargesPerUnit.total);
                                                            totalCogs += ((sku.totalQuantity * sku.targetPrice) + (sku.totalQuantity * sku.cogsDomainChargesPerUnit.total));
                                                            return sku;
                                                        }),
                                                };
                                            }

                                            return [];
                                        }
                                    );

                                if (matchedEstimationItems.length > 0) {
                                    partTotal += subCategoryTotal;
                                    return {
                                        id: subCategory.id,
                                        name: subCategory.name,
                                        code: subCategory.code,
                                        status: subCategory.status,
                                        total: subCategoryTotal,
                                        totalCogs: totalCogs,
                                    };
                                }
                            }

                            return [];
                        }).filter((subCategory: any) => subCategory !== null);
                        if (subCategories.length > 0) {
                            return {
                                ...category,
                                subCategories,
                            };
                        }

                        return null;
                    }).filter((category: any) => category !== null)
                    return {
                        id: part.id,
                        name: part.name,
                        status: part.status,
                        total: parseFloat(String(partTotal)),
                        totalSkus: parseInt(part.totalSkus),
                        categories: combinedCategories,
                    };
                });
                const updatedCategories = props.categories.map((category: any) => {
                    let estimationTotal: number = 0;
                    let estimationTotalCogs: number = 0;
                    let estimationTotalSkus = 0;
                    const subCategories = category.subCategories.map((subCategory: any) => {
                        const estimation = estimations?.find((estimation: any) =>
                            estimation.materialSubCategoryId === subCategory.id
                        );
                        if (estimation && estimation.estimationItems.length > 0) {
                            let subCategoryTotalCogs: number = 0;
                            let subCategoryTotal: number = 0;
                            let subCategoryTotalSkus = 0;
                            const updatedEstimations = estimation.estimationItems.map((estimationItem: any) => ({
                                ...estimationItem,
                                estimationItemSkus:
                                    estimationItem.estimationItemSkus.length > 0 &&
                                    estimationItem.estimationItemSkus.map((sku: any) => {
                                        subCategoryTotalCogs = subCategoryTotalCogs + (parseInt(sku.totalQuantity) * parseFloat(sku.targetPrice)) + (parseInt(sku.totalQuantity) * parseFloat(sku?.cogsDomainChargesPerUnit?.total));
                                        subCategoryTotal = subCategoryTotal + (parseInt(sku.totalQuantity) * parseFloat(sku.sellingPrice)) + (parseInt(sku.totalQuantity) * parseFloat(sku?.gsvDomainChargesPerUnit?.total));
                                        subCategoryTotalSkus++;
                                        return sku;
                                    }),
                            }));

                            estimationTotal += subCategoryTotal;
                            estimationTotalCogs = estimationTotalCogs + subCategoryTotalCogs;
                            estimationTotalSkus += subCategoryTotalSkus;

                            return {
                                ...subCategory,
                                total: subCategoryTotal,
                                totalCogs: subCategoryTotalCogs,
                                estimationTotalSkus: subCategoryTotalSkus,
                                estimationItems: updatedEstimations,
                            };
                        }
                        return null;
                    }).filter((subCategory: any) => subCategory !== null);
                    subCategories.sort((a: any, b: any) => a.total - b.total);

                    if (subCategories.length > 0) {
                        return {
                            ...category,
                            total: estimationTotal,
                            totalCogs: estimationTotalCogs,
                            estimationTotalSkus,
                            subCategories,
                        };
                    }

                    return null;
                }).filter((category: any) => category !== null);
                downloadCSV(updatedCategories, structuredDetails);
            }
        }
    }, [estimations, estimationSummary]);

    useEffect(() => {
        //project.stage = "PARAMETER";
        switch (project.stage) {
            case "DETAILS":
            case "PARAMETER":
                tabs.map((tab: any) => {
                    if (["site-condition"].includes(tab.id)) {
                        tab.disabled = false;
                    }
                });
                break;
            case "HEADCOUNT":
                tabs.map((tab: any) => {
                    if (["site-condition", "head-count"].includes(tab.id)) {
                        tab.disabled = false;
                    }
                });
                break;
            case "CATALOG":
                tabs.map((tab: any) => {
                    if (project.estimationType === "scratch") {
                        if (tab.id == "estimation") {
                            tab.disabled = false;
                        }
                    }
                    if (project.estimationType === 'engine' || (project.estimationType === 'clone' && project.sourceEstimationType === 'engine')) {
                        if (["site-condition", "head-count", "e-catalog"].includes(tab.id)) {
                            tab.disabled = false;
                        }
                    }
                });
                break;
            case "ESTIMATION":
                tabs.map((tab: any) => {
                    if (project.estimationType === "scratch") {
                        if (tab.id == "estimation") {
                            tab.disabled = false;
                        }
                    }
                    if (project.estimationType === 'engine' || (project.estimationType === 'clone' && project.sourceEstimationType === 'engine')) {
                        if (["site-condition", "head-count", "e-catalog", "summary"].includes(tab.id)) {
                            tab.disabled = false;
                        }
                    }
                });
                setHideDownload(false);
                break;
            default:
                tabs.map((tab: any) => {
                    tab.disabled = false;
                });
                break;
        }
        if (["DETAILS", "PARAMETER", "HEADCOUNT", "CATALOG"].includes(project.stage) && (project.estimationType === 'engine' || (project.estimationType === 'clone' && project.sourceEstimationType === 'engine'))) {
            setCategoryTabValue("site-condition");
            setHideDownload(true);
        } else {
            setCategoryTabValue("summary");
        }
        setCurrentRevision(revision);
        project.revision = revision;
        setProjectWithRevision(project);
    }, [revision]);

    useEffect(() => {
        updateShowProjectParameters();
        if (props.openPopup) {
            getProjectRevisionDesignType();
            setOpenPopup(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            disableScrollLock={false}
            open={openPopup}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{
                "& > .MuiBackdrop-root": {
                    backdropFilter: "blur(0px)",
                    backgroundColor: "transparent",
                },
            }}
        >
            <Box sx={{
                position: "absolute" as "absolute",
                bottom: 0,
                width: "100%",
                height: '90%',
                border: 0,
                backgroundColor: "background.paper",
                boxShadow: 1,
                '&:focus-visible': {
                    outline: 'none'
                }
            }}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box sx={{width: '95%'}}>
                                <PageHeading
                                    title={"Project: " + project.name + " (Revision: " + currentRevision?.name + ")"}
                                    showButtons={true}
                                    buttons={headerButtons}
                                    handleHeaderButtonClick={handleHeaderButtonClick}
                                />
                            </Box>
                            <Box onClick={handleClose}
                                 sx={{display: 'flex', justifyContent: 'center', width: '5%', cursor: 'pointer'}}>
                                <CloseIcon/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.modalFullBody}>
                        <Box sx={style.bodyContainer}>
                            {(isLoading || isSummaryLoading) && (
                                <Loader/>
                            )}
                            <Box className={"common-space-container"}>
                                <Box display={"flex"} alignItems={"flex-start"} sx={{pb: 1}}>
                                    <Box sx={{width: '100%'}}>
                                        <Box>
                                            {categoryTabValue !== "" && (
                                                <EstimationCategoryTab
                                                    hideZeroSkus={false}
                                                    includeSummaryTab={true}
                                                    categories={categories}
                                                    disableCategories={project.status == 'COMPLETED' || (["DETAILS", "PARAMETER", "HEADCOUNT", "ECATALOG"].includes(project.stage) && project.estimationType === 'engine') ? true : false}
                                                    categoryTabValue={categoryTabValue}
                                                    handleCategoryTabChange={handleCategoryTabChange}
                                                    showProjectParameters={showProjectParameters}
                                                    tabs={tabs}
                                                />
                                            )}
                                            {selectedCategory.hasOwnProperty("id") && (categoryTabValue !== "summary"
                                                && categoryTabValue !== "site-condition" && categoryTabValue !== "head-count"
                                                && categoryTabValue !== "e-catalog") && (
                                                <SubCategoryTab
                                                    hideZeroSkus={false}
                                                    subCategoryTabValue={subCategoryTabValue}
                                                    subCategories={selectedCategory?.subCategories}
                                                    categoryTabValue={categoryTabValue}
                                                    handleSubCategoryTabChange={handleSubCategoryTabChange}
                                                />
                                            )}
                                            <Box sx={{pt: 2, pb: 1}}>
                                                {subCategoryTabValue !== "" && categoryTabValue !== "summary"
                                                    && categoryTabValue !== "site-condition" && categoryTabValue !== "head-count"
                                                    && categoryTabValue !== "e-catalog" && selectedSubCategory.id == subCategoryTabValue && (
                                                        <CustomTabPanel value={subCategoryTabValue}
                                                                        index={subCategoryTabValue}>
                                                            <EstimationItemListing
                                                                revision={currentRevision}
                                                                project={project}
                                                                subCategoryId={subCategoryTabValue}
                                                                showUpdatedCharges={props.showUpdatedCharges}
                                                                category={selectedCategory}
                                                                subCategory={selectedSubCategory}
                                                            />
                                                        </CustomTabPanel>
                                                    )}

                                                {categoryTabValue === "summary" && (
                                                    <EstimationSummary
                                                        revisionDesignType={revisionDesignType}
                                                        categories={categories}
                                                        revision={currentRevision}
                                                        project={project}
                                                        showUpdatedCharges={props.showUpdatedCharges}
                                                        refreshRevision={refreshRevision}
                                                    />
                                                )}

                                                {categoryTabValue === "site-condition" && (
                                                    <Box sx={{p: 2}}>
                                                        <SiteConditionTab
                                                            project={projectWithRevision}
                                                            proceedTabChange={proceedTabChange}
                                                            handleDisableNextTabs={handleDisableNextTabs}
                                                            revision={currentRevision}
                                                        />
                                                    </Box>
                                                )}
                                                {categoryTabValue === "head-count" && (
                                                    <Box sx={{p: 2}}>
                                                        <HeadCountTab
                                                            project={projectWithRevision}
                                                            proceedTabChange={proceedTabChange}
                                                            handleDisableNextTabs={handleDisableNextTabs}
                                                            revision={currentRevision}
                                                        />
                                                    </Box>
                                                )}

                                                {categoryTabValue === "e-catalog" && (
                                                    <Box sx={{p: 2}}>
                                                        <ECatalogTab
                                                            showUpdatedCharges={props.showUpdatedCharges}
                                                            categories={categories}
                                                            project={projectWithRevision}
                                                            proceedTabChange={proceedTabChange}
                                                            revision={currentRevision}
                                                        />
                                                    </Box>
                                                )}


                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default EstimationDetailsPopupNew;