import {Link, useParams} from "react-router-dom";
import React, {ChangeEvent, useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import Loader from "../../../components/Loader";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import PageHeading from "../../../components/page-heading/PageHeading";
import {getContentUrl, getFormattedDate, getFormattedDateTime, getPriceInFormatted,} from "../../../utils/Common";
import TextField from "@mui/material/TextField";
import {useAppDispatch} from "../../../app/hooks";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import ReadMore from "../../../components/read-more/ReadMore";
import RejectParticipation from "./popups/RejectParticipation";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CloseIcon from "@mui/icons-material/Close";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AddIcon from "@mui/icons-material/Add";
import AddLink from "../../../components/package-tabs/popup/AddLink";
import AddComment from "../../../components/package-tabs/popup/AddComment";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ProjectDetailsRow from "../../../components/vendor/ProjectDetailsRow";
import EventTermsAndCondition from "./popups/EventTermsAndCondition";

const styles = {
    leftBorder: {
        py: 2,
        px: 1,
        borderLeft: 1,
        borderColor: "divider",
        height: "100%",
    },
    rightBorder: {
        py: 2,
        px: 1,
        borderRight: 1,
        borderColor: "divider",
        height: "100%",
    },
    xBorder: {
        py: 2,
        px: 1,
        borderRight: 1,
        borderLeft: 1,
        borderColor: "divider",
        height: "100%",
    },
};

const VendorEventDetails = () => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const termFile = `${getContentUrl()}/innov8/terms-and-conditions-for-use-of-website.pdf`;
    const [event, setEvent] = useState<any>({});
    const [vendorEventDetail, setVendorEventDetail] = useState<any>({});
    const [vendorResponded, setVendorResponded] = useState(false);
    const [openTermPopup, setOpenTermPopup] = useState(false);
    const dispatch = useAppDispatch();
    const [timeRemaining, setTimeRemaining] = useState<any>("");
    const [isEventExpired, setIsEventExpired] = useState(false);
    const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false);
    const [headerButtons, setHeaderButtons] = useState<any>([]);

    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState<any>(null);
    const [selectedLinkIndex, setSelectedLinkIndex] = useState<any>(null);
    const [selectedExistingDocumentIndex, setSelectedExistingDocumentIndex] = useState<any>(null);
    const [openAddLinkPopup, setOpenAddLinkPopup] = useState(false);
    const [openAddCommentPopup, setOpenAddCommentPopup] = useState(false);
    const [commentType, setCommentType] = useState("");
    const [openAddCommentToExistingPopup, setOpenAddCommentToExistingPopup] = useState(false);
    const [links, setLinks] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);
    const [existingDocuments, setExistingDocuments] = useState<any>([]);
    const [vendorUploadedRates, setVendorUploadedRates] = useState<any>([]);
    const [quoteUploadedFile, setQuoteUploadedFile] = useState<any>(null);
    const [submittedAnswers, setSubmittedAnswers] = useState<any>({});
    const [eventTotal, setEventTotal] = useState(0);
    const [showDescription, setShowDescription] = useState(true);
    const [allValues, setAllValues] = useState<any>({});

    const handleToggleDescription = () => {
        setShowDescription(prev => !prev);
    };

    const handlePriceChange = (e: any, packageItem: any) => {
        const newAllValues = {...allValues, [packageItem.id]: e.target.value};
        setAllValues(newAllValues);
    }

    const downloadQuotes = () => {
        setIsLoading(true);
        api.get(`/procurement/event/download-quote/${id}/${vendorEventDetail.id}`)
            .then((response: any) => {
                return response.data;
            })
            .then((response: any) => {
                const currentTime = new Date().valueOf();
                const url = window.URL.createObjectURL(new Blob([response]))
                const anchor = document.createElement("a");
                anchor.href = url;
                let eventName = event.name.replace(" ", "-");
                anchor.setAttribute('download', `${eventName}-${currentTime}-quote.csv`);
                anchor.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(ex => {
                console.log(ex);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleQuoteUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setQuoteUploadedFile(e.target.files[0]);
            setVendorUploadedRates([]);
            readRates(e.target.files[0]);
        }
    }

    const removeQuoteUploadedFile = () => {
        setVendorUploadedRates([]);
        setQuoteUploadedFile(null);
    }

    const handleAddLink = () => {
        setOpenAddLinkPopup(true);
    }

    const handleAddCommentToLink = (index: number) => {
        setSelectedLinkIndex(index);
        setCommentType("link");
        setOpenAddCommentPopup(true);
    }

    const handleDocumentUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files: any[] = [];
            Array.from(event.target.files).map((uploadedFile: any) => {
                files.push({document: uploadedFile, comment: ""});
            });
            if (documents.length > 0) {
                setDocuments([...documents, ...files]);
            } else {
                setDocuments(files);
            }
        }
    }

    const removeDocument = (index: number) => {
        let newDocuments: any = [...documents];
        newDocuments.splice(index, 1);
        setDocuments(newDocuments);
    }

    const removeFromExistingDocument = (index: number) => {
        let newDocuments: any = [...existingDocuments];
        newDocuments.splice(index, 1);
        setExistingDocuments(newDocuments);
    }

    const removeLink = (index: number) => {
        let newLinks: any = [...links];
        newLinks.splice(index, 1);
        setLinks(newLinks);
    }

    const handleAddCommentToExisting = (index: number) => {
        setSelectedExistingDocumentIndex(index);
        setOpenAddCommentToExistingPopup(true);
    }

    const handleAddComment = (index: number) => {
        setSelectedDocumentIndex(index);
        setCommentType("document");
        setOpenAddCommentPopup(true);
    }

    const handleAddLinkPopupCallback = (callBack: any) => {
        if (callBack.event == "add") {
            setLinks((prevLink: any) => [...prevLink, {url: callBack.data.link, name: callBack.data.name, comment: ""}]);
        }
        setOpenAddLinkPopup(false);
    }

    const handleAddCommentPopupCallback = (callBack: any) => {
        if (callBack.event == "add") {
            if (commentType == "link") {
                const allLinks = [...links];
                allLinks[selectedLinkIndex].comment = callBack.data;
                setLinks(allLinks);
                setSelectedLinkIndex(null);
            } else {
                const allDocuments = [...documents];
                allDocuments[selectedDocumentIndex].comment = callBack.data;
                setDocuments(allDocuments);
                setSelectedDocumentIndex(null);
            }
        }
        setCommentType("");
        setOpenAddCommentPopup(false);
    }

    const handleAddCommentToExistingPopupCallback = (callBack: any) => {
        if (callBack.event == "add") {
            let allDocuments: any[] = [...existingDocuments];
            let currentDocument: any = {...allDocuments[selectedExistingDocumentIndex]};
            currentDocument['comment'] = callBack.data;
            allDocuments[selectedExistingDocumentIndex] = currentDocument;
            setExistingDocuments(allDocuments);
        }
        setSelectedExistingDocumentIndex(null);
        setOpenAddCommentToExistingPopup(false);
    }

    const loadEvent = () => {
        setIsLoading(true);
        api
            .get(`/procurement/event/details-for-vendor/${id}`)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                setVendorEventDetail({});
                setEvent(response);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    const readRates = (file: any) => {
        setIsLoading(true);
        const formData: any = new FormData();
        formData.append("file", file);
        api
            .post(`/procurement/event/read-vendor-rates/${id}`, formData)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                if(response.error == false) {
                    let total = 0;
                    setVendorUploadedRates(response.data);
                    event?.eventPackages.map((eventPackage: any) => {
                        eventPackage.packageItems.map((packageItem: any, index: number) => {
                            response.data.map((vendorRate: any) => {
                                if(vendorRate.itemId == packageItem.id && parseFloat(vendorRate.price) > 0) {
                                    total += parseFloat(vendorRate.price) * packageItem.totalQuantity;
                                }
                            })
                        });
                    });
                    setEventTotal(total);
                }
            })
            .catch((ex) => {
                console.log(ex);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let errorFlag = false;

        const data: any = new FormData(e.target);

        const formData: any = new FormData();

        let answers: any = {};
        let vendorPrices: any = [];
        for (let [key, val] of data.entries()) {
            if (quoteUploadedFile == null) {
                event?.eventPackages.map((eventPackage: any) => {
                    eventPackage.packageItems.map((packageItem: any) => {
                        if (key == packageItem.id) {
                            vendorPrices.push({itemId: key, price: val});
                        }
                    });
                });
            }

            if (event?.questions.length > 0) {
                event?.questions.map((clientQuestion: any) => {
                    if (key == clientQuestion.id && val != "") {
                        if (answers.hasOwnProperty(key)) {
                            let allAnswers: any[] = answers[key];
                            allAnswers.push(val);
                            answers[key] = allAnswers;
                        } else {
                            answers[key] = [val];
                        }
                    }
                });
            }
        }

        if (event?.questions.length > 0) {
            event?.questions.map((clientQuestion: any) => {
                if (clientQuestion.mandatory && !answers.hasOwnProperty(clientQuestion.id)) {
                    errorFlag = true;
                    dispatch(
                        openSnackbar({
                            message: "Question: " + clientQuestion.question + " is mandatory",
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                }
            });
        }

        if (!errorFlag) {
            const questionData: any[] = [];
            for (let key in answers) {
                questionData.push({
                    questionId: key,
                    answers: answers[key]
                });
            }
            formData.append("questions", JSON.stringify(questionData));
        }

        if (errorFlag) {
            return;
        }

        Array.from(documents).map((selectedDocument: any) => {
            formData.append("documents[]", selectedDocument.document);
            formData.append("comments[]", selectedDocument.comment);
        });

        formData.append("description", data.get("description"));
        if (quoteUploadedFile == null && vendorPrices.length > 0) {
            formData.append("data", JSON.stringify(vendorPrices));
        }

        if (quoteUploadedFile != null) {
            formData.append("quoteFile", quoteUploadedFile);
            formData.append("quoteUploaded", true);
        } else {
            formData.append("quoteUploaded", false);
            if (vendorPrices.length == 0) {
                dispatch(
                    openSnackbar({
                        message: "Please submit at least one item price",
                        backgroundColor: ERROR_COLOR,
                    })
                );
                return;
            }
        }

        formData.append("links", JSON.stringify(links));
        formData.append("eventVendorId", vendorEventDetail.id);

        setIsLoading(true);
        api.post(`/procurement/event/vendor-bidding/${id}`, formData)
            .then((response) => {
                return response.data;
            }).then((res) => {
            if (res) {
                removeQuoteUploadedFile();
                setEvent({});
                setVendorEventDetail({});
                setExistingDocuments([]);
                setDocuments([]);
                setLinks([]);
                dispatch(
                    openSnackbar({
                        message: "Successfully submitted the bid",
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                loadEvent();
            }
        }).catch((ex) => {
            dispatch(
                openSnackbar({
                    message: "Oops. Something went wrong, please try again",
                    backgroundColor: ERROR_COLOR,
                })
            );
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleHeaderButtonClick = (data: any) => {
        if (data === 'participate') {
            markParticipation();
        }

        if (data == 'reject') {
            setOpenRejectConfirmation(true);
        }
    }

    const handleRejectPopupCallback = (callBack: any) => {
        if (callBack.event == "reject") {
            const form = {
                reason: callBack.data
            };
            api.post(`/procurement/event/reject-participate/${vendorEventDetail.id}`, form).then((response) => {
                return response.data;
            }).then((res) => {
                setHeaderButtons([]);
                setVendorEventDetail({});
                setEvent(res);
            }).catch((ex) => {
                dispatch(
                    openSnackbar({
                        message: ex?.response.data,
                        backgroundColor: ERROR_COLOR,
                    })
                );
                console.log(ex);
            }).finally(() => {
                setIsLoading(false);
            });
        }

        setOpenRejectConfirmation(false);
    }

    const markParticipation = () => {
        api.get(`/procurement/event/mark-participate/${vendorEventDetail.id}`).then((response) => {
            return response.data;
        }).then((res) => {
            setHeaderButtons([]);
            setVendorEventDetail({});
            setEvent(res);
        }).catch((ex) => {
            dispatch(
                openSnackbar({
                    message: ex?.response.data,
                    backgroundColor: ERROR_COLOR,
                })
            );
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleAccept = () => {
        setIsLoading(true);
        const form = {termFile, eventVendorId: vendorEventDetail.id};
        api
            .post(`/procurement/event/accept-terms/${id}`, form)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                if (res) {
                    setIsLoading(false);
                    setOpenTermPopup(false);
                    loadEvent();
                }
            })
            .catch((ex) => {
                dispatch(
                    openSnackbar({
                        message: ex?.response.data,
                        backgroundColor: ERROR_COLOR,
                    })
                );
                console.log(ex);
                setIsLoading(false);
            });
    };

    const onChangeHandlerMultiple = (e: any, option: any) => {
        const {checked, name} = e.target;
        let answers = submittedAnswers.hasOwnProperty(name) ? submittedAnswers[name] : [];
        if (checked) {
            answers.push(option);
        } else {
            answers.splice(answers.indexOf(option), 1);
        }
        const allAnswers: any = {...submittedAnswers};
        allAnswers[name] = answers;
        setSubmittedAnswers(allAnswers);
    }

    useEffect(() => {
        if (Object.keys(event).length > 0) {
            const endDate = new Date(event.endDate);
            const today = new Date();
            // endDate.setHours(23, 59, 59, 0);
            // today.setHours(23, 59, 59, 0);
            if (today > endDate) {
                console.log(1111);
                // setIsEventExpired(true);
            }
            setVendorEventDetail(event.eventVendor);
        }
    }, [event]);

    useEffect(() => {
        if (Object.keys(allValues).length > 0) {
            let total = 0;
            event?.eventPackages.map((eventPackage: any) => {
                eventPackage.packageItems.map((packageItem: any, index: number) => {
                    if(allValues.hasOwnProperty(packageItem.id)) {
                        total += allValues[packageItem.id] * packageItem.totalQuantity;
                    }
                });
            });
            setEventTotal(total);
        }
    }, [allValues]);

    useEffect(() => {
        if (Object.keys(vendorEventDetail).length > 0) {
            if (event.status == "PUBLISHED" && vendorEventDetail.status == "CREATED") {
                const buttons: any[] = [];
                buttons.push({
                    id: 'reject', label: 'Not Interested', variant: 'contained', color: 'error'
                });

                buttons.push({
                    id: 'participate', label: 'Participate', variant: 'contained'
                });
                setHeaderButtons(buttons);
            }

            if (!vendorEventDetail.termAccepted) {
                setOpenTermPopup(true);
            }

            if (vendorEventDetail.vendorDocuments.length > 0) {
                setExistingDocuments(vendorEventDetail.vendorDocuments);
            }

            const eventVendorDetails = vendorEventDetail.vendorRevisions[0]?.eventVendorDetails;
            let eventPrices: any = {};
            event.eventPackages.map((eventPackage: any) => {
                eventPackage.packageItems.map((packageItem: any) => {
                    if (Array.isArray(eventVendorDetails) && eventVendorDetails.length > 0) {
                        eventVendorDetails.map((eventDetail: any) => {
                            if (eventDetail.packageItemId == packageItem.id) {
                                eventPrices[packageItem.id] = eventDetail.price;
                            }
                        });
                    }
                })
            });

            if(Object.keys(eventPrices).length > 0) {
                setAllValues(eventPrices);
            }

            (Array.isArray(eventVendorDetails) && eventVendorDetails.length > 0) ? setVendorResponded(true) : setVendorResponded(false);
            let vendorAnswers: any = {};
            if (vendorEventDetail.vendorQuestions.length > 0) {
                vendorEventDetail.vendorQuestions.map((vendorQuestion: any) => {
                    vendorAnswers[vendorQuestion.eventQuestionnaireId] = vendorQuestion.answers;
                });
            }
            setSubmittedAnswers(vendorAnswers);
        }
    }, [vendorEventDetail]);

    useEffect(() => {
        if (vendorEventDetail.status !== "REJECTED") {
            const timer = setInterval(() => {
                const now = new Date();
                const endDate = new Date(event.endDate);
                const timeDifference = endDate.getTime() - now.getTime();

                if (!isNaN(timeDifference)) {
                    if (timeDifference > 0) {
                        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                        setTimeRemaining({days, hours, minutes, seconds,});
                    } else {
                        setIsEventExpired(true);
                        setTimeRemaining({days: 0, hours: 0, minutes: 0, seconds: 0});
                        clearInterval(timer);
                    }
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [event.endDate]);

    useEffect(() => {
        loadEvent();
    }, [id]);

    if (isLoading) {
        return <Loader/>;
    }

    const TimeBox = ({value, label}: any) => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "8px",
                border: "2px solid #8BB8E0",
                borderRadius: "8px",
                minWidth: "50px",
                minHeight: "60px",
                marginRight: "8px",
                backgroundColor: "background.paper",
            }}
        >
            <Typography sx={{fontSize: "20px", fontWeight: 600, color: "#8BB8E0"}}>
                {value}
            </Typography>
            <Typography sx={{fontSize: "12px", color: "#8BB8E0"}}>{label}</Typography>
        </Box>
    );

    return (
        <Box className={"common-space-container"}>
            {event.hasOwnProperty("eventPackages") && !openTermPopup && (
                <>
                    <PageHeading
                        title={`Event Details: ${event.name}`}
                        showButtons={true}
                        buttons={headerButtons}
                        handleHeaderButtonClick={handleHeaderButtonClick}
                    />
                    <Box>
                        <Box sx={{py: 2}}>
                            <ProjectDetailsRow project={event.project}/>
                        </Box>
                        <Typography variant={"h4"} sx={{pb: 1}}>Event Details</Typography>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box sx={{width: "75%"}}>
                                <Grid
                                    container
                                    sx={{
                                        backgroundColor: "primary.light",
                                        borderTop: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <Grid item xs>
                                        <Typography sx={styles.leftBorder}>Event Name</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={{py: 2, px: 1}}>Package Name</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={{py: 2, px: 1}}>Created On</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={{py: 2, px: 1}}>Start Date</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={{py: 2, px: 1}}>End Date</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>Responded?</Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    sx={{borderBottom: 1, borderColor: "divider"}}
                                >
                                    <Grid item xs>
                                        <Typography sx={styles.xBorder}>
                                            {event?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>
                                            {event?.eventPackages[0]?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>
                                            {getFormattedDate(event?.createdAt)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>
                                            {getFormattedDateTime(event?.startDate)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>
                                            {getFormattedDateTime(event?.endDate)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>
                                            {vendorResponded ? "Yes" : "No"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            {vendorEventDetail.status !== "REJECTED" && (
                                <Grid container
                                      sx={{
                                          width: "25%",
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "center",
                                      }}
                                >
                                    <Box sx={{display: "flex"}}>
                                        <TimeBox value={timeRemaining.days} label="Days"/>
                                        <TimeBox value={timeRemaining.hours} label="Hours"/>
                                        <TimeBox value={timeRemaining.minutes} label="Mins"/>
                                        <TimeBox value={timeRemaining.seconds} label="Secs"/>
                                    </Box>
                                </Grid>
                            )}
                        </Box>

                        <Box sx={{py: 4}}>
                            <Box component={"form"} onSubmit={handleSubmit}>
                                {(vendorEventDetail.status == "PARTICIPATED" || vendorEventDetail.status == "SUBMITTED") && (
                                    <>
                                        <Box sx={{py: 2}}>
                                            <Box className={"space-between"}>
                                                <InputLabel sx={{pb: 1, fontSize: "0.8rem"}}>
                                                    Any Bidding Comments
                                                </InputLabel>
                                            </Box>
                                            <Box>
                                                <TextField
                                                    fullWidth size={"small"} variant={"outlined"} className={"inputBox"}
                                                    name="description"
                                                    defaultValue={vendorEventDetail.description || ""}
                                                    multiline rows={10}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "5px",
                                                            borderColor: 'primary.light',
                                                            fontSize: "0.8rem"
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{width: '60%'}}>
                                            <Box sx={{py: 2, width: '90%'}}>
                                                {event?.documents.length > 0 && (
                                                    <Box sx={{py: 2}}>
                                                        <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                                            Documents From Client ({event?.documents?.length})
                                                        </Typography>

                                                        {event.documents.map((clientDocument: any, docIndex: number) => (
                                                            <Box key={"client-document-" + docIndex}
                                                                 className={"space-between"}>
                                                                <Box className={"space-between"}
                                                                     sx={{border: 1, p: 1, mt: 1, width: '65%'}}>
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{pr: 1}}>
                                                                        {clientDocument.type == "link" ?
                                                                            <InsertLinkIcon/> :
                                                                            <InsertDriveFileOutlinedIcon/>}
                                                                    </Box>
                                                                    <Typography
                                                                        className={"vertically-center-aligned"}
                                                                        sx={{wordBreak: "break-all"}}>{clientDocument.name}</Typography>
                                                                    <Link to={clientDocument?.documentLink}
                                                                          target={"_blank"}
                                                                          className={"vertically-center-aligned"}
                                                                          style={{paddingRight: 1}}>
                                                                        View
                                                                    </Link>
                                                                </Box>
                                                                {clientDocument.comment !== "" && (
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{px: 1, width: '35%'}}>
                                                                        <ReadMore
                                                                            text={"Comment: " + clientDocument.comment}
                                                                            maxChars={20}/>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}
                                                <Box>
                                                    <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                                        Attach Documents
                                                    </Typography>

                                                    {links.length > 0 && (
                                                        links.map((uploadedLink: any, linkIndex: number) => (
                                                            <Box key={"link-" + linkIndex} className={"space-between"}>
                                                                <Box className={"space-between"}
                                                                     sx={{border: 1, p: 1, mt: 1, width: '70%'}}>
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{pr: 1}}>
                                                                        <InsertLinkIcon/>
                                                                    </Box>
                                                                    <Typography className={"vertically-center-aligned"}
                                                                                sx={{wordBreak: "break-all"}}>{uploadedLink.name}</Typography>
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{pr: 1}}>
                                                                        <CloseIcon sx={{cursor: 'pointer'}}
                                                                                   onClick={() => removeLink(linkIndex)}/>
                                                                    </Box>
                                                                </Box>
                                                                {uploadedLink.comment == "" ? (
                                                                    <Button variant={"text"}
                                                                            onClick={() => handleAddCommentToLink(linkIndex)}>
                                                                        Add Comment
                                                                    </Button>
                                                                ) : (
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{px: 2, width: '30%'}}>
                                                                        <ReadMore text={uploadedLink.comment}
                                                                                  maxChars={20}/>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        ))
                                                    )}
                                                    {existingDocuments.length > 0 && (
                                                        <Box>
                                                            {existingDocuments.map((existingDocument: any, docIndex: number) => (
                                                                <Box key={"existing-document-" + docIndex}
                                                                     className={"space-between"}>
                                                                    <Box className={"space-between"}
                                                                         sx={{border: 1, p: 1, mt: 1, width: '65%'}}>
                                                                        <Box className={"vertically-center-aligned"}
                                                                             sx={{pr: 1}}>
                                                                            <TextSnippetIcon/>
                                                                        </Box>
                                                                        <Typography
                                                                            className={"vertically-center-aligned"}
                                                                            sx={{wordBreak: "break-all"}}>{existingDocument.name}</Typography>
                                                                        <Box className={"vertically-center-aligned"}
                                                                             sx={{pr: 1}}>
                                                                            <CloseIcon sx={{cursor: 'pointer'}}
                                                                                       onClick={() => removeFromExistingDocument(docIndex)}/>
                                                                        </Box>
                                                                    </Box>
                                                                    {existingDocument.comment == "" ? (
                                                                        <Button variant={"text"}
                                                                                onClick={() => handleAddCommentToExisting(docIndex)}>
                                                                            Add Comment
                                                                        </Button>
                                                                    ) : (
                                                                        <Box className={"vertically-center-aligned"}
                                                                             sx={{px: 1, width: '35%'}}>
                                                                            <ReadMore text={existingDocument.comment}
                                                                                      maxChars={20}/>
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    )}
                                                    {documents.length > 0 && (
                                                        <Box>
                                                            {Array.from(documents).map((selectedDocument: any, docIndex: number) => {
                                                                return (
                                                                    <Box key={"document-" + docIndex}
                                                                         className={"space-between"}>
                                                                        <Box className={"space-between"}
                                                                             sx={{
                                                                                 border: 1,
                                                                                 p: 1,
                                                                                 mt: 1,
                                                                                 width: '70%'
                                                                             }}>
                                                                            <Box className={"vertically-center-aligned"}
                                                                                 sx={{pr: 1}}>
                                                                                <TextSnippetIcon/>
                                                                            </Box>
                                                                            <Typography
                                                                                className={"vertically-center-aligned"}
                                                                                sx={{wordBreak: "break-all"}}>{selectedDocument.document?.name}</Typography>
                                                                            <Box className={"vertically-center-aligned"}
                                                                                 sx={{pr: 1}}>
                                                                                <CloseIcon sx={{cursor: 'pointer'}}
                                                                                           onClick={() => removeDocument(docIndex)}/>
                                                                            </Box>
                                                                        </Box>
                                                                        {selectedDocument.comment == "" ? (
                                                                            <Button variant={"text"}
                                                                                    onClick={() => handleAddComment(docIndex)}>
                                                                                Add Comment
                                                                            </Button>
                                                                        ) : (
                                                                            <Box className={"vertically-center-aligned"}
                                                                                 sx={{px: 2, width: '30%'}}>
                                                                                <ReadMore
                                                                                    text={selectedDocument.comment}
                                                                                    maxChars={20}/>
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                )
                                                            })}
                                                        </Box>
                                                    )}
                                                    <Box sx={{py: 2}}>
                                                        <Button component="label"
                                                                sx={{borderColor: '#6C63FF', color: '#6C63FF'}}
                                                                variant={"outlined"} endIcon={<AddIcon/>}>
                                                            {documents.length > 0 ? "Add More Files" : "Add Files"}
                                                            <input name="documents" onChange={handleDocumentUpload}
                                                                   hidden
                                                                   multiple type="file"/>
                                                        </Button>
                                                        <Button sx={{borderColor: '#6C63FF', color: '#6C63FF', ml: 2}}
                                                                onClick={handleAddLink} variant={"outlined"}
                                                                endIcon={<AddIcon/>}>
                                                            Add Link
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {event.questions.length > 0 && (
                                            <Box sx={{width: '60%'}}>
                                                <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                                    Please Answer This
                                                </Typography>
                                                <Typography sx={{py: 1, fontSize: '12px'}}>
                                                    By responding to all the questions your partner will have a better
                                                    understanding of your services. Mandatory questions are marked with
                                                    *.
                                                </Typography>
                                                {event.questions.map((clientQuestion: any, questionIndex: number) => {
                                                        let answers: any[] = submittedAnswers.hasOwnProperty(clientQuestion.id) ? submittedAnswers[clientQuestion.id] : [];
                                                        return (
                                                            <Box key={"question-" + questionIndex}>
                                                                <Typography sx={{py: 2}}>
                                                                    {questionIndex + 1}. {clientQuestion.question}
                                                                    {clientQuestion.mandatory && (
                                                                        <sup>*</sup>
                                                                    )}
                                                                </Typography>
                                                                <Box sx={{width: '60%', px: 2}}>
                                                                    {clientQuestion.questionType == "radio" ? (
                                                                        <FormControl>
                                                                            <RadioGroup
                                                                                row
                                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                name={clientQuestion.id}
                                                                                defaultValue={answers.length > 0 ? answers[0] : ""}
                                                                            >
                                                                                {
                                                                                    clientQuestion.answers.map((option: any, index: number) => (
                                                                                        <FormControlLabel
                                                                                            key={`radio-button-form-${index}-${clientQuestion.id}`}
                                                                                            value={option}
                                                                                            control={<Radio/>}
                                                                                            label={option}/>
                                                                                    ))
                                                                                }
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    ) : (
                                                                        clientQuestion.questionType == "checkbox" ? (
                                                                            clientQuestion.answers.map((option: any, answerIndex: number) => {
                                                                                return (
                                                                                    <FormControlLabel
                                                                                        key={`checkbox-form-${clientQuestion.id}-` + answerIndex}
                                                                                        label={option}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={answers.length > 0 && answers.some((obj: any) => {
                                                                                                    return obj === option
                                                                                                })}
                                                                                                onChange={(e: any) => onChangeHandlerMultiple(e, option)}

                                                                                                name={clientQuestion.id}
                                                                                                value={option}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <TextField
                                                                                fullWidth
                                                                                size={"small"}
                                                                                variant={"outlined"}
                                                                                className={"inputBox"}
                                                                                name={clientQuestion.id}
                                                                                required={clientQuestion.mandatory}
                                                                                defaultValue={answers.length > 0 ? answers[0] : ""}
                                                                                InputProps={{
                                                                                    style: {
                                                                                        borderRadius: "5px",
                                                                                        borderColor: 'primary.light',
                                                                                        fontSize: "0.8rem",
                                                                                    },
                                                                                }}
                                                                            />
                                                                        )
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                )}
                                            </Box>
                                        )}
                                    </>
                                )}
                                <Box sx={{pt: 4, pb: 2}}>
                                    <Box>
                                        <Box className={"space-between"}>
                                            <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                                Package Item Details
                                            </Typography>
                                            {!isEventExpired && (vendorEventDetail.status == "PARTICIPATED" || vendorEventDetail.status == "SUBMITTED") && (
                                                <Box>
                                                    <Button variant={"outlined"} sx={{mr: 2}} onClick={downloadQuotes}>Download
                                                        Quotes Sheet</Button>
                                                    <Button variant={"contained"} component="label">
                                                        Upload Quotes
                                                        <input name="quotes" onChange={handleQuoteUpload} hidden
                                                               type="file" accept={".csv"}/>
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                        {quoteUploadedFile != null && (
                                            <Box className={"right-align-content"}>
                                                <Box sx={{width: '25%'}}>
                                                    <Box className={"space-between"}
                                                         sx={{border: 1, p: 1, mt: 1}}>
                                                        <Box className={"vertically-center-aligned"}
                                                             sx={{pr: 1}}>
                                                            <TextSnippetIcon/>
                                                        </Box>
                                                        <Typography
                                                            className={"vertically-center-aligned"}
                                                            sx={{wordBreak: "break-all"}}>{quoteUploadedFile.name}</Typography>
                                                        <Box className={"vertically-center-aligned"}
                                                             sx={{pr: 1}}>
                                                            <CloseIcon sx={{cursor: 'pointer'}}
                                                                       onClick={removeQuoteUploadedFile}/>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                    {event?.eventPackages.map(
                                        (eventPackage: any, index: number) => {
                                            return (
                                                <Box key={"event-package-" + index}>
                                                    <Typography variant={"h2"} sx={{pb: 2}}>
                                                        {event?.eventPackage?.name}
                                                    </Typography>
                                                    <Grid
                                                        container
                                                        sx={{
                                                            backgroundColor: "primary.light",
                                                            borderTop: 1,
                                                            borderColor: "divider",
                                                        }}
                                                    >
                                                        <Grid item xs={1.2}>
                                                            <Typography
                                                                sx={{
                                                                    py: 2,
                                                                    px: 1,
                                                                    borderLeft: 1,
                                                                    borderColor: "divider",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                Item Category
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1.4}>
                                                            <Typography sx={{py: 2, px: 1, fontWeight: 700}}>
                                                                Selected Item
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1.5}>
                                                            <Typography sx={{py: 2, px: 1, fontWeight: 700}}>
                                                                Code
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}
                                                              sx={{
                                                                  px: 1,
                                                                  display: 'flex',
                                                                  alignItems: 'center',
                                                                  justifyContent: 'space-between'
                                                              }}>
                                                            <Typography variant="body1">Description/Specs</Typography>
                                                            <Button variant={"text"} onClick={handleToggleDescription}
                                                                    sx={{fontSize: "0.65rem", p: 0, m: 0}}>
                                                                {showDescription ? "Show Specs" : "Show Description"}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={0.8}>
                                                            <Typography sx={{py: 2, px: 1, fontWeight: 700}}>
                                                                UOM
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={0.8}>
                                                            <Typography sx={{py: 2, px: 1, fontWeight: 700}}>
                                                                Qty
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <Typography sx={{py: 2, px: 1, fontWeight: 700}}>
                                                                GSV
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1.4}>
                                                            <Typography sx={{py: 2, px: 1, fontWeight: 700}}>
                                                                Total
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1.7}>
                                                            <Typography
                                                                sx={{
                                                                    py: 2,
                                                                    px: 1,
                                                                    borderRight: 1,
                                                                    borderColor: "divider",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                Your Bid (Per Unit)
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {eventPackage.packageItems.map(
                                                        (packageItem: any, index: number) => {
                                                            let targetPrice = parseFloat(packageItem.itemDetail.targetPrice) + parseFloat(packageItem.itemDetail.domainCogsCharges.total);
                                                            let price: number = 0;
                                                            if ((vendorEventDetail.status == "PARTICIPATED" || vendorEventDetail.status == "SUBMITTED")) {
                                                                const eventVendorDetails = vendorEventDetail?.vendorRevisions[0]?.eventVendorDetails;
                                                                eventVendorDetails.map((eventVendorDetail: any) => {
                                                                    if (eventVendorDetail.packageItemId == packageItem.id) {
                                                                        price = eventVendorDetail.price;
                                                                    }
                                                                })
                                                            }

                                                            if(vendorUploadedRates.length > 0) {
                                                                vendorUploadedRates.map((vendorRate: any) => {
                                                                    if(vendorRate.itemId == packageItem.id) {
                                                                        price = vendorRate.price;
                                                                    }
                                                                });
                                                            }

                                                            return (
                                                                <Grid
                                                                    key={"vendor-event-details-" + index}
                                                                    container
                                                                    sx={{borderBottom: 1, borderColor: "divider"}}
                                                                >
                                                                    <Grid item xs={1.2}>
                                                                        <Typography sx={{
                                                                            py: 2,
                                                                            px: 1,
                                                                            borderRight: 1,
                                                                            borderLeft: 1,
                                                                            borderColor: "divider",
                                                                            height: "100%",
                                                                        }}>
                                                                            {packageItem?.itemDetail?.itemCategory?.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1.4}>
                                                                        <Box sx={styles.rightBorder}>
                                                                            <Box
                                                                                className={"space-between vertically-center-aligned"}>
                                                                                {packageItem?.itemDetail?.sku?.primaryImage != null && (
                                                                                    <img className={"sku-list-image"}
                                                                                         src={packageItem?.itemDetail?.sku?.primaryImage}
                                                                                         alt={packageItem?.itemDetail?.sku?.name}/>
                                                                                )}
                                                                                <Typography>
                                                                                    <Link
                                                                                        to={`/vendor/catalogue/skus/details/${packageItem.itemDetail.sku.id}`}
                                                                                    >
                                                                                        {packageItem?.itemDetail.sku.name}
                                                                                    </Link>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={1.5}>
                                                                        <Box sx={styles.rightBorder}>
                                                                            {packageItem?.itemDetail.sku.code}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Box sx={styles.rightBorder}>
                                                                            {showDescription ? (
                                                                                <ReadMore
                                                                                    text={packageItem?.itemDetail?.sku.description}
                                                                                    maxChars={200}
                                                                                    className={"sku-desc"}
                                                                                />
                                                                            ) : (
                                                                                <Box>
                                                                                    {packageItem?.itemDetail?.sku.options && packageItem?.itemDetail?.sku.options.length > 0 ? (
                                                                                        packageItem?.itemDetail?.sku.options.map((option: any, idx: number) => (
                                                                                            <Typography key={idx}
                                                                                                        variant={"body2"}
                                                                                                        sx={{
                                                                                                            fontSize: "0.8rem",
                                                                                                            padding: '0.4rem'
                                                                                                        }}>
                                                                                                <strong>{option.name}:</strong> {option.value}
                                                                                            </Typography>
                                                                                        ))
                                                                                    ) : (
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize: "0.8rem",
                                                                                                padding: '0.4rem'
                                                                                            }}>
                                                                                            NA
                                                                                        </Typography>
                                                                                    )}
                                                                                </Box>
                                                                            )}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={0.8}>
                                                                        <Typography sx={styles.rightBorder}>
                                                                            {packageItem?.itemDetail?.sku?.uom}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={0.8}>
                                                                        <Typography sx={styles.rightBorder}>
                                                                            {packageItem?.totalQuantity}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1.2}>
                                                                        <Typography sx={styles.rightBorder}>
                                                                            {packageItem.showRate && (
                                                                                getPriceInFormatted(targetPrice, event.project.country)
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1.4}>
                                                                        <Typography sx={styles.rightBorder}>
                                                                            {packageItem.showRate && (
                                                                                getPriceInFormatted(packageItem?.totalQuantity * targetPrice, event.project.country)
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1.7}>
                                                                        <Box sx={styles.rightBorder}>
                                                                            {(vendorEventDetail.status == "PARTICIPATED" || vendorEventDetail.status == "SUBMITTED") && (
                                                                                <TextField
                                                                                    fullWidth
                                                                                    size={"small"}
                                                                                    variant={"outlined"}
                                                                                    className={"inputBox"}
                                                                                    type={"number"}
                                                                                    id={packageItem?.id}
                                                                                    name={packageItem?.id}
                                                                                    placeholder={"Price"}
                                                                                    defaultValue={price > 0 ? price : ""}
                                                                                    onWheel={(e: any) => e.target.blur()}
                                                                                    onBlur={(e: any) => handlePriceChange(e, packageItem)}
                                                                                    InputProps={{
                                                                                        style: {
                                                                                            borderRadius: "5px",
                                                                                            borderColor: "primary.light",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }
                                                    )}
                                                </Box>
                                            );
                                        }
                                    )}
                                </Box>
                                <Box sx={{backgroundColor: "primary.light", p: 2}}>
                                    <Box className={"right-align-content"}>
                                        <Typography component={"span"}>
                                            <strong>Total</strong>: {getPriceInFormatted(eventTotal, event.project.country)}
                                        </Typography>
                                    </Box>
                                </Box>
                                {!isEventExpired && (vendorEventDetail.status == "PARTICIPATED" || vendorEventDetail.status == "SUBMITTED") && (
                                    <Box sx={{py: 2}} className={"right-align-content"}>
                                        <Button variant={"contained"} type={"submit"}>
                                            Submit Bid
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

            {openRejectConfirmation && (
                <RejectParticipation openPopup={openRejectConfirmation}
                                     handlePopupCallback={handleRejectPopupCallback}/>
            )}

            {openAddLinkPopup && (
                <AddLink openPopup={openAddLinkPopup} handleAddLinkPopupCallback={handleAddLinkPopupCallback}/>
            )}
            {openAddCommentPopup && (
                <AddComment openPopup={openAddCommentPopup}
                            handleAddCommentPopupCallback={handleAddCommentPopupCallback}/>
            )}
            {openAddCommentToExistingPopup && (
                <AddComment openPopup={openAddCommentToExistingPopup}
                            handleAddCommentPopupCallback={handleAddCommentToExistingPopupCallback}/>
            )}

            {openTermPopup && (
                <EventTermsAndCondition termFile={termFile} handleAccept={handleAccept}/>
            )}
        </Box>
    );
};

export default VendorEventDetails;
