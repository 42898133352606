import {Box, Button, Grid, Typography} from "@mui/material";
import {getFormattedDateTime} from "../../../utils/Common";
import React, {useEffect, useState} from "react";
import EventDocumentView from "./EventDocumentView";
import EventQuestionnaireView from "./EventQuestionnaireView";

const EventDetailsRow = (props: any) => {
    const {eventDetails, color} = props;
    const [openDocumentView, setOpenDocumentView] = useState(false);
    const [openQuestionView, setOpenQuestionView] = useState(false);
    const [totalDocuments, setTotalDocuments] = useState(0);

    let statusLabel = eventDetails.status;
    if(statusLabel == "BID_CLOSED") {
        statusLabel = "Bid Closed";
    }

    const handleDocumentView = () => {
        setOpenDocumentView(true);
    }

    const handleQuestionView = () => {
        setOpenQuestionView(true);
    }

    const handlePopupClose = (flag: boolean) => {
        if (flag) {
            setOpenQuestionView(false);
        } else {
            setOpenDocumentView(false);
        }
    }

    useEffect(() => {
        if (Object.keys(eventDetails).length > 0) {
            let count = Array.isArray(eventDetails?.documents) ? eventDetails.documents.length : 0;
            setTotalDocuments(count);
        }
    }, [eventDetails]);
    return (
        <Box>
            <Typography variant={"h4"} sx={{pb: 1}}>Event Details</Typography>
            <Grid container
                  sx={{
                      p: 2,
                      border: 1,
                      borderColor: "divider",
                      backgroundColor: "info.contrastText",
                      fontWeight: '700'
                  }}>
                <Grid item xs>Event Name</Grid>
                <Grid item xs={1.5}>Start Date</Grid>
                <Grid item xs={1.5}>End Date</Grid>
                <Grid item xs>Sealed Bid</Grid>
                <Grid item xs>Documents Added</Grid>
                <Grid item xs>Questions Added</Grid>
                <Grid item xs>Total Vendors</Grid>
                <Grid item xs>Response Received</Grid>
                <Grid item xs>Event Status</Grid>
            </Grid>
            <Grid sx={{p: 2, border: 1, borderTop: 0, borderColor: "divider"}} container alignItems={"stretch"}>
                <Grid item xs>{eventDetails?.name}</Grid>
                <Grid item xs={1.5}>{getFormattedDateTime(eventDetails?.startDate)}</Grid>
                <Grid item xs={1.5}>{getFormattedDateTime(eventDetails?.endDate)}</Grid>
                <Grid item xs>{eventDetails?.sealedBid ? "Yes" : "No"}</Grid>
                <Grid item xs>
                    {totalDocuments > 0 ? (
                        <Box>
                            <Typography component={"span"}>
                                {totalDocuments}
                            </Typography>
                            <Button sx={{p: 0, m: 0, minHeight: '10px'}} onClick={handleDocumentView}
                                    variant={"text"}>View</Button>
                        </Box>
                    ) : 0}
                </Grid>
                <Grid item xs>
                    {eventDetails.hasOwnProperty('questions') && Array.isArray(eventDetails?.questions) && eventDetails?.questions.length > 0 ? (
                        <Box>
                            <Typography component={"span"}>
                                {eventDetails?.questions.length}
                            </Typography>
                            <Button sx={{p: 0, m: 0, minHeight: '10px'}} onClick={handleQuestionView}
                                    variant={"text"}>View</Button>
                        </Box>
                    ) : 0}</Grid>
                <Grid item xs>{eventDetails?.totalVendors}</Grid>
                <Grid item xs>{eventDetails?.totalResponse}</Grid>
                <Grid item xs>
                    <Typography sx={{
                        textTransform: 'capitalize',
                        border: 1,
                        borderColor: `rgba(${color}, 1)`,
                        borderRadius: 1,
                        p: 0.6,
                        backgroundColor: `rgba(${color}, 0.5)`,
                        minWidth: '100px',
                        textAlign: 'left',
                        width: '50%'
                    }}>
                        {statusLabel.toLowerCase()}
                    </Typography>
                </Grid>
            </Grid>
            {openDocumentView && (
                <EventDocumentView openPopup={openDocumentView} totalDocuments={totalDocuments}
                                   documents={eventDetails.documents}
                                   handlePopupCallback={() => handlePopupClose(false)}/>
            )}
            {openQuestionView && (
                <EventQuestionnaireView openPopup={openQuestionView} questions={eventDetails.questions}
                                        handlePopupCallback={() => handlePopupClose(true)}/>
            )}
        </Box>
    )
}

export default EventDetailsRow;