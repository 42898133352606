import {Box, Button, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const RemoveFilters = (props: any) => {
    const removeFilter = (name: string) => {
        const prevAppliedFilters: any = {...props.appliedFilters};
        delete prevAppliedFilters[name];
        props.handleFilterChange(prevAppliedFilters);
    };
    return (
        <Box>
            {Object.keys(props.appliedFilters).length > 0 && (
                <Box className={"filters"} sx={{pb: 2}}>
                    {Object.entries(props.filters).map(
                        ([name, filter]: any, filterIndex: number) => {
                            const label = name.replace("_", " ");
                            let value = "";
                            if (props.appliedFilters.hasOwnProperty(name)) {
                                value = props.appliedFilters[name];
                                if (filter.type === "autocomplete" || filter.type == "select") {
                                    filter.options.map((option: any) => {
                                        if (option.id === value) {
                                            value = option.label;
                                            return;
                                        }
                                        return (
                                            <Button
                                                variant={"outlined"}
                                                key={"applied-filter-" + filterIndex}
                                                sx={{p: 0.5, borderRadius: 5, mr: 1, mb: 1}}
                                                endIcon={
                                                    <CloseIcon
                                                        sx={{pr: 0.5}}
                                                        onClick={() => removeFilter(name)}
                                                    />
                                                }
                                            >
                                                <Typography
                                                    sx={{
                                                        px: 0.5,
                                                        fontSize: "11px",
                                                        textTransform: "capitalize",
                                                    }}
                                                >
                                                    {" "}
                                                    {label + " : " + value}{" "}
                                                </Typography>{" "}
                                            </Button>
                                        );
                                    });
                                }
                                if (Array.isArray(value)) {
                                    value = value.toString().split(",").join(" to ");
                                }
                                return (
                                    <Button
                                        variant={"outlined"}
                                        key={"applied-filter-" + filterIndex}
                                        sx={{p: 0.5, borderRadius: 5, mr: 1, mb: 1}}
                                        endIcon={
                                            <CloseIcon
                                                sx={{pr: 0.5}}
                                                onClick={() => removeFilter(name)}
                                            />
                                        }
                                    >
                                        {filter.type === "checkbox" ? (
                                            <Typography
                                                sx={{
                                                    px: 0.5,
                                                    fontSize: "11px",
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {label}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                sx={{
                                                    px: 0.5,
                                                    fontSize: "11px",
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {filter.type === "checkbox" ? label : `${label} : ${value}`}
                                            </Typography>
                                        )}{" "}
                                    </Button>
                                );
                            }
                        }
                    )}
                </Box>
            )}
        </Box>
    );
};
export default RemoveFilters;
