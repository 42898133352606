import {SyntheticEvent, useEffect, useState} from "react";
import SubCategoryTab from "../../tabs/SubCategoryTab";
import {Box} from "@mui/material";
import {loadItemCategories} from "../../../modules/project/features/actions";
import {useAppDispatch} from "../../../app/hooks";
import SearchFilter from "../../filter/SearchFilter";
import ItemCategoryCatalogueTab from "./ItemCategoryCatalogueTab";
import Loader from "../../Loader";
import {api} from "../../../utils/Axios";
import BasicCategoryTab from "../../tabs/BasicCategoryTab";

const RcCatalogTab = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [categoryTabValue, setCategoryTabValue] = useState("");
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [appliedFilters, setAppliedFilters] = useState({});

    const handleCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
    };

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
    }

    const handleSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const loadFilteredCategories = () => {
        if(categoryTabValue != "") {
            const params = new URLSearchParams({
                searchQuery: searchQuery
            });

            if (Object.keys(appliedFilters).length > 0) {
                Object.entries(appliedFilters).map(([name, value]: any) => {
                    params.append(name, value);
                });
            }
            setIsLoading(true);
            api.get(`/catalogue/material-master/filter-categories-with-subcategories/${categoryTabValue}`, {params})
                .then((response) => response.data)
                .then((response: any) => {
                    categories.forEach((category: any) => {
                        if(category.id == categoryTabValue) {
                            category.subCategories = response;
                        }
                    });
                })
                .catch((ex) => {
                    console.log(ex);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const loadCategories = () => {
        setIsLoading(true);
        api.get("/catalogue/material-master/categories-with-subcategories").then((response) => {
            return response.data;
        }).then((response: any) => {
            setCategories(response);
            setCategoryTabValue(response[0].id);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadFilteredCategories();
    }, [searchQuery, appliedFilters]);

    useEffect(() => {
        if (categoryTabValue !== "") {
            categories.forEach((category: any) => {
                if (category.id === categoryTabValue) {
                    setSelectedCategory(category);
                    let assignedSubCategory = false;
                    category.subCategories.forEach((subCategory: any) => {
                        if(subCategory?.totalSkus > 0 && !assignedSubCategory) {
                            if (category.code == "FF") {
                                if (subCategory.code !== "CA") {
                                    setSubCategoryTabValue(subCategory.id);
                                    assignedSubCategory = true;
                                }
                            } else {
                                setSubCategoryTabValue(subCategory.id);
                                assignedSubCategory = true;
                            }
                        }
                    });
                }
            });
        }
    }, [categoryTabValue, categories]);

    useEffect(() => {
        loadCategories();
    }, []);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Box sx={{position: 'relative'}}>
            {categoryTabValue !== "" && (
                <BasicCategoryTab
                    showCount={true}
                    hideZeroSkus={false}
                    categories={categories}
                    categoryTabValue={categoryTabValue}
                    handleCategoryTabChange={handleCategoryTabChange}
                />
            )}
            {selectedCategory.hasOwnProperty("id") && (
                <SubCategoryTab
                    showCount={true}
                    hideZeroSkus={true}
                    subCategoryTabValue={subCategoryTabValue}
                    subCategories={selectedCategory?.subCategories}
                    categoryTabValue={categoryTabValue}
                    handleSubCategoryTabChange={handleSubCategoryTabChange}
                />
            )}
            <Box>
                <ItemCategoryCatalogueTab
                    subCategoryTabValue={subCategoryTabValue}
                    searchQuery={searchQuery}
                    appliedFilters={appliedFilters}
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                />
            </Box>
            <Box sx={{
                position: "absolute",
                right: 0,
                top: 0,
            }}>
                <Box sx={{pb: 2}}>
                    <SearchFilter
                        handleFilterChange={handleFilterChange}
                        handleSearchFilter={handleSearchFilter}
                        filters={filters}
                        appliedFilters={appliedFilters}
                        showFilters={true}
                        showSearch={true}
                        query={searchQuery}
                        showSearchInput={true}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default RcCatalogTab;


const filters = {
    'name': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku Name',
        label: 'Sku Name',
        name: 'name'
    },
    'code': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku code',
        label: 'Sku Code',
        name: 'code'
    },
    'uom': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By UOM',
        label: 'Sku UOM',
        name: 'uom'
    },
    'location': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Location',
        label: 'Location',
        name: 'location'
    },
    'RC_price': {
        element: 'radio',
        label: 'RC Price',
        name: 'RC_price',
        options: [
            {id: 'yes', label: 'Yes'},
            {id: 'no', label: 'No'},
        ],
    },
    'price': {
        element: 'slider',
        placeholder: 'Search Here',
        label: 'Price Range',
        name: 'price',
        info: '1 = 1000',
        range: [0, 1000000]
    },
};
