import {config} from "./RoomDataConfig";
import {Box, Tab, Tabs, Typography, Button} from "@mui/material";
import {a11yProps, CustomTabPanel} from "../../utils/TabsUtils";
import {SyntheticEvent, useEffect, useState, useRef} from "react";
import RoomDataItems, { ContinueRef } from "./RoomDataItems";
import {api} from "../../utils/Axios";
import ECatalogDialog from "../../components/ecatalog/ECatalogDialog";
import CheckIcon from '@mui/icons-material/Check';

const HeadCountRoomData = (props: any) => {
    const roomCountConfig = config;
    const [itemCategoryTabValue, setItemCategoryTabValue] = useState(roomCountConfig[0].id);
    const [currentRoomConfigIndex, setCurrentRoomConfigIndex] = useState(0);
    const [items, setItems] = useState([]);
    const roomItemCategegories:any[] = props.roomItemCategegories;
    const [open, setOpen] = useState(false);
    const [nextTabValue, setNextTabValue] = useState("");
    const [fieldUpdated, setFieldUpdated] = useState(false);
    const ref = useRef<ContinueRef>(null);

    const [remainingIndexes, setRemainingIndexes] = useState<number[]>(roomCountConfig.map((rData: any, ix: number) => {
                                                                                                return ix;
                                                                                            }));

    const handleItemCategoryTabChange = (
        event: SyntheticEvent,
        newValue: string
    ) => {
        setItemCategoryTabValue(newValue);
    };

    const goToNextItemCategory = (curIndex: any) => {
        const filtered = remainingIndexes.filter(a =>
                  a !== curIndex
                );
        
        if(filtered.length) {
            let nextIndex = filtered[0];
            setCurrentRoomConfigIndex(nextIndex);
            setItemCategoryTabValue(roomCountConfig[nextIndex].id);
        }
        //Added to disable next tabs till estimation
        props.handleDisableNextTabs('head-count');
        setRemainingIndexes(filtered);

        //console.log(filtered);
        //console.log(remainingIndexes);

        roomItemCategegories.push(roomCountConfig[curIndex].id);

    }

    const handleItemCatTabChange = (itemCategoryIndex: any) => {
        //setCurrentRoomConfigIndex(itemCategoryIndex);
        //setItemCategoryTabValue(roomCountConfig[itemCategoryIndex].id);

        if(fieldUpdated) {
            setNextTabValue(itemCategoryIndex.toString());
            setOpen(true);
        } else {
            setCurrentRoomConfigIndex(itemCategoryIndex);
            setItemCategoryTabValue(roomCountConfig[itemCategoryIndex].id);
        }
    }
    const updateFieldChange = (value:boolean) => {
        setFieldUpdated(value);
    }

    const handleClose = (value:any, tabValue:any) => {
        setOpen(false);
        if(value === "proceed") {
            setFieldUpdated(false);
            if(tabValue === "proceedToNextTab") {
                goToNextTab();
            } else {
                let itemCategoryIndex = parseInt(tabValue);
                setCurrentRoomConfigIndex(itemCategoryIndex);
                setItemCategoryTabValue(roomCountConfig[itemCategoryIndex].id);
            }
        }
    };

    const goToNextTab = () => {
        if(remainingIndexes.length > 0) {
            alert("Please fill all the data before proceeding to next view");
            return;
        }
        api.post(`/procurement/project/sync-head-count/${props.project.id}?revisionId=${props?.revision?.id}`).then((response) => {
            if (response.status === 200)
                return response.data;
            return {};
        }).then(res => {
            props.proceedTabChange('e-catalog');
            //props.proceedTabChange('estimation');
        }).catch(ex => {
            console.log(ex);
        });
    }
    
    const checkSaveAndCountinue = (event:SyntheticEvent) => {
        //console.log("checkSaveAndCountinue");
        ref.current?.saveHeadCount(event);
    }

    const handleContinue = () => {
        if(fieldUpdated && false) {
            console.log("Field state change found ..");
            setNextTabValue("proceedToNextTab");
            setOpen(true);
        } else {
            console.log("Will continbue. to E-Catalog tab");
            goToNextTab();
        }
    }

    useEffect(() => {
        let roomIndexes:number[] = [];
        let nextRoomId = "";
        //console.log(roomItemCategegories);
        roomCountConfig.map((roomData: any, index: number) => {
            //console.log(roomData.id);
            if (!props.roomItemCategegories.includes(roomData.id)) {
                roomIndexes.push(index);
                if(nextRoomId === "") {
                    nextRoomId = roomData.id;
                    setCurrentRoomConfigIndex(index);
                }
            }
        });
        //console.log("Final remaining indexes on load  ==");
        //console.log(roomIndexes);
        setRemainingIndexes(roomIndexes);
        if(nextRoomId !== "") {
            setItemCategoryTabValue(nextRoomId);
        }

    }, [props.roomItemCategegories]);

    useEffect(() => {
        console.log("Project Data:: ", props.project)
        let builtUpArea = props.project?.projectDetails?.builtUpArea;
        let headCount = props.project?.projectDetails?.totalHeadCount;
        let headCountFromClient = props.project?.projectDetails?.headCountFromClient;
        let carpetArea = props.project?.carpetArea;
        if(headCountFromClient == "NO" || headCount == 0) {
            headCount = carpetArea/100;
        }

        console.log("builtUpArea --", builtUpArea);

        setItems([]);
        let collabTotal = 0;
        let collabIndex = 0;
        let collabSkuIndex = 0;

        let avScreenTotal = 0;
        let avScreenIndex = 0;
        let avScreenSkuIndex = 0;

        let totalHotdeskSeats = headCount - (Math.floor(headCount/50));;
        let hotDeskIndex = 0;
        let hotDeskSkuIndex = 0;

        roomCountConfig.map((roomData: any, index: number) => {
            if(itemCategoryTabValue == 'main_rooms' && roomData.id == "open_collaboration_space") {
                roomData.items.map((item: any, index: number) => {
                    item.skus.map((sku: any, skuIndex: number) => {
                        //open_collaboration_space total calculation
                        if(sku.id == "open_collaboration_space_1" || sku.id == "open_collaboration_space_2" 
                            || sku.id == "open_collaboration_space_4" || sku.id == "open_collaboration_space_5") {
                            collabTotal = collabTotal + parseInt(sku.roomCount);
                            console.log("open_collaboration_space_count", collabTotal);
                        }
                    });
                });
            }

            if(itemCategoryTabValue == 'general_room_details' 
                && (roomData.id == "board_rooms" || roomData.id == "training_room_20_pax" 
                    || roomData.id == "meeting_rooms_in_laminate_finish")) {
                roomData.items.map((item: any, index: number) => {
                    item.skus.map((sku: any, skuIndex: number) => {
                        //Meetin rooms AV Screens total calculation
                        if(sku.id == "board_rooms_1" || sku.id == "board_rooms_2"
                            || sku.id == "board_rooms_3" || sku.id == "training_room_20_pax_0"
                                || sku.id == "training_room_20_pax_2" || sku.id == "training_room_others"
                                || sku.id == "meeting_rooms_in_laminate_finish_2" || sku.id == "meeting_rooms_in_laminate_finish_3"
                                || sku.id == "meeting_rooms_in_laminate_finish_4" || sku.id == "meeting_rooms_in_laminate_finish_5") {
                            avScreenTotal = avScreenTotal + parseInt(sku.roomCount);
                        }
                    });
                });
            }

            if((itemCategoryTabValue == 'workstations' || itemCategoryTabValue == 'meeting_rooms_in_laminate_finish' ||  itemCategoryTabValue == 'board_rooms' 
            || itemCategoryTabValue  == 'phone_booths_focus_room_built_in') && roomData.id == "cabins") {
                console.log("Inside Cabins");
                roomData.items.map((item: any, index: number) => {
                    item.skus.map((sku: any, skuIndex: number) => {
                        //open_collaboration_space total calculation
                        if(sku.id == "cabins_1") {
                            totalHotdeskSeats = headCount - Math.floor(headCount/50);
                            console.log("hotdesk seats count", totalHotdeskSeats);
                        }
                    });
                });
            }


            if (roomData.id === itemCategoryTabValue) {
                if(roomData.id == 'main_rooms' || roomData.id == 'meeting_rooms_in_laminate_finish' 
                    || roomData.id == 'training_room_20_pax' || roomData.id == 'cabins'
                        || roomData.id == 'meeting_rooms_in_laminate_finish' || roomData.id == 'board_rooms'
                        || roomData.id == 'phone_booths_focus_room_built_in' || roomData.id == 'pantry_points'
                            || roomData.id == 'open_collaboration_space' || roomData.id == 'general_room_details'
                            || roomData.id == 'workstations' || roomData.id == 'monitor_arms') {
                    let totalTrainingRoom = 0;
                    let othersRoomCount = 0;
                    let othersRoomQty = 0;
                    let allQtyIndex = 0;

                    roomData.items.map((item: any, index: number) => {
                        item.skus.map((sku: any, skuIndex: number) => {
                            if(sku.id == 'meeting_rooms_in_laminate_finish_4') {//8 pax Rm
                                roomData.items[index].skus[skuIndex].roomCount  =  Math.floor(headCount/75);
                            }
                            if(sku.id == 'main_rooms_7') {//BMS Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 100000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_8') {//Security Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 200000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_9') {//Mail Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 60000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_13') {//Transport Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 200000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_14') {//Wellness Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 200000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_22') {//Repro
                                let reproCount = 0;
                                if(builtUpArea > 1 &&  builtUpArea <= 25000) {
                                    reproCount = 1;
                                } else if(builtUpArea > 25000 &&  builtUpArea <= 65000) {
                                    reproCount = 2;
                                } else if(builtUpArea > 65000 &&  builtUpArea <= 80000) {
                                    reproCount = 3;
                                } else if(builtUpArea > 80000 &&  builtUpArea <= 125000) {
                                    reproCount = 4;
                                }
                                roomData.items[index].skus[skuIndex].roomCount = reproCount;
                            }

                            if(sku.id == "cabins_1") {
                                roomData.items[index].skus[skuIndex].roomCount = Math.floor(headCount/50);
                            }
                            //4 pax Rm
                            if(sku.id == "meeting_rooms_in_laminate_finish_2") {
                                console.log("calculate 4Px headCount = "+totalHotdeskSeats);
                                roomData.items[index].skus[skuIndex].roomCount = Math.floor(totalHotdeskSeats/50);
                            }
                            //6 pax Rm
                            if(sku.id == "meeting_rooms_in_laminate_finish_3") {
                                roomData.items[index].skus[skuIndex].roomCount = Math.floor(totalHotdeskSeats/75);
                            }
                            //8 pax Rm
                            if(sku.id == "meeting_rooms_in_laminate_finish_4") {
                                roomData.items[index].skus[skuIndex].roomCount = Math.floor(totalHotdeskSeats/75);
                            }
                            //10 pax Rm
                            if(sku.id == "meeting_rooms_in_laminate_finish_5") {
                                roomData.items[index].skus[skuIndex].roomCount = Math.floor(totalHotdeskSeats/100);
                            }
                            //12 pax Rm
                            if(sku.id == "board_rooms_1") {
                                roomData.items[index].skus[skuIndex].roomCount = Math.floor(totalHotdeskSeats/110);
                            }
                            //1 pax Rm
                            if(sku.id == "phone_booths_focus_room_built_in_1") {
                                roomData.items[index].skus[skuIndex].roomCount = Math.floor(totalHotdeskSeats/50);
                            }
                            //Close Pantry (Wet Pantry)
                            if(sku.id == "main_rooms_28") {
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 30000) ? 1 : 0;
                            }
                            //Collabs index
                            if(sku.id == "main_rooms_15") {
                                collabIndex = index;
                                collabSkuIndex = skuIndex;
                            }
                            //AV Screen index
                            if(sku.id == "meeting_room_av_screens_1") {
                                avScreenIndex = index;
                                avScreenSkuIndex = skuIndex;
                            }
                            //HUB / IDF Room
                            if(sku.id == "main_rooms_16") {
                                let hub_idf_count = 0;
                                if(builtUpArea > 1 &&  builtUpArea <= 20000) {
                                    hub_idf_count = 1;
                                } else if(builtUpArea > 20000 &&  builtUpArea <= 60000) {
                                    hub_idf_count = 2;
                                } else if(builtUpArea > 60000 &&  builtUpArea <= 100000) {
                                    hub_idf_count = 3;
                                } 
                                roomData.items[index].skus[skuIndex].roomCount = hub_idf_count;
                            }
                            //Server/ MDF Room
                            if(sku.id == "main_rooms_17") {
                                let server_mdf_count = 0;
                                if(builtUpArea > 1 &&  builtUpArea <= 25000) {
                                    server_mdf_count = 0;
                                } else if(builtUpArea > 25000) {
                                    server_mdf_count = 1;
                                } 
                                roomData.items[index].skus[skuIndex].roomCount = server_mdf_count;
                            }
                            //UPS Room
                            if(sku.id == "main_rooms_19") {
                                let ups_room_count = 0;
                                if(builtUpArea > 1) {
                                    ups_room_count = 1;
                                }
                                roomData.items[index].skus[skuIndex].roomCount = ups_room_count;
                            }
                            //Battery Room
                            if(sku.id == "main_rooms_21") {
                                let battery_room_count = 0;
                                if(builtUpArea > 1) {
                                    battery_room_count = 1;
                                }
                                roomData.items[index].skus[skuIndex].roomCount = battery_room_count;
                            }

                            //Workstations + Hotdesk Seat
                            if(sku.id == "workstations_1") {
                                hotDeskIndex = index;
                                hotDeskSkuIndex = skuIndex;
                            }
                            //Workstation (Laptop)
                            if(sku.id == "workstations_2") {
                                let laptopCount =  0;
                                let workspaceOperationRation = props.elecParameters["workspaceOperationRation"];
                                if(workspaceOperationRation == '100 : 0 : 0')  {
                                    laptopCount = headCount;
                                } else if(workspaceOperationRation == '50 : 20 : 30' || workspaceOperationRation == '50 : 30 : 20' || workspaceOperationRation == '50 : 50 : 0') {
                                    laptopCount = (headCount * 50)/100;
                                } else if(workspaceOperationRation == '70 : 20 : 10') {
                                    laptopCount = (headCount * 70)/100;
                                } 
                                laptopCount = Math.ceil(laptopCount);
                                roomData.items[index].skus[skuIndex].quantity = laptopCount;
                            }
                            //Workstation LWorkstation (Laptop + Single Monitor) || Single Monitor Arms
                            if(sku.id == "workstation_laptop_single_monitor" || sku.id == "monitor_arms_1") {
                                let singleMonitorCount =  0;
                                let workspaceOperationRation = props.elecParameters["workspaceOperationRation"];
                                if(workspaceOperationRation == '0 : 100 : 0')  {
                                    singleMonitorCount = headCount;
                                } else if(workspaceOperationRation == '0 : 50 : 50' || workspaceOperationRation == '50 : 50 : 0') {
                                    singleMonitorCount = (headCount * 50)/100;
                                } else if(workspaceOperationRation == '0 : 70 : 20') {
                                    singleMonitorCount = (headCount * 70)/100;
                                } else if(workspaceOperationRation == '50 : 20 : 30' || workspaceOperationRation == '70 : 20 : 10') {
                                    singleMonitorCount = (headCount * 20)/100;
                                } else if(workspaceOperationRation == '50 : 30 : 20') {
                                    singleMonitorCount = (headCount * 30)/100;
                                }
                                singleMonitorCount = Math.ceil(singleMonitorCount);
                                roomData.items[index].skus[skuIndex].quantity = singleMonitorCount;
                            }
                            //Workstation (Laptop + Dual Monitor) || Dual Monitor Arms
                            if(sku.id == "workstations_4" || sku.id == "monitor_arms_2") {
                                let dualMonitorCount =  0;
                                let workspaceOperationRation = props.elecParameters["workspaceOperationRation"];
                                if(workspaceOperationRation == '0 : 0 : 100')  {
                                    dualMonitorCount = headCount;
                                } else if(workspaceOperationRation == '0 : 50 : 50' ) {
                                    dualMonitorCount = (headCount * 50)/100;
                                } else if(workspaceOperationRation == '0 : 70 : 30' || workspaceOperationRation == '50 : 20 : 30') {
                                    dualMonitorCount = (headCount * 30)/100;
                                } else if(workspaceOperationRation == '50 : 30 : 20') {
                                    dualMonitorCount = (headCount * 20)/100;
                                } else if(workspaceOperationRation == '70 : 20 : 10') {
                                    dualMonitorCount = (headCount * 10)/100;
                                }
                                dualMonitorCount = Math.ceil(dualMonitorCount);
                                roomData.items[index].skus[skuIndex].quantity = dualMonitorCount;
                            }
                            
                            if(sku.id == 'main_rooms_30') {//Toilets
                                let toiletCount = 0;
                                let toiletCountStr = props.ciParameters["total-number-of-toilet-blocks"];
                                if(toiletCountStr) {
                                    toiletCount = parseInt(toiletCountStr);
                                }

                                let toiletArea  = 0;
                                let toiletAreaStr = props.ciParameters["total-toilet-area-in-sqm"];
                                if(toiletAreaStr) {
                                    toiletArea = parseInt(toiletAreaStr);
                                }

                                roomData.items[index].skus[skuIndex].roomCount = toiletCount;
                                roomData.items[index].skus[skuIndex].closedAreaSqm = toiletArea;
                            }

                            //Training room  data calculations
                            if(sku.id == "training_room_20_pax_0") {
                                totalTrainingRoom =   totalTrainingRoom + parseInt(sku.roomCount) *  20;
                            }
                            if(sku.id == "training_room_20_pax_2") {
                                totalTrainingRoom =   totalTrainingRoom + parseInt(sku.roomCount) *  40;
                            }

                            if(sku.id  == "training_room_others") {
                                othersRoomCount = parseInt(sku.roomCount);
                            }

                            if(sku.id == "training_room_seats_others") {
                                othersRoomQty = parseInt(sku.quantity);
                            }
                            if(sku.id == "training_rooms_mpr_no_of_seats_1") {
                                allQtyIndex = skuIndex;
                            }
                        });
                        if(roomData.id == "training_room_20_pax")  {
                            totalTrainingRoom = totalTrainingRoom + (othersRoomCount * othersRoomQty);
                            roomData.items[index].skus[allQtyIndex].quantity = totalTrainingRoom;
                        }
                    })

                    //console.log("props.ciParameters", props.ciParameters);
                    //console.log("roomData.items =====");
                    //console.log(roomData.items);
                }

                if(roomData.id == "main_rooms") {
                    //console.log("collabTotal", collabTotal);
                    //console.log("collabSkuIndex", roomData.items[collabIndex].skus[collabSkuIndex]);
                    roomData.items[collabIndex].skus[collabSkuIndex].roomCount = collabTotal;
                }
                if(roomData.id == "general_room_details") {
                    //console.log("avScreenTotal", avScreenTotal);
                    //console.log("avScreenSkuIndex", roomData.items[avScreenIndex].skus[avScreenSkuIndex]);
                    roomData.items[avScreenIndex].skus[avScreenSkuIndex].quantity = avScreenTotal;
                }
                if(roomData.id == "workstations") {
                    //console.log("totalHotdeskSeats", totalHotdeskSeats);
                    //console.log("hostDeskSkuIndex", roomData.items[hotDeskIndex].skus[hotDeskSkuIndex]);
                    roomData.items[hotDeskIndex].skus[hotDeskSkuIndex].quantity = totalHotdeskSeats;
                }

                setItems(roomData.items);
            }
        });
    }, [itemCategoryTabValue]);

    return (
        <Box sx={{pt: 4}}>
            <Box sx={{backgroundColor: "#F9FAFF", fontWeight: 700, p: 2, position: 'relative'}}>
                <Typography>Room Data</Typography>
                {((remainingIndexes.length < 1))  && 
                <Button className={"head-count-next"} variant={"outlined"} sx={{mr: 2, position: 'absolute', top: '6px', right: '5px'}} 
                    onClick={(e) => checkSaveAndCountinue(e)}>
                    Submit & Continue
                </Button>}
            </Box>
            <ECatalogDialog
                            open={open}
                            onClose={handleClose}
                            confirmText={"You have unsaved changes. Are you sure you want to continue without saving?"}
                            nextTabValue={nextTabValue}
                          />

            <Box sx={{display: 'flex', pt: 4}}>
                <Box sx={{pr: 2, borderRight: 1, borderColor: 'divider', width: '35%'}}>
                    <Typography sx={{py: 3, px: 2, fontWeight: 700}}>
                        Item Category
                    </Typography>
                    <Tabs
                        orientation={'vertical'}
                        variant={'scrollable'}
                        value={itemCategoryTabValue}
                        onChange={handleItemCategoryTabChange}
                        aria-label="Item Category Tabs"
                        className="tab-sidebar"
                    >
                        {roomCountConfig && roomCountConfig.map((roomData: any, itemCategoryIndex: number) => {
                                return (
                                    <Box sx={{display:'flex', alignItems: 'center', position: 'relative', width: '100%', maxWidth: '360px'}}
                                        className={(itemCategoryTabValue === roomData.id) ? "tab-sidebar-outer tab-sidebar-outer-selected" : "tab-sidebar-outer"}>
                                    <Tab
                                        sx={{
                                            alignItems: 'flex-start',
                                            minHeight: '35px',
                                            borderRadius: 1,
                                            width: '100%'
                                        }}
                                        key={'item-category-tab-' + itemCategoryIndex}
                                        value={roomData.id}
                                        {...a11yProps(roomData.id, false)}
                                        label={
                                            <Typography className={(itemCategoryTabValue === roomData.id) ? "tab-sidebar-label tab-sidebar-label-selected" : "tab-sidebar-label"}>
                                                {roomData.name}
                                            </Typography>
                                        }
                                        disabled={roomData.disabled}
                                        wrapped
                                        className="tab-sidebar-item"
                                        onClick={() => handleItemCatTabChange(itemCategoryIndex)}
                                    />
                                    {roomItemCategegories.includes(roomData.id) && <CheckIcon sx={{position: "absolute", right:"11px", padding: "3px", marginTop: "2px", border: "1px solid #00ab41", borderRadius: "14px", height: "20px", width: "20px", background: "#00ab41", color: "#fff"}} />}
                                    {!roomItemCategegories.includes(roomData.id) && <CheckIcon sx={{position: "absolute", right:"11px", padding: "3px", marginTop: "2px", border: "1px solid #d5d5d5", borderRadius: "14px", height: "20px", width: "20px", background: "#d5d5d5", color: "#fff"}} />}
                                    </Box>
                                );
                            }
                        )}
                    </Tabs>
                </Box>
                {(itemCategoryTabValue !== "" && items.length > 0) &&
                    <CustomTabPanel
                        width={'65%'}
                        value={itemCategoryTabValue}
                        index={itemCategoryTabValue}
                    >
                        <Box sx={{p: 2}} id={"room-data-items"}>
                            <RoomDataItems
                                project={props.project}
                                revision={props.revision} 
                                items={items}
                                itemCategoryId={itemCategoryTabValue}
                                handleContinue={handleContinue}
                                goToNextItemCategory={goToNextItemCategory}
                                remainingIndexes={remainingIndexes}
                                currentIndex={currentRoomConfigIndex}
                                updateFieldChange={updateFieldChange} 
                                ref={ref}
                                ciParameters={props.ciParameters}                  
                            />
                        </Box>
                    </CustomTabPanel>
                }

            </Box>
        </Box>
    )
}
export default HeadCountRoomData;