import {getPriceInFormatted} from "../../../../utils/Common";

export const boqColumns = [
    {field: 'id', headerName: 'ID', headerClassName: 'data-grid-header', flex: 1},
    {
        field: 'itemCategoryName',
        headerName: "Item Category",
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 240,
        valueGetter: (params: any) => params.row?.itemCategory?.name
    },
    {
        field: 'selectedItem',
        headerName: 'Selected Item',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        renderCell: (params: any) => <a href={`/catalogue/skus/details/${params.row?.skuId}`}
                                        target={"_blank"}>{params.row?.sku?.name}</a>
    },
    {
        field: 'skuUom',
        headerName: "UOM",
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 120,
        valueGetter: (params: any) => params.row?.sku?.uom
    },
    {
        field: 'totalQuantity',
        headerName: 'Quantity',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        maxWidth: 150
    },
    {
        field: 'skuSellingPrice',
        headerName: 'GSV',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 150,
        renderCell: (params: any) => {
            let total = parseFloat(params.row?.sellingPrice) + parseFloat(params.row?.domainGsvCharges?.total);
            return (
                <>{getPriceInFormatted(total, params.row.country)}</>
            )
        }
    },
    {
        field: 'skuRcVendors',
        headerName: 'RC',
        type: 'price',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 80,
        renderCell: (params: any) => params.row?.sku?.rcVendors
    },
    {
        field: 'skuTotal',
        headerName: 'Total',
        type: 'price',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        renderCell: (params: any) => {
            let total = (parseFloat(params.row?.sellingPrice) * parseInt(params.row?.totalQuantity)) + (parseInt(params.row?.totalQuantity) * parseFloat(params.row.domainGsvCharges.total));
            return (<>{getPriceInFormatted(total, params.row.country)}</>)
        }
    }
];


