import ListPageMain from "./ListPageMain";


const ListPageWithSelection = (props: any) => {


    return (
        <ListPageMain
            columns={props.columns}
            totalElements={props.totalElements}
            rows={props.rows}
            sortColumn={props.sortColumn}
            sortDirection={props.sortDirection}
            size={props.size}
            page={props.page}
            hideColumns={props.hideColumns}
            handleSort={props.handleSort}
            handlePagination={props.handlePagination}
            checkboxSelection={props.checkboxSelection}
            handleCallBack={props.handleCallBack}
            handleRowSelected={props.handleRowSelected}
            hideFooter={props?.hideFooter}
            hideFooterPagination={props?.hideFooterPagination}
            enableRowSelection={props.enableRowSelection}
            selectionModel={props?.selectionModel}
            disableMultipleRowSelection={props.disableMultipleRowSelection}
        />
    );
};
export default ListPageWithSelection;
