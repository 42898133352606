import {Typography} from "@mui/material";
import {getFormattedDate, getFormattedDateTime, getPriceInFormatted} from "../../../../utils/Common";

export const packageListColumns = [
    {
        field: 'name',
        headerName: 'Package Name',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 250
    },
    {
        field: 'noOfVendors',
        headerName: 'RC',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 90
    },
    {
        field: 'createdAt',
        headerName: 'Created On',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 150,
        valueGetter: (params: any) => getFormattedDate(params.row?.createdAt)
    },
    {
        field: 'total',
        headerName: 'Total GSV',
        type: 'price',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 170,
        renderCell: (params: any) => {
            let total = parseFloat(params.row.total) + parseFloat(params.row.domainGsvCharges.total);
            return (
                <>{getPriceInFormatted(total, params.row.country)}</>
            )
        }
    },
    {
        field: 'type',
        headerName: 'Type',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        maxWidth: 130,
        renderCell: (params: any) => {
            let type = params.row.type;
            if(type == 'auto') {
                type = "Auto exec";
            }
            return (
                <Typography sx={{
                    textTransform: 'capitalize',
                    border: 1,
                    borderRadius: 1,
                    borderColor: `rgba(223, 112, 113, 1)`,
                    backgroundColor: `rgba(223, 112, 113, 0.5)`,
                    p: 0.6,
                    minWidth: '100px',
                    textAlign: 'left'
                }}>{type.toLowerCase()}</Typography>
            )
        }
    },
    {
        field: 'status',
        headerName: 'Status',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        maxWidth: 150,
        renderCell: (params: any) => {
            let color = '253, 182, 122';
            let name = params.row.status;
            if (name == 'CREATED') {
                color = '223, 149, 113';
            }
            if (name == 'PR') {
                color = '223, 112, 113';
            }
            if (name == 'EVENT') {
                color = '223, 149, 149';
            }
            if (name == 'CLOSED') {
                color = '55, 214, 112';
            }

            return (
                <Typography sx={{
                    textTransform: 'capitalize',
                    border: 1,
                    borderColor: `rgba(${color}, 1)`,
                    backgroundColor: `rgba(${color}, 0.5)`,
                    borderRadius: 1,
                    p: 0.6,
                    minWidth: '100px',
                    textAlign: 'left'
                }}>{name.toLowerCase()}</Typography>
            )
        }
    },
    {
        field: 'action',
        headerName: 'Action',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        show: 'button',
        buttons: [
            {
                type: 'anchor',
                title: 'View Details',
                permissions: ['edit-projects', 'view-package'],
                condition: (row: any) => true
            },
            {
                type: 'edit',
                title: 'Edit',
                permissions: ['edit-package'],
                condition: (row: any) => row.status === 'CREATED'
            },
            {
                type: 'publish',
                title: 'Publish',
                variant: 'contained',
                permissions: ['edit-projects'],
                condition: (row: any) => row.status === 'CREATED'
            },
            {
                type: 'revoke',
                title: 'Revoke',
                variant: 'text',
                permissions: ['edit-projects'],
                condition: (row: any) => row.status === 'CREATED'
            },
        ]
    },
];

export const packageListReadOnlyColumns = [
    {
        field: 'name',
        headerName: 'Package Name',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 250
    },
    {
        field: 'noOfVendors',
        headerName: 'RC',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 100
    },
    {
        field: 'createdAt',
        headerName: 'Created On',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 200,
        valueGetter: (params: any) => getFormattedDate(params.row?.createdAt)
    },
    // {
    //     field: 'total',
    //     headerName: 'Total Value',
    //     type: 'price',
    //     headerClassName: 'data-grid-header',
    //     sortable: false,
    //     flex: 1,
    //     maxWidth: 200,
    // },
    {
        field: 'status',
        headerName: 'Status',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        maxWidth: 200,
        renderCell: (params: any) => {
            let color = '253, 182, 122';
            let name = params.row.status;
            if (name == 'CREATED') {
                color = '223, 149, 113';
            }
            if (name == 'PR') {
                color = '223, 112, 113';
            }
            if (name == 'EVENT') {
                color = '223, 149, 149';
            }
            if (name == 'CLOSED') {
                color = '55, 214, 112';
            }

            return (
                <Typography sx={{
                    textTransform: 'capitalize',
                    border: 1,
                    borderColor: `rgba(${color}, 1)`,
                    borderRadius: 1,
                    p: 0.6,
                    backgroundColor: `rgba(${color}, 0.5)`,
                    minWidth: '100px',
                    textAlign: 'left'
                }}>{name.toLowerCase()}</Typography>
            )
        }
    },
    {
        field: 'action',
        headerName: 'Action',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        show: 'button',
        buttons: [
            {
                type: 'anchor',
                title: 'View Details',
                permissions: ['edit-projects'],
                condition: (row: any) => true
            }
        ]
    },
];


export const eventListColumns = [
    {
        field: 'packageName',
        headerName: 'Package Name',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        valueGetter: (params: any) => params.row?.eventPackages[0]?.name + (params.row?.eventPackages.length > 1 ? " and Many" : "")
    },
    {
        field: 'name',
        headerName: 'Event Name',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
    },
    {
        field: 'createdAt',
        headerName: 'Created On',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        valueGetter: (params: any) => getFormattedDate(params.row?.createdAt)
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        valueGetter: (params: any) => getFormattedDateTime(params.row?.startDate)
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        valueGetter: (params: any) => getFormattedDateTime(params.row?.endDate)
    },
    {
        field: 'status',
        headerName: 'Status',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        maxWidth: 200,
        renderCell: (params: any) => {
            let color = '253, 182, 122';
            let name = params.row.status;
            if (name == 'CREATED') {
                color = '223, 149, 113';
            }
            if (name == 'CLOSED') {
                color = '55, 214, 112';
            }
            if(name == "BID_CLOSED")
                name = "Bid Closed";

            return (
                <Typography sx={{
                    textTransform: 'capitalize',
                    border: 1,
                    borderColor: `rgba(${color}, 1)`,
                    borderRadius: 1,
                    p: 0.6,
                    backgroundColor: `rgba(${color}, 0.5)`,
                    minWidth: '100px',
                    textAlign: 'left'
                }}>{name.toLowerCase()}</Typography>
            )
        }
    },
    {
        field: 'action',
        headerName: 'Action',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        show: 'button',
        buttons: [{
            type: 'anchor',
            title: 'View Details',
            permissions: ['edit-event', "edit-projects", "view-event"],
            condition: (row: any) => true
        }]
    },
];

export const packagePrListColumns = [
        {
            field: 'name',
            headerName: 'Package Name',
            headerClassName: 'data-grid-header',
            sortable: false,
            flex: 1,
            maxWidth: 200
        },
        {
            field: 'vendorLink',
            headerName: 'Vendor',
            headerClassName: 'data-grid-header',
            sortable: false,
            flex: 1,
            maxWidth: 200,
            renderCell: (params: any) => (
                <>
                    {
                        params.row?.entityType == 'brand' ? (
                            <a
                                href={`/brands/details/${params.row?.entity?.id}`}
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                {params.row?.entity.name}
                            </a>
                        ) : (
                            <a
                                href={`/vendors/details/${params.row?.entity?.id}`}
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                {params.row?.entity.name + " (" + params?.row?.entity?.city + ")"}
                            </a>
                        )
                    }
                </>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Created On',
            headerClassName: 'data-grid-header',
            sortable: false,
            flex: 1,
            valueGetter: (params: any) => getFormattedDate(params.row?.createdAt)
        },
        // {
        //     field: 'total',
        //     headerName: 'Total Value',
        //     type: 'price',
        //     headerClassName: 'data-grid-header',
        //     sortable: false,
        //     flex: 1
        // },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'data-grid-header',
            flex: 1,
            sortable: false,
            maxWidth: 200,
            renderCell:
                (params: any) => {
                    let color = '253, 182, 122';
                    let name = params.row.status;
                    if (name == 'PR') {
                        color = '223, 112, 113';
                    }
                    if (name == 'CLOSED') {
                        color = '55, 214, 112';
                    }

                    return (
                        <Typography sx={{
                            textTransform: 'capitalize',
                            border: 1,
                            borderColor: `rgba(${color}, 1)`,
                            borderRadius: 1,
                            p: 0.6,
                            backgroundColor: `rgba(${color}, 0.5)`,
                            minWidth: '100px',
                            textAlign: 'left'
                        }}>{name.toLowerCase()}</Typography>
                    )
                }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'data-grid-header',
            headerAlign: 'right',
            align: 'right',
            flex: 1,
            sortable: false,
            show: 'button',
            buttons:
                [
                    {
                        type: 'anchor',
                        title: 'View Details',
                        permissions: ['edit-projects'],
                        condition: (row: any) => true
                    }
                ]
        }
    ]
;
