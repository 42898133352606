import {
    Box,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getStyle} from "../../../utils/ModalUtils";
import CloseIcon from "@mui/icons-material/Close";
import {interpolateColor} from "./utils";
import {getPriceInFormatted} from "../../../utils/Common";

const CompareBids = (props: any) => {
    const style = getStyle(85, 75, 15, 1);
    let {eventVendorsList, eventPackageDetails, eventDetails} = props;
    const [bidVendors, setBidVendors] = useState<any>([]);
    const [eventPackages, setEventPackages] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [totalGsv, setTotalGsv] = useState(0);
    const [totalCogs, setTotalCogs] = useState(0);
    const [L1Total, setL1Total] = useState(0);
    const [rankWiseVendors, setRankWiseVendor] = useState<any>({});
    const [highestRank, setHighestRank] = useState(0);

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallback();
    }

    useEffect(() => {
        if (eventVendorsList.length > 0) {
            let priceAvailableVendors: any[] = [];
            let gsvTotal = 0;
            let cogsTotal = 0;
            let leastItemPriceTotal = 0;
            const updatedEventPackageDetails = eventPackageDetails.map((packageDetails: any) => {
                const items = packageDetails.items.map((item: any) => {
                    let leastItemPrice = Number.MAX_VALUE;
                    let prices: any[] = [];
                    const vendorPriceDetails: any = {};

                    gsvTotal += (item.gsv + item.domainGsvCharges?.total) * item.totalQuantity;
                    cogsTotal += (item.cogsPrice + item.domainCogsCharges?.total) * item.totalQuantity;

                    eventVendorsList.forEach((eventVendor: any) => {
                        const revisionPrices: any[] = [];

                        eventVendor.vendorRevisions.forEach((vendorRevision: any) => {
                            vendorRevision.eventVendorDetails.map((vendorDetails: any) => {
                                if (vendorDetails.packageItemId == item.id) {
                                    let vendorPrice = vendorDetails.price + vendorDetails.domainCharges.total;
                                    if ((vendorPrice * item.totalQuantity) < leastItemPrice) {
                                        leastItemPrice = vendorPrice * item.totalQuantity;
                                    }
                                    revisionPrices.push({
                                        price: vendorPrice,
                                        revision: vendorRevision.revision,
                                        eventVendorId: eventVendor.id
                                    });
                                    if (!priceAvailableVendors.includes(eventVendor.vendor.id)) {
                                        priceAvailableVendors.push(eventVendor.vendor.id);
                                    }

                                    prices.push((vendorPrice));
                                }
                            });
                        });
                        if (priceAvailableVendors.includes(eventVendor.vendor.id)) {
                            vendorPriceDetails[eventVendor.id] = revisionPrices;
                        }
                    });

                    leastItemPriceTotal += leastItemPrice;

                    let max, min = 0;
                    if (Object.keys(vendorPriceDetails).length > 0) {
                        max = Math.max(...prices);
                        min = Math.min(...prices);
                    }
                    return {
                        ...item, eventVendors: vendorPriceDetails, min, max
                    }
                });
                return {
                    ...packageDetails, items: items
                }
            });

            setTotalGsv(gsvTotal);
            setTotalCogs(cogsTotal);

            setL1Total(leastItemPriceTotal);
            setBidVendors(priceAvailableVendors);
            setEventPackages(updatedEventPackageDetails);

            const revisionWiseTotal: any = {};

            const revisionTotals: any[] = [];

            eventVendorsList.map((vendorDetails: any, idx: number) => {
                let total = [];
                if (priceAvailableVendors.includes(vendorDetails.vendor.id)) {
                    const revisionPrices: any[] = [];
                    vendorDetails.vendorRevisions.forEach((vendorRevision: any) => {
                        vendorRevision.eventVendorDetails.forEach((eventVendorDetails: any) => {
                            eventPackageDetails.map((packageDetails: any) => {
                                packageDetails.items.map((item: any) => {
                                    if (eventVendorDetails.packageItemId == item.id) {
                                        let vendorPrice = ((eventVendorDetails.price + eventVendorDetails.domainCharges.total) * item.totalQuantity);
                                        revisionPrices.push({
                                            price: vendorPrice,
                                            revision: vendorRevision.revision,
                                            eventVendorId: vendorDetails.id
                                        });
                                    }
                                });
                            });
                        });
                    });
                    for (let i = 1; i <= eventDetails.currentRevision; i++) {
                        let revisionTotal = 0;
                        revisionPrices.forEach((revisionPrice: any) => {
                            if (i == revisionPrice.revision) {
                                revisionTotal += revisionPrice.price;
                            }
                        });

                        total.push({eventVendorId: vendorDetails.id, revision: i, price: revisionTotal});
                        revisionTotals.push({eventVendorId: vendorDetails.id, revision: i, price: revisionTotal});
                    }
                }
                revisionWiseTotal[vendorDetails.id] = total;
            });
            revisionTotals.sort(function (a, b) {
                return a.price - b.price;
            });

            let rank = 1;
            revisionTotals.map((revisionTotal: any) => {
                if (revisionTotal.price > 0) {
                    const revisionPrices = revisionWiseTotal[revisionTotal.eventVendorId];
                    revisionPrices.map((revisionPrice: any) => {
                        if (revisionTotal.revision == revisionPrice.revision) {
                            if (revisionPrice.price > 0) {
                                revisionPrice.rank = "L" + rank;
                                rank++;
                            } else {
                                revisionPrice.rank = "NA";
                            }
                        }
                    });
                }
            });
            setHighestRank(rank - 1);
            setRankWiseVendor(revisionWiseTotal);
        }
    }, [eventVendorsList]);

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Compare Vendor Bids
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box>
                                <TableContainer
                                    component={"div"}
                                    sx={{
                                        border: "1px solid",
                                        borderColor: "divider",
                                        borderBottom: 0,
                                        maxHeight: "500px",
                                        overflowX: "auto",
                                        overflowY: "auto",
                                    }}
                                >
                                    <Table sx={{minWidth: 650}} aria-label="simple table">
                                        <TableHead sx={{
                                            backgroundColor: "info.contrastText",
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 2
                                        }}>
                                            <TableRow>
                                                <TableCell width={"6%"} align={"left"}
                                                           sx={{fontWeight: 500, minWidth: '100px'}}>Sr. No.</TableCell>
                                                <TableCell align={"left"} sx={{fontWeight: 500, minWidth: '150px'}}>
                                                    SKU Name
                                                </TableCell>
                                                <TableCell align={"left"} sx={{fontWeight: 500, minWidth: '100px'}}>
                                                    UOMName
                                                </TableCell>
                                                <TableCell align={"left"} sx={{fontWeight: 500, minWidth: '150px'}}>
                                                    Specifications
                                                </TableCell>
                                                <TableCell align={"left"}
                                                           sx={{fontWeight: 500, minWidth: '100px'}}>
                                                    Quantity
                                                </TableCell>
                                                <TableCell align={"left"}
                                                           sx={{fontWeight: 500, minWidth: '100px'}}>
                                                    GSV Rate
                                                </TableCell>
                                                <TableCell align={"center"} sx={{fontWeight: 500, minWidth: '150px'}}>
                                                    GSV Amount
                                                </TableCell>
                                                {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                    if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                        return (
                                                            <TableCell key={'bid-vendor-names-' + idx} align={"center"}
                                                                       sx={{fontWeight: 500, minWidth: '150px', border: 0, borderBottom: 1, borderLeft: 1, borderRight: 1, borderColor: 'divider'}}>
                                                                {vendorDetails?.vendor?.name + " (" + vendorDetails?.vendor?.city + ")" || "Unknown Vendor"}
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                                <TableCell align={"left"}
                                                           sx={{fontWeight: 500, minWidth: '150px'}}>
                                                    Planned COGS Rate
                                                </TableCell>
                                                <TableCell align={"left"}
                                                           sx={{fontWeight: 500, minWidth: '150px'}}>
                                                    Planned COGS Amount
                                                </TableCell>
                                                <TableCell align={"left"}
                                                           sx={{fontWeight: 500, minWidth: '100px'}}>
                                                    L1 Rate
                                                </TableCell>
                                                <TableCell align={"left"}
                                                           sx={{fontWeight: 500, minWidth: '100px'}}>
                                                    L1 Amount
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={7}/>
                                                {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                    if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                        const cells = [];
                                                        for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                                            cells.push(
                                                                <TableCell key={"bid-amount-" + i} align={"center"}
                                                                           sx={{p: 0, border: 0, borderRight: 1, borderColor: 'divider'}}>
                                                                    <TableContainer>
                                                                        <Table>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell colSpan={2}
                                                                                               align={"center"}
                                                                                               sx={{px: 0, py: 1, border: 0}}>R{i}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell align={"center"}
                                                                                               sx={{minWidth: '150px', px: 0, py: 1, border: 0, borderTop: 1, borderColor: 'divider'}}>Rate</TableCell>
                                                                                    <TableCell align={"center"}
                                                                                               sx={{minWidth: '150px', px: 0, py: 1, border: 0, borderTop: 1, borderColor: 'divider'}}>Amount</TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </TableCell>
                                                            );
                                                        }
                                                        return (
                                                            <TableCell key={'bid-vendor-names-revision-' + idx}
                                                                       align={"left"}
                                                                       sx={{
                                                                           fontWeight: 500,
                                                                           minWidth: '150px',
                                                                           p: 0,
                                                                           border: 0,
                                                                           borderLeft: 1,
                                                                           borderColor: 'divider'
                                                                       }}>
                                                                <TableContainer>
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                {cells}
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {eventPackages.map((eventPackage: any, packageIndex: number) => (
                                                eventPackage.items.map((item: any, itemIndex: number) => {
                                                    let currentIndex = (packageIndex * eventPackage.items.length) + itemIndex;
                                                    let itemGsv = item.gsv + item.domainGsvCharges?.total;
                                                    let itemCogs = item.cogsPrice + item.domainCogsCharges?.total;
                                                    let leastItemPrice = Number.MAX_VALUE;
                                                    return (
                                                        <TableRow
                                                            key={"package-" + packageIndex + "-" + itemIndex}>
                                                            <TableCell width={"6%"} align={"left"}
                                                                       sx={{fontWeight: 500, minWidth: '100px'}}>
                                                                {currentIndex + 1}
                                                            </TableCell>
                                                            <TableCell align="left"
                                                                       sx={{minWidth: '150px'}}>
                                                                <Typography
                                                                    sx={{fontSize: '11px', fontWeight: 400}}>
                                                                    {item.itemDetail?.itemCategory?.name}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{fontSize: '13px', fontWeight: 500}}>
                                                                    {item.itemDetail?.sku?.name}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left"
                                                                       sx={{minWidth: '100px'}}>
                                                                {item.itemDetail?.sku.uom}
                                                            </TableCell>
                                                            <TableCell align="left"
                                                                       sx={{minWidth: '150px'}}>
                                                                {item.itemDetail?.sku.options != null && item.itemDetail?.sku.options.map((option: any, idx: number) => (
                                                                    <Typography
                                                                        key={idx}
                                                                        variant={"body2"}
                                                                        sx={{fontSize: "0.8rem"}}>
                                                                        <strong>{option.name}:</strong> {option.value}
                                                                    </Typography>
                                                                ))}
                                                            </TableCell>
                                                            <TableCell align="left"
                                                                       sx={{minWidth: '100px'}}>
                                                                <Typography sx={{
                                                                    fontSize: '11px',
                                                                    fontWeight: 400
                                                                }}>{item.totalQuantity}</Typography>
                                                            </TableCell>
                                                            <TableCell align="left"
                                                                       sx={{minWidth: '100px'}}>
                                                                <Typography sx={{
                                                                    fontSize: '11px',
                                                                    fontWeight: 400
                                                                }}>{getPriceInFormatted(itemGsv, eventDetails.project.country)}</Typography>
                                                            </TableCell>
                                                            <TableCell align="center"
                                                                       sx={{minWidth: '150px'}}>
                                                                <Typography sx={{
                                                                    fontSize: '11px',
                                                                    fontWeight: 400
                                                                }}>{getPriceInFormatted(itemGsv * item.totalQuantity, eventDetails.project.country)}</Typography>
                                                            </TableCell>

                                                            {Object.keys(item.eventVendors).map((vendor: any, vendorIndex: number) => {
                                                                const cells: any[] = [];
                                                                const cellsData: any[] = item.eventVendors[vendor];

                                                                for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                                                    let cellDetails = "NA"
                                                                    let color = "darkgrey";
                                                                    let totalAmount = 0;
                                                                    cellsData.forEach((cellData: any) => {
                                                                        if (i == cellData.revision) {
                                                                            if (cellData.price < leastItemPrice) {
                                                                                leastItemPrice = cellData.price;
                                                                            }
                                                                            totalAmount = cellData.price * item.totalQuantity;
                                                                            cellDetails = getPriceInFormatted(cellData.price, eventDetails.project.country);
                                                                            color = interpolateColor(item.min, item.max, Number(cellData.price));
                                                                        }
                                                                    });
                                                                    cells.push(
                                                                        <TableCell
                                                                            key={"bid-amount-prices-" + vendorIndex + "-" + i}
                                                                            align={"center"}
                                                                            sx={{
                                                                                minWidth: '100px',
                                                                                border: 0, borderLeft: 1, borderColor: 'divider'
                                                                            }}
                                                                        >
                                                                            <TableContainer>
                                                                                <Table>
                                                                                    <TableBody>
                                                                                        <TableRow>
                                                                                            <TableCell align={"center"}
                                                                                                       sx={{minWidth: '150px', px: 0, py: 1, border: 0}}>
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        color: color,
                                                                                                        fontWeight: 600,
                                                                                                        fontSize: '11px'
                                                                                                    }}>
                                                                                                    {cellDetails}
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell align={"center"}
                                                                                                       sx={{minWidth: '150px', px: 0, py: 1, border: 0}}>
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        color: color,
                                                                                                        fontWeight: 600,
                                                                                                        fontSize: '11px'
                                                                                                    }}>{getPriceInFormatted(totalAmount, eventDetails.project.country)}
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                return (
                                                                    <TableCell
                                                                        key={'bid-vendor-names-revision-' + vendorIndex}
                                                                        align={"left"}
                                                                        sx={{
                                                                            fontWeight: 500,
                                                                            p: 0,
                                                                            borderRight: 1,
                                                                            borderColor: 'divider'
                                                                        }}>
                                                                        <TableContainer>
                                                                            <Table>
                                                                                <TableBody>
                                                                                    <TableRow>
                                                                                        {cells}
                                                                                    </TableRow>
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </TableCell>
                                                                )
                                                            })}
                                                            <TableCell align="left"
                                                                       sx={{minWidth: '100px'}}>
                                                                <Typography sx={{
                                                                    fontSize: '11px',
                                                                    fontWeight: 400
                                                                }}>{getPriceInFormatted(itemCogs, eventDetails.project.country)}</Typography>
                                                            </TableCell>
                                                            <TableCell align="left"
                                                                       sx={{minWidth: '100px'}}>
                                                                <Typography sx={{
                                                                    fontSize: '11px',
                                                                    fontWeight: 400
                                                                }}>{getPriceInFormatted(itemCogs * item.totalQuantity, eventDetails.project.country)}</Typography>
                                                            </TableCell>
                                                            <TableCell align="left"
                                                                       sx={{minWidth: '100px'}}>
                                                                <Typography sx={{
                                                                    fontSize: '11px',
                                                                    fontWeight: 400
                                                                }}>{getPriceInFormatted(leastItemPrice, eventDetails.project.country)}</Typography>
                                                            </TableCell>
                                                            <TableCell align="left"
                                                                       sx={{minWidth: '100px'}}>
                                                                <Typography sx={{
                                                                    fontSize: '11px',
                                                                    fontWeight: 400
                                                                }}>{getPriceInFormatted(leastItemPrice * item.totalQuantity, eventDetails.project.country)}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            ))}
                                        </TableBody>
                                        <TableFooter
                                            sx={{
                                                backgroundColor: 'background.paper',
                                                zIndex: 9999,
                                                boxShadow: '0px 1px 1px rgba(50, 50, 50, 0.75)'
                                            }}>
                                            <TableRow>
                                                <TableCell align={"center"} colSpan={6}
                                                           sx={{
                                                               fontSize: '14px',
                                                               fontWeight: 700,
                                                               color: 'common.black',
                                                               py: 1, px: 2,
                                                               border: 0, borderBottom: 1, borderColor: 'divider'
                                                           }}
                                                >
                                                    Overall Total:
                                                </TableCell>
                                                <TableCell sx={{minWidth: '150px'}} align={'center'}>
                                                    {totalGsv > 0 ? getPriceInFormatted(totalGsv, eventDetails.project.country) : "NA"}
                                                </TableCell>
                                                {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                    if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                        const revisionPrices: any[] = [];
                                                        vendorDetails.vendorRevisions.forEach((vendorRevision: any) => {
                                                            vendorRevision.eventVendorDetails.forEach((eventVendorDetails: any) => {
                                                                eventPackageDetails.map((packageDetails: any) => {
                                                                    packageDetails.items.map((item: any) => {
                                                                        if (eventVendorDetails.packageItemId == item.id) {
                                                                            let vendorPrice = ((eventVendorDetails.price + eventVendorDetails.domainCharges.total) * item.totalQuantity);
                                                                            revisionPrices.push({
                                                                                rate: eventVendorDetails.price + eventVendorDetails.domainCharges.total,
                                                                                price: vendorPrice,
                                                                                revision: vendorRevision.revision,
                                                                                eventVendorId: vendorDetails.id
                                                                            });
                                                                        }
                                                                    });
                                                                });
                                                            });
                                                        });

                                                        const cells: any[] = [];
                                                        for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                                            let cellDetails = 0;
                                                            let rateTotal = 0;
                                                            revisionPrices.forEach((cellData: any) => {
                                                                if (i == cellData.revision) {
                                                                    rateTotal += cellData.rate;
                                                                    cellDetails += cellData.price;
                                                                }
                                                            });
                                                            cells.push(
                                                                <TableCell
                                                                    key={"bid-amount-prices-" + idx + "-" + i}
                                                                    align={"center"}
                                                                    sx={{
                                                                        minWidth: '100px',
                                                                        border: 0, borderRight: 1, borderColor: 'divider'
                                                                    }}
                                                                >
                                                                    <TableContainer>
                                                                        <Table>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell align={"center"}
                                                                                               sx={{minWidth: '150px', px: 0, py: 1, border: 0}}>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize: "12px", p: 0
                                                                                            }}>
                                                                                            {rateTotal > 0 ? getPriceInFormatted(rateTotal, eventDetails.project.country) : "NA"}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align={"center"}
                                                                                               sx={{minWidth: '150px', px: 0, py: 1, border: 0}}>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize: "12px", p: 0
                                                                                            }}>
                                                                                            {cellDetails > 0 ? getPriceInFormatted(cellDetails, eventDetails.project.country) : "NA"}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </TableCell>
                                                            );
                                                        }
                                                        return (
                                                            <TableCell key={"accept-bid-for-" + idx} className={"rees"}
                                                                       sx={{
                                                                           borderLeft: 1,
                                                                           borderColor: 'divider',
                                                                           p: 0
                                                                       }}>
                                                                <TableContainer>
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                {cells}
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                                <TableCell/>
                                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}}>
                                                    {totalCogs > 0 ? getPriceInFormatted(totalCogs, eventDetails.project.country) : "NA"}
                                                </TableCell>
                                                <TableCell/>
                                                <TableCell>
                                                    {L1Total > 0 ? getPriceInFormatted(L1Total, eventDetails.project.country) : "NA"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align={"center"} colSpan={6}
                                                           sx={{
                                                               fontSize: '14px',
                                                               fontWeight: 700,
                                                               color: 'common.black',
                                                               py: 1, px: 2,
                                                               border: 0, borderBottom: 1, borderColor: 'divider'
                                                           }}
                                                >
                                                    NSV Amount
                                                </TableCell>
                                                <TableCell/>
                                                {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                    if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                        const revisionPrices: any[] = [];
                                                        vendorDetails.vendorRevisions.forEach((vendorRevision: any) => {
                                                            vendorRevision.eventVendorDetails.forEach((eventVendorDetails: any) => {
                                                                eventPackageDetails.map((packageDetails: any) => {
                                                                    packageDetails.items.map((item: any) => {
                                                                        if (eventVendorDetails.packageItemId == item.id) {
                                                                            let vendorPrice = ((eventVendorDetails.price + eventVendorDetails.domainCharges.total) * item.totalQuantity);
                                                                            revisionPrices.push({
                                                                                price: vendorPrice,
                                                                                revision: vendorRevision.revision,
                                                                                eventVendorId: vendorDetails.id
                                                                            });
                                                                        }
                                                                    });
                                                                });
                                                            });
                                                        });

                                                        const cells: any[] = [];
                                                        for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                                            let cellDetails = 0;
                                                            revisionPrices.forEach((cellData: any) => {
                                                                if (i == cellData.revision) {
                                                                    cellDetails += cellData.price;
                                                                }
                                                            });
                                                            cells.push(
                                                                <TableCell
                                                                    key={"bid-amount-prices-" + idx + "-" + i}
                                                                    align={"center"}
                                                                    sx={{
                                                                        minWidth: '100px',
                                                                        border: 0, borderRight: 1, borderColor: 'divider'
                                                                    }}
                                                                >
                                                                    <TableContainer>
                                                                        <Table>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell
                                                                                        sx={{minWidth: '150px', px: 0, py: 1, border: 0}}/>
                                                                                    <TableCell align={"center"}
                                                                                               sx={{minWidth: '150px', px: 0, py: 1, border: 0}}>
                                                                                        <Typography
                                                                                            sx={{fontSize: "12px", p: 0}}>
                                                                                            {cellDetails > 0 ? getPriceInFormatted((totalGsv - cellDetails), eventDetails.project.country) : "NA"}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </TableCell>
                                                            );
                                                        }

                                                        return (
                                                            <TableCell
                                                                key={"accept-bid-for-nsv-amount-" + idx}
                                                                sx={{
                                                                    border: 1,
                                                                    borderBottom: 1,
                                                                    borderRight: 1,
                                                                    borderColor: 'divider',
                                                                    p: 0
                                                                }}>
                                                                <TableContainer>
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                {cells}
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                                <TableCell/>
                                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}}>
                                                    {totalCogs > 0 ? getPriceInFormatted((totalGsv - totalCogs), eventDetails.project.country) : "NA"}
                                                </TableCell>
                                                <TableCell/>
                                                <TableCell>
                                                    {L1Total > 0 ? getPriceInFormatted((totalGsv - L1Total), eventDetails.project.country) : "NA"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align={"center"} colSpan={6}
                                                           sx={{
                                                               fontSize: '14px',
                                                               fontWeight: 700,
                                                               color: 'common.black',
                                                               py: 1, px: 2,
                                                               border: 0, borderBottom: 1, borderColor: 'divider'
                                                           }}
                                                >
                                                    NSV %
                                                </TableCell>
                                                <TableCell/>
                                                {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                    if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                        const revisionPrices: any[] = [];
                                                        vendorDetails.vendorRevisions.forEach((vendorRevision: any) => {
                                                            vendorRevision.eventVendorDetails.forEach((eventVendorDetails: any) => {
                                                                eventPackageDetails.map((packageDetails: any) => {
                                                                    packageDetails.items.map((item: any) => {
                                                                        if (eventVendorDetails.packageItemId == item.id) {
                                                                            let vendorPrice = ((eventVendorDetails.price + eventVendorDetails.domainCharges.total) * item.totalQuantity);
                                                                            revisionPrices.push({
                                                                                price: vendorPrice,
                                                                                revision: vendorRevision.revision,
                                                                                eventVendorId: vendorDetails.id
                                                                            });
                                                                        }
                                                                    });
                                                                });
                                                            });
                                                        });

                                                        const cells: any[] = [];
                                                        for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                                            let cellDetails = 0;
                                                            revisionPrices.forEach((cellData: any) => {
                                                                if (i == cellData.revision) {
                                                                    cellDetails += cellData.price;
                                                                }
                                                            });
                                                            let nsv = cellDetails > 0 ? ((totalGsv - cellDetails) / totalGsv * 100).toFixed(2) : "NA";
                                                            cells.push(
                                                                <TableCell
                                                                    key={"bid-amount-prices-" + idx + "-" + i}
                                                                    align={"center"}
                                                                    sx={{
                                                                        minWidth: '100px',
                                                                        border: 0, borderRight: 1, borderColor: 'divider'
                                                                    }}
                                                                >
                                                                    <TableContainer>
                                                                        <Table>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell
                                                                                        sx={{minWidth: '150px', px: 0, py: 1, border: 0}}/>
                                                                                    <TableCell align={"center"}
                                                                                               sx={{minWidth: '150px', px: 0, py: 1, border: 0}}>
                                                                                        <Typography
                                                                                            sx={{fontSize: "12px", p: 0}}>
                                                                                            {nsv != "NA" ? nsv + "%" : nsv}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </TableCell>
                                                            )
                                                        }

                                                        return (
                                                            <TableCell
                                                                key={"accept-bid-for-nsv-percentage" + idx}
                                                                sx={{
                                                                    border: 1,
                                                                    borderBottom: 1,
                                                                    borderRight: 1,
                                                                    borderColor: 'divider',
                                                                    p: 0
                                                                }}>
                                                                <TableContainer>
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                {cells}
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                                <TableCell/>
                                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}}>
                                                    {totalCogs > 0 ? ((totalGsv - totalCogs) / totalGsv * 100).toFixed(2) + "%" : "NA"}
                                                </TableCell>
                                                <TableCell/>
                                                <TableCell>
                                                    {L1Total > 0 ? ((totalGsv - L1Total) / totalGsv * 100).toFixed(2) + "%" : "NA"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align={"center"} colSpan={6}
                                                           sx={{
                                                               fontSize: '14px',
                                                               fontWeight: 700,
                                                               color: 'common.black',
                                                               py: 1, px: 2,
                                                               border: 0, borderBottom: 1, borderColor: 'divider'
                                                           }}
                                                >
                                                    Rank
                                                </TableCell>
                                                <TableCell/>
                                                {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                    if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                        const revisionPrices = rankWiseVendors[vendorDetails.id];

                                                        const cells: any[] = [];
                                                        for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                                            let rank: string = "";
                                                            revisionPrices.forEach((cellData: any) => {
                                                                if (i == cellData.revision) {
                                                                    if (cellData.hasOwnProperty("rank"))
                                                                        rank = cellData.rank;
                                                                    else
                                                                        rank = "NA";
                                                                }
                                                            });
                                                            cells.push(
                                                                <TableCell
                                                                    key={"bid-amount-prices-" + idx + "-" + i}
                                                                    align={"center"}
                                                                    sx={{
                                                                        minWidth: '100px',
                                                                        border: 0, borderRight: 1, borderColor: 'divider'
                                                                    }}
                                                                >
                                                                    <TableContainer>
                                                                        <Table>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell
                                                                                        sx={{minWidth: '150px', px: 0, py: 1, border: 0}}/>
                                                                                    <TableCell align={"center"}
                                                                                               sx={{minWidth: '150px', px: 0, py: 1, border: 0}}>
                                                                                        <Typography
                                                                                            sx={{fontSize: "12px", p: 0}}>
                                                                                            {rank == "L" + highestRank ? "HI" : rank}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </TableCell>
                                                            )
                                                        }

                                                        return (
                                                            <TableCell
                                                                key={"accept-bid-for-rank-for-" + idx} sx={{
                                                                border: 1,
                                                                borderBottom: 1,
                                                                borderRight: 1,
                                                                borderColor: 'divider',
                                                                p: 0
                                                            }}>
                                                                <TableContainer>
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                {cells}
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                            </TableRow>

                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )

}
export default CompareBids;
