import {skuPageConfig} from "../skus/listing/PageConfig";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {api} from "../../utils/Axios";
import Loader from "../Loader";
import {Box, Typography} from "@mui/material";
import ListPage from "../list-page/ListPage";
import RemoveFilters from "../filter/RemoveFilters";

const CatalogItemSkuListing = (props: any) => {
    let pageConfigs = skuPageConfig.columns;
    if (props.vendorDashboard) {
        pageConfigs = skuPageConfig.vendorColumns;
    } else if (props.type == 'brand') {
        pageConfigs = skuPageConfig.brandColumns;
    }

    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [sortColumn, setSortColumn] = useState("totalRc");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const navigate = useNavigate();

    const handleSort = (sortModel: any) => {
        setSortColumn(sortModel.field);
        setSortDirection(sortModel?.sort);
        setPage(0);
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        setPage(newPagination.page);
    }

    const handleCallBack = (rowData: any, buttonData: any) => {
        if (buttonData.type === 'anchor' && !props.vendorDashboard) {
            navigate(`/catalogue/skus/details/${rowData.id}`);
        } else {
            navigate(`/vendor/catalogue/skus/details/${rowData.id}`);
        }
    }

    const loadSku = () => {
        setIsLoading(true);
        let url = `/catalogue/sku/list/${props.item.id}`;
        if (props.type === 'brand') {
            url = `/catalogue/sku/brand-list/${props.item.id}`;
        }

        const params = new URLSearchParams({
            pageNo: page.toString(),
            pageSize: size.toString(),
            sort: sortDirection?.toUpperCase(),
            sortByColumn: sortColumn,
            searchQuery: props.searchQuery
        });

        if (Object.keys(props.appliedFilters).length > 0) {
            Object.entries(props.appliedFilters).map(([name, value]: any) => {
                params.append(name, value);
            });
        }

        api.get(url, {params})
            .then((response) => response.data)
            .then((response: any) => {
                setRows(response.content);
                setTotalElements(response.totalElements);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        loadSku();
    }, [sortColumn, sortDirection, page, size, props.searchQuery, props.appliedFilters, props.item]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Box sx={{pb: 5}} className={"sku-listing"}>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Box>
                    <Box sx={{pb: 2}}>
                        <Typography component={'span'}
                                    sx={{
                                        py: 1,
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                            Item Name:&nbsp;
                        </Typography>
                        <Typography component={'span'} sx={{fontSize: '14px', fontWeight: 600, textAlign: 'left'}}>
                            {props.item?.name.split('Item :')[1]}
                        </Typography>
                    </Box>
                    {/* <Box sx={{pb: 2}}>
                        <Typography component={'span'} sx={{py: 1, fontWeight: 700}}>
                            Item Code:&nbsp;
                        </Typography>
                        <Typography component={'span'}>
                            {props.item?.code}
                        </Typography>
                    </Box> */}
                </Box>
            </Box>
            <Box>
                <RemoveFilters handleFilterChange={props.handleFilterChange}
                               filters={props.filters}
                               appliedFilters={props.appliedFilters}/>
            </Box>

            <ListPage
                rows={rows}
                columns={pageConfigs}
                page={page}
                size={size}
                totalElements={totalElements}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                handlePagination={handlePagination}
                handleSort={handleSort}
                handleCallBack={handleCallBack}
                rowHeight={92}
            />
        </Box>
    )
}

export default CatalogItemSkuListing;