import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {ChangeEvent} from "react";
import {useEffect, useState} from "react";

const RadioOptions = (props: any) => {
    const {formField} = props;
    const [fieldValue, setFieldValue] = useState<any>("");
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFieldValue(event.target.value);
        props.onChangeHandler(event);
    }
    useEffect(() => {
        let val = (props?.valueFields?.hasOwnProperty(formField.name)) ? props.valueFields[formField.name] :  (formField.value != undefined ? formField.value : "");
        setFieldValue(val);
    }, []);

    useEffect(() => {
        if(props?.valueFields?.hasOwnProperty(formField.name) && props?.valueFields[formField.name] != formField) {
            setFieldValue(props?.valueFields[formField.name]);
        }
        //console.log(props.valueFields);
    }, [props.valueFields, props.disableFields])
    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={formField.name}
                onChange={handleChange}
                value={fieldValue}
                //defaultValue={formField?.value}
            >
                {
                    formField.options.map((option: any, index: number) => (
                        <FormControlLabel key={"radio-button-form-" + index + "-" + option.id} value={option.id}
                                          control={<Radio/>} label={option.label} disabled={(formField?.readonly && fieldValue && option.id != fieldValue) ? true : false}/>
                    ))
                }
            </RadioGroup>
        </FormControl>
    )
}

export default RadioOptions;