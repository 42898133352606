import {Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getPriceInFormatted} from "../../../utils/Common";
import {interpolateColor} from "./utils";
import {useAppDispatch} from "../../../app/hooks";
import {getAcceptBid} from "./features/actions";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {ACCEPT_BID_FAILED, ACCEPT_BID_SUCCESS, ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";

const BreakBidsContent = (props: any) => {
    let {eventVendorsList, eventPackageDetails, eventDetails} = props;
    const [eventPackages, setEventPackages] = useState<any>([]);
    const [bidVendors, setBidVendors] = useState<any>([]);
    const [acceptBidCTA, setAcceptBidCTA] = useState<any>(true);
    const dispatch = useAppDispatch();

    const handleAcceptBid = async (
        vendorDetails: any, revisionNumber: any
    ) => {
        try {
            let revisionId = "";
            vendorDetails.vendorRevisions.map((vendorRevision: any) => {
                if (vendorRevision.revision == revisionNumber) {
                    revisionId = vendorRevision.id;
                }
            });
            if (revisionId !== "") {
                const response = await dispatch(getAcceptBid({eventVendorId: vendorDetails.id, revisionId: revisionId})).unwrap();
                if (response) {
                    dispatch(
                        openSnackbar({
                            message: ACCEPT_BID_SUCCESS,
                            backgroundColor: SUCCESS_COLOR,
                        })
                    );
                    setAcceptBidCTA(false);
                    props.acceptBid();
                }
            } else {
                dispatch(
                    openSnackbar({
                        message: ACCEPT_BID_FAILED,
                        backgroundColor: ERROR_COLOR,
                    })
                );
            }
        } catch (err: any) {

            console.log(err);
            dispatch(
                openSnackbar({
                    message: ACCEPT_BID_FAILED,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    };

    useEffect(() => {
        if (eventVendorsList.length > 0) {
            let priceAvailableVendors: any[] = [];
            const updatedEventPackageDetails = eventPackageDetails.map((packageDetails: any) => {
                const items = packageDetails.items.map((item: any) => {
                    let prices: any[] = [];
                    const vendorPriceDetails: any = {};
                    eventVendorsList.forEach((eventVendor: any) => {
                        const revisionPrices: any[] = [];

                        eventVendor.vendorRevisions.forEach((vendorRevision: any) => {
                            vendorRevision.eventVendorDetails.map((vendorDetails: any) => {
                                if (vendorDetails.packageItemId == item.id) {
                                    let vendorPrice = vendorDetails.price + vendorDetails.domainCharges.total;
                                    revisionPrices.push({price: vendorPrice, revision: vendorRevision.revision, eventVendorId: eventVendor.id});
                                    if (!priceAvailableVendors.includes(eventVendor.vendor.id)) {
                                        priceAvailableVendors.push(eventVendor.vendor.id);
                                    }

                                    prices.push((vendorPrice));
                                }
                            });
                        });
                        if (priceAvailableVendors.includes(eventVendor.vendor.id)) {
                            vendorPriceDetails[eventVendor.id] = revisionPrices;
                        }
                    });

                    let max, min = 0;
                    if (Object.keys(vendorPriceDetails).length > 0) {
                        max = Math.max(...prices);
                        min = Math.min(...prices);
                    }
                    return {
                        ...item, eventVendors: vendorPriceDetails, min, max
                    }
                })
                return {
                    ...packageDetails, items: items
                }
            });
            setBidVendors(priceAvailableVendors);
            setEventPackages(updatedEventPackageDetails);
        }
    }, [eventVendorsList]);

    return (
        <TableContainer
            component={"div"}
            sx={{
                border: "1px solid",
                borderColor: "divider",
                borderBottom: 0,
                maxHeight: "500px",
                overflowX: "auto",
                overflowY: "auto",
            }}
        >
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead sx={{
                    backgroundColor: "info.contrastText",
                    position: 'sticky',
                    top: 0,
                    zIndex: 2
                }}>
                    <TableRow>
                        <TableCell width={"6%"} align={"left"}
                                   sx={{fontWeight: 500, minWidth: '100px'}}>Sr. No.</TableCell>
                        <TableCell align={"left"} sx={{fontWeight: 500, minWidth: '150px'}}>SKU
                            Name</TableCell>
                        <TableCell width={"10%"} align={"left"}
                                   sx={{fontWeight: 500, minWidth: '100px'}}>Quantity</TableCell>
                        {eventVendorsList.map((vendorDetails: any, idx: number) => {
                            if (bidVendors.includes(vendorDetails.vendor.id)) {
                                return (
                                    <TableCell key={'bid-vendor-names-' + idx} align={"center"}
                                               sx={{fontWeight: 500, minWidth: '150px'}}>
                                        {vendorDetails?.vendor?.name + " (" + vendorDetails?.vendor?.city + ")" || "Unknown Vendor"}
                                    </TableCell>
                                )
                            }
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}/>
                        {eventVendorsList.map((vendorDetails: any, idx: number) => {
                            if (bidVendors.includes(vendorDetails.vendor.id)) {
                                const cells = [];
                                for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                    cells.push(
                                        <TableCell key={"revision-bid-" + i} align={"center"}
                                                   sx={{p: 0, border: 0, minWidth: '150px'}}>R{i}</TableCell>
                                    );
                                }
                                return (
                                    <TableCell key={'bid-vendor-names-revision-' + idx}
                                               align={"left"}
                                               sx={{fontWeight: 500, p: 0, borderRight: 1, borderColor: 'divider'}}>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        {cells}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TableCell>
                                )
                            }
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eventPackages.map((eventPackage: any, packageIndex: number) => (
                        eventPackage.items.map((item: any, itemIndex: number) => {
                            let currentIndex = (packageIndex * eventPackage.items.length) + itemIndex;
                            return (
                                <TableRow
                                    key={"package-" + packageIndex + "-" + itemIndex + "-" + eventPackage.id + "-" + item.id}>
                                    <TableCell width={"6%"} align={"left"} sx={{fontWeight: 500, minWidth: '100px'}}>
                                        {currentIndex + 1}
                                    </TableCell>
                                    <TableCell align="left" sx={{minWidth: '150px'}}>
                                        <Typography sx={{fontSize: '11px', fontWeight: 400}}>
                                            {item.itemDetail?.itemCategory?.name}
                                        </Typography>
                                        <Typography sx={{fontSize: '13px', fontWeight: 500}}>
                                            {item.itemDetail?.sku?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell width={"10%"} align="left"
                                               sx={{minWidth: '100px'}}>
                                        <Typography sx={{
                                            fontSize: '11px',
                                            fontWeight: 400
                                        }}>{item.totalQuantity}</Typography>
                                    </TableCell>

                                    {Object.keys(item.eventVendors).map((vendor: any, vendorIndex: number) => {
                                        const cells: any[] = [];
                                        const cellsData: any[] = item.eventVendors[vendor];

                                        for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                            let cellDetails = "NA"
                                            let color = "darkgrey";
                                            cellsData.forEach((cellData: any) => {
                                                if (i == cellData.revision) {
                                                    cellDetails = getPriceInFormatted(cellData.price, "");
                                                    color = interpolateColor(item.min, item.max, Number(cellData.price));
                                                }
                                            });
                                            cells.push(
                                                <TableCell key={"bid-prices-" + i} align={"center"}
                                                           sx={{border: 0, p: 0, minWidth: '150px'}}>
                                                    <Typography
                                                        sx={{color: color, fontWeight: 600, fontSize: '11px'}}>
                                                        {cellDetails}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }


                                        return (
                                            <TableCell key={'bid-vendor-names-revision-prices-' + vendorIndex}
                                                       sx={{fontWeight: 500, p: 0, borderRight: 1, borderColor: 'divider'}}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                {cells}
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })
                    ))}
                </TableBody>

                {eventDetails.status != "CLOSED" && (
                    <TableFooter>
                        <TableRow>
                            <TableCell align={"center"} colSpan={3}
                                       sx={{
                                           fontSize: '14px',
                                           fontWeight: 700,
                                           color: 'common.black',
                                           py: 1, px: 2, border: 0
                                       }}
                            >
                                Overall Total:
                            </TableCell>
                            {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                if (bidVendors.includes(vendorDetails.vendor.id)) {
                                    const revisionPrices: any[] = [];
                                    vendorDetails.vendorRevisions.forEach((vendorRevision: any) => {
                                        vendorRevision.eventVendorDetails.forEach((eventVendorDetails: any) => {
                                            eventPackageDetails.map((packageDetails: any) => {
                                                packageDetails.items.map((item: any) => {
                                                    if (eventVendorDetails.packageItemId == item.id) {
                                                        let vendorPrice = ((eventVendorDetails.price + eventVendorDetails.domainCharges.total) * item.totalQuantity);
                                                        revisionPrices.push({price: vendorPrice, revision: vendorRevision.revision, eventVendorId: vendorDetails.id});
                                                    }
                                                });
                                            });
                                        });
                                    });

                                    const cells: any[] = [];
                                    for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                        let cellDetails = 0;
                                        revisionPrices.forEach((cellData: any) => {
                                            if (i == cellData.revision) {
                                                cellDetails += cellData.price;
                                            }
                                        });
                                        cells.push(
                                            <TableCell
                                                key={"accept-bid-comparison" + idx}
                                                align="center"
                                                sx={{
                                                    minWidth: '150px',
                                                    border: 0,
                                                    p: 0,
                                                }}
                                            >
                                                <Typography sx={{fontSize: "12px"}}>
                                                    {cellDetails > 0 ? getPriceInFormatted(cellDetails, eventDetails.project.country) : "NA"}
                                                </Typography>
                                            </TableCell>
                                        );
                                    }
                                    return (
                                        <TableCell key={"accept-bid-for-" + idx}
                                                   sx={{borderRight: 1, borderColor: 'divider', p: 0}}>
                                            <TableContainer>
                                                <Table>
                                                    <TableRow>
                                                        {cells}
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                        <TableRow
                            sx={{backgroundColor: "info.contrastText", position: 'sticky', bottom: -1, zIndex: 1}}>
                            <TableCell colSpan={3}/>
                            {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                if (bidVendors.includes(vendorDetails.vendor.id)) {
                                    const revisionPrices: any[] = [];
                                    vendorDetails.vendorRevisions.forEach((vendorRevision: any) => {
                                        vendorRevision.eventVendorDetails.forEach((eventVendorDetails: any) => {
                                            let vendorPrice = eventVendorDetails.price + eventVendorDetails.domainCharges.total;
                                            revisionPrices.push({price: vendorPrice, revision: vendorRevision.revision, eventVendorId: vendorDetails.id});
                                        });
                                    });

                                    const cells: any[] = [];
                                    for (let i = 1; i <= eventDetails.currentRevision; i++) {
                                        let cellDetails = "NA"
                                        revisionPrices.forEach((cellData: any) => {
                                            if (i == cellData.revision) {
                                                cellDetails = "Accept Bid";
                                            }
                                        });
                                        cells.push(
                                            <TableCell
                                                key={"accept-bid-" + idx}
                                                align="center"
                                                sx={{
                                                    minWidth: '150px',
                                                    color: "#ccc",
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    lineHeight: "14.4px",
                                                    position: 'sticky',
                                                    bottom: 0,
                                                    border: 0,
                                                    p: 0,
                                                    backgroundColor: "info.contrastText"
                                                }}
                                            >
                                                {cellDetails != "NA" ? (
                                                    <Typography
                                                        onClick={() =>
                                                            handleAcceptBid(vendorDetails, i)
                                                        }
                                                        sx={{
                                                            fontSize: "12px",
                                                            cursor: "pointer",
                                                            color: "#3854E4",
                                                        }}
                                                    >
                                                        {cellDetails}
                                                    </Typography>
                                                ) : (
                                                    <Typography sx={{fontSize: "12px", color: "darkgray"}}>
                                                        {cellDetails}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        );
                                    }
                                    return (
                                        <TableCell key={"accept-bid-for-" + idx}
                                                   sx={{borderRight: 1, borderColor: 'divider', px: 0}}>
                                            <TableContainer>
                                                <Table>
                                                    <TableRow>
                                                        {cells}
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    )
}

export default BreakBidsContent;