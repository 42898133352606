import { useEffect, useMemo, useState, FormEvent, useRef } from "react";
import { Box, Button } from "@mui/material";
import Fields from "./fields/Fields";
import { hasAccess } from "../../utils/Access";
import ECatalogDialog from "../ecatalog/ECatalogDialog";

const prepareForm = (formArr: any, group: any) => {
    if (group !== undefined && group === true) {
        let formData = {};
        formArr.forEach((groupForm: any) => {
            let data = groupForm.fields.reduce(
                (r: any, v: any) => ({ ...r, [v.name]: v.value !== undefined ? v.value : "" }),
                {}
            );
            formData = { ...formData, ...data };
        });
        return formData;
    }
    return formArr.reduce(
        (r: any, v: any) => ({ ...r, [v.name]: v.value !== undefined ? v.value : "" }),
        {}
    );
};

const prepareValidationForm = (formArr: any, group: any) => {
    // console.log("prepareValidationForm ---");
    if (group !== undefined && group === true) {
        // console.log("prepareValidationForm group true  ---");
        let formData = {};
        formArr.forEach((groupForm: any) => {
            let data = groupForm.fields.reduce(
                (r: any, v: any) => ({ ...r, [v.name]: (v.disableField !== undefined || v.sumOf !== undefined || v.calculate !== undefined) ? {"disableField":v?.disableField,"disableOnValue": v?.disableOnValue, "sumOf": v?.sumOf, "errorMessage": v.errorMessage, "calculate": v?.calculate, "override": v?.override} : {} }),
                {}
            );
            formData = { ...formData, ...data };
        });
        return formData;
    }
    // console.log("prepareValidationForm group false  ---");
    formArr.reduce(
        (r: any, v: any) => ({ ...r, [v.name]: (v.disableField !== undefined || v.sumOf !== undefined || v.calculate !== undefined) ? {"disableField":v?.disableField,"disableOnValue": v?.disableOnValue, "sumOf": v?.sumOf, "errorMessage": v.errorMessage, "calculate": v?.calculate, "override": v?.override} : {} }),
        {}
    );
}

const BuildForm = (props: any) => {
    const { formArr, group, buttons } = props;    

    // Initial form values
    const initialForm = useMemo(() => prepareForm(formArr, group), [formArr]);
    const validationForm = useMemo<any>(() => prepareValidationForm(formArr, group), [formArr]);
    const [formData, setFormData] = useState(initialForm);
    const [disableFields, setDisableFields] = useState<any>({});
    const [valueFields, setValueFields] = useState<any>({});

    const [changedFields, setChangedFields] = useState<any>({});
    const [alertError, setAlertError] = useState<any>(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<any>("");

    const onFormDataChange = (form: any, currentField:any) => {
        var updatedDisabledFields: { [k: string]: any } = {};
        let localValueFields:any = valueFields;

        let localChangedFields = changedFields;
        Object.keys(form).forEach((key) => {
            if (form[key] !== initialForm[key]) {
                localChangedFields = ({ ...localChangedFields, [key]: form[key] });
            } else {
                const { [key]: _, ...rest } = localChangedFields;
                localChangedFields = rest;
            }
        });

        const changedFormData = { ...initialForm, ...localChangedFields };
        console.log("changedFormData",  changedFormData);
        console.log("currentField", currentField);
        Object.keys(changedFormData).forEach((key) => {

            if(validationForm != undefined && validationForm.hasOwnProperty(key) != undefined && validationForm[key].hasOwnProperty("sumOf") && validationForm[key]["sumOf"]) {
                let filedNames = validationForm[key]["sumOf"];
                if(validationForm.hasOwnProperty(currentField) && validationForm[currentField].hasOwnProperty("override") && validationForm[currentField]["override"]) {
                    form[key] = changedFormData[key];
                    localValueFields[key] = changedFormData[key];
                } else if(currentField != "" && filedNames.includes(currentField)) {
                    console.log("calculating SUM");
                //if(changedFormData[key]  && changedFormData[key] != "") {
                    let currentFieldValue = 0;
                    if(changedFormData[key] && changedFormData[key] != "") {
                        currentFieldValue = parseInt(changedFormData[key]);
                    }
                    let sumVal = 0;
                    let showError = true;
                    
                    filedNames.map((fld:any) => {
                        if(changedFormData[fld] && changedFormData[fld] != "") {
                            sumVal += parseInt(changedFormData[fld]);  
                        }
                    });

                    if(currentFieldValue != sumVal && showError) {
                        //setAlertErrorMessage(validationForm[key]["errorMessage"]);
                        //setAlertError(true);
                        localValueFields[key] = sumVal;
                        form[key] = sumVal;
                    }
                //}
                }
            }

            if(validationForm != undefined && validationForm.hasOwnProperty(key) != undefined && validationForm[key].hasOwnProperty("calculate") && validationForm[key]["calculate"]) {
                if(changedFormData[key] && changedFormData[key] != "") {
                    let filedNames = validationForm[key]["calculate"];
                    console.log("filedNames", filedNames);

                    formArr.forEach((formField: any) => {
                        formField.fields.forEach((field: any) => {

                            if(!filedNames.includes(field.name)) {
                                return;
                            }

                            if(field.name == "toiletDetailsScope") {
                                let currentFieldValue = changedFormData["toilets-provided-by-the-landlord"];
                                if(currentFieldValue == "NO") {
                                    localValueFields["toiletDetailsScope"] = "Toilet in Scope";
                                    form["toiletDetailsScope"] = "Toilet in Scope";
                                    changedFormData["toiletDetailsScope"] = "Toilet in Scope";
                                } else {
                                    localValueFields["toiletDetailsScope"] = "Toilet not in Scope";
                                    form["toiletDetailsScope"] = "Toilet not in Scope";
                                    changedFormData["toiletDetailsScope"] = "Toilet not in Scope";
                                }
                            }

                            if(field.name == "floorPlanWidth") {
                                if(field.value && currentField != 'carpetArea') {
                                    delete localValueFields["floorPlanWidth"];
                                    return;
                                }

                                let carpetArea = changedFormData["carpetArea"];
                                carpetArea = parseInt(carpetArea);
                                let floorLength = changedFormData["floorPlanLength"];
                                floorLength = parseInt(floorLength);
                                let updateValue = carpetArea / (10.764 * floorLength);
                                
                                if(currentField != "openCeilingOfficeArea" && currentField != "closedCeilingOfficeArea" && currentField != "corridorLength" && currentField != "floorPlanWidth" && currentField != "falseFloorOfficeArea") {
                                    localValueFields["floorPlanWidth"] = Math.round(updateValue);
                                    form["floorPlanWidth"] = Math.round(updateValue);
                                } else {
                                    delete localValueFields["floorPlanWidth"];
                                }
                            }
                            
                            if(field.name == "corridorLength") {
                                if(field.value && currentField != 'builtUpArea') {
                                    delete localValueFields["corridorLength"];
                                    return;
                                }

                                let currentFieldValue = changedFormData["builtUpArea"];
                                currentFieldValue = parseInt(currentFieldValue);
                                let updateValue = 0;
                                if(currentFieldValue > 1 && currentFieldValue <= 20000) {
                                    updateValue = 75;
                                } else if(currentFieldValue > 20000 && currentFieldValue <= 65000) {
                                    updateValue = 220;
                                } else if(currentFieldValue > 65000 && currentFieldValue <= 125000) {
                                    updateValue = 355;
                                }
                                if(currentField != "openCeilingOfficeArea" && currentField != "closedCeilingOfficeArea" && currentField != "corridorLength" && currentField != "floorPlanWidth" && currentField != "falseFloorOfficeArea") {
                                    localValueFields["corridorLength"] = updateValue;
                                    form["corridorLength"] = updateValue;
                                } else {
                                    delete localValueFields["corridorLength"];
                                }
                            }

                            if((field.name == "openCeilingOfficeArea" || field.name == "closedCeilingOfficeArea") && changedFormData["carpetArea"]) {
                                if(field.value && currentField != 'typeOfWorkHallCeiling') {
                                    delete localValueFields["openCeilingOfficeArea"];
                                    delete localValueFields["closedCeilingOfficeArea"];
                                    return;
                                }

                                let updateValue = 0;
                                let carpetArea = changedFormData["carpetArea"];
                                carpetArea = parseInt(carpetArea);
                                let tWHCeiling = changedFormData["typeOfWorkHallCeiling"];
                                
                                if(tWHCeiling == "Closed Ceiling") {
                                    updateValue = (carpetArea * 20)/100;
                                } else if(tWHCeiling == "Partially open and closed ceiling") {
                                    updateValue = (carpetArea * 50)/100;
                                } else {
                                    updateValue = (carpetArea * 70)/100;
                                }

                                let closeUpdateValue = (carpetArea/10.764) - (updateValue/10.764);
                                if(currentField != "openCeilingOfficeArea" && currentField != "closedCeilingOfficeArea" && currentField != "corridorLength" && currentField != "floorPlanWidth" && currentField != "falseFloorOfficeArea") {
                                    localValueFields["openCeilingOfficeArea"] = (updateValue/10.764).toFixed(2);
                                    form["openCeilingOfficeArea"] = (updateValue/10.764).toFixed(2);
                                } else {
                                    delete localValueFields["openCeilingOfficeArea"];
                                }
                                if(currentField != "openCeilingOfficeArea" && currentField != "closedCeilingOfficeArea" && currentField != "corridorLength" && currentField != "floorPlanWidth" && currentField != "falseFloorOfficeArea") {
                                    localValueFields["closedCeilingOfficeArea"] = (closeUpdateValue).toFixed(2);
                                    form["closedCeilingOfficeArea"] = (closeUpdateValue).toFixed(2);
                                } else {
                                    delete localValueFields["closedCeilingOfficeArea"];
                                }
                            }


                            if(field.name == "falseFloorOfficeArea" && (changedFormData["typeOfBaseFlooring"] || changedFormData["typeOfBaseFlooring"])) {
                                if(field.value && currentField != 'typeOfBaseFlooring') {
                                    delete localValueFields["falseFloorOfficeArea"];
                                    return;
                                }
                                let  updateValue = 0;
                                let typeOFBaseFloor = changedFormData["typeOfBaseFlooring"];
                                let carpetArea = changedFormData["carpetArea"];
                                carpetArea = parseInt(carpetArea);

                                if(typeOFBaseFloor == "False floor across office Bare cement finish tiles (upto 150 mm height Below carpet area & Cement screeding for Hard floor area)") {
                                    updateValue = ((carpetArea * 90)/100)/10.764;
                                } else {
                                    updateValue = ((carpetArea * 5)/100)/10.764;
                                }

                                if(currentField != "openCeilingOfficeArea" && currentField != "closedCeilingOfficeArea" && currentField != "corridorLength" && currentField != "floorPlanWidth" && currentField != "falseFloorOfficeArea") {
                                    localValueFields["falseFloorOfficeArea"] = Math.round(updateValue);
                                    form["falseFloorOfficeArea"] = Math.round(updateValue);
                                } else {
                                    delete localValueFields["falseFloorOfficeArea"];
                                }

                            }

                            if(field.name == "downLightsProposedArea") {
                                console.log("Will change");
                                if(field.value && currentField != 'builtUpArea') {
                                    delete localValueFields["downLightsProposedArea"];
                                    return;
                                }

                                let currentFieldValue = changedFormData["builtUpArea"];
                                currentFieldValue = parseInt(currentFieldValue);

                                let updateValue = (((currentFieldValue * 35)/100)/10.76).toFixed(2);

                                if(currentField != "openCeilingOfficeArea" && currentField != "closedCeilingOfficeArea" && currentField != "corridorLength" && currentField != "floorPlanWidth" && currentField != "falseFloorOfficeArea" && currentField != "downLightsProposedArea") {
                                    localValueFields["downLightsProposedArea"] = updateValue;
                                    form["downLightsProposedArea"] = updateValue;
                                } else {
                                    delete localValueFields["downLightsProposedArea"];
                                }
                                console.log("Will change value "+updateValue);
                            }

                            /*if(field.name == "toilets-provided-by-the-landlord") {
                                let currentFieldValue = changedFormData["toiletDetailsScope"];
                                if(currentFieldValue == "Toilet in Scope") {
                                    localValueFields["toilets-provided-by-the-landlord"] = "NO";
                                    form["toilets-provided-by-the-landlord"] = "NO";
                                    changedFormData["toilets-provided-by-the-landlord"] = "NO";
                                } else {
                                    localValueFields["toilets-provided-by-the-landlord"] = "YES";
                                    form["toilets-provided-by-the-landlord"] = "YES";
                                    changedFormData["toilets-provided-by-the-landlord"] = "YES";
                                }
                            }*/
                        });
                    });
                }
            }

            if(validationForm != undefined && validationForm.hasOwnProperty(key) != undefined && validationForm[key].hasOwnProperty("disableField") && validationForm[key]["disableField"]) {
                let checkValue = validationForm[key]["disableOnValue"];
                let filedName = validationForm[key]["disableField"];
                
                // console.log("checkValue", checkValue);
                // console.log("filedName", filedName);
                if(changedFormData[key] == checkValue) {
                    if(Array.isArray(filedName)) {
                        filedName.map((fld:any) => {
                            updatedDisabledFields[fld] = true;
                        });
                    } else {
                        updatedDisabledFields[filedName] = true;
                    }
                } 
            }
            //console.log(changedFormData);
        });


        Object.keys(form).forEach((key) => {
            if (form[key] !== initialForm[key]) {
                setChangedFields((prev: any) => ({ ...prev, [key]: form[key] }));
                if(props.updateFieldChange) {
                    props.updateFieldChange(true);
                }
            } else {
                setChangedFields((prev: any) => {
                    const { [key]: _, ...rest } = prev; // Remove the key if it's reverted back to initial
                    return rest;
                });
            }
        });
        
        console.log("Form", form);
        setFormData(form);
        setValueFields(localValueFields);
        setDisableFields(updatedDisabledFields);
        props.handleFormChange(form);
    };

    const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const completeFormData = { ...initialForm, ...changedFields };
        props.onSubmit(completeFormData, () => setFormData(initialForm));
    };

    const handleClickButton = (type: string, event: any) => {
        if (type !== 'submit' && type !== 'continue') {
            props.onButtonClick(type, formData, () => setFormData(initialForm));
        } else if(type === 'continue') {
            event.preventDefault();
            const completeFormData = { ...initialForm, ...changedFields };
            props.onSubmit(completeFormData, () => setFormData(initialForm));
        }
    };
    const hideAlertError = (value:any, tabValue:any) => {
        setAlertError(false);
    }

    return (
        <>
            <ECatalogDialog
                            open={alertError}
                            onClose={hideAlertError}
                            confirmText={alertErrorMessage}
                            nextTabValue={""}
                          />
            <Box component={"form"} onSubmit={onSubmitHandler}>
                {group !== undefined && group === true && (
                    <Fields formArr={formArr} initialForm={initialForm} onFormDataChange={onFormDataChange} group={group} disableFields={disableFields} valueFields={valueFields}/>
                )}
                {(group === undefined || group === false) && (
                    <Fields formArr={formArr} initialForm={initialForm} onFormDataChange={onFormDataChange} group={group} disableFields={disableFields} valueFields={valueFields}/>
                )}
                <Box sx={{ pt: 4 }}
                    ref={props.buttonRef}
                >
                    {buttons.map((button: any, index: number) => {
                        if (button?.permissions && hasAccess(button.permissions)) {
                            return (
                                <Button
                                    disabled={props.saveDisabled ? (button?.disabledButton ? true : false) : false}
                                    key={"form-button-" + index}
                                    type={button.type === 'submit' ? button.type : "button"}
                                    variant={button?.variant ? button.variant : 'contained'}
                                    sx={{ backgroundColor: button?.color ? button.color : 'primary.main', mr: 2}}
                                    onClick={(e) => handleClickButton(button.type, e)}
                                >
                                    {button.title}
                                </Button>
                            );
                        }
                    })}
                </Box>
            </Box>
        </>
    );
};

export default BuildForm;
