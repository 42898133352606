import {getStyle} from "../../../utils/ModalUtils";
import {useAppDispatch} from "../../../app/hooks";
import React, {useEffect, useMemo, useState} from "react";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {ERROR_COLOR} from "../../../constants";
import {debounce} from "@mui/material/utils";
import {api} from "../../../utils/Axios";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../Loader";
import ListPage from "../../list-page/ListPage";
import {publishPackageConfig} from "../../../modules/project/plan/config/PublishPackage";
import CreateEventPopup from "./CreateEventPopup";
import {getPriceInFormatted} from "../../../utils/Common";
import {Link} from "react-router-dom";

interface Vendor {
    id: string;
    name: string;
    city: string;
}

const PublishPackage = (props: any) => {
    const style = getStyle(75, 80, 10, 10);

    const dispatch = useAppDispatch();
    const [size, setSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(1);
    const [open, setOpen] = useState(false);
    const [page, pageChange] = useState(0);
    const [gsvAmount, setGsvAmount] = useState(0);
    const [cogsAmount, setCogsAmount] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [defaultVendorId, setDefaultVendorId] = useState("");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [radioValue, setRadioValue] = useState("");
    const [selectedVendor, setSelectedVendor] = useState<any>({});
    const [vendors, setVendors] = useState([]);
    const [vendorRates, setVendorRates] = useState([]);
    const [packageDetails, setPackageDetails] = useState<any>({});
    const [selectedVendors, setSelectedVendors] = useState(
        vendors ? [vendors] : []
    );
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<readonly Vendor[]>([]);
    const [value, setValue] = useState<Vendor | null>(null);
    const [openEventPopup, setOpenEventPopup] = useState(false);

    const fetchVendors = useMemo(
        () =>
            debounce(
                (query: string, callback: (results?: readonly Vendor[]) => void) => {
                    api
                        .get(`/procurement/vendor/search?query=${query}`)
                        .then((response) => response.data)
                        .then((data) => callback(data))
                        .catch(() => callback([]));
                },
                400
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }
        if (inputValue.length >= 3) {
            fetchVendors(inputValue, (results?: readonly Vendor[]) => {
                if (active) {
                    let newOptions: readonly Vendor[] = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchVendors]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    const handleVendorChange = (event: SelectChangeEvent) => {
        setDefaultVendorId(event.target.value);
    };

    const handleDirectPr = () => {
        if (selectedVendors.length > 0) {
            let directPrVendor: any = selectedVendors[0];
            setIsLoading(true);
            api.get(
                `/procurement/package/create-direct-pr/vendor/${props.package.id}/${directPrVendor.id}`
            ).then((response) => response.data).then((response: any) => {
                props.handlePopupCallBack({event: "close", prPackage: "created"});
            }).catch((ex) => {
                dispatch(
                    openSnackbar({
                        message: ex.response?.data || "Oops. Something went wrong",
                        backgroundColor: ERROR_COLOR,
                    })
                );
                console.log(ex);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const handleCreatePr = () => {
        if (Object.keys(selectedVendor).length > 0) {
            setIsLoading(true);
            api
                .get(
                    `/procurement/package/create-pr/${selectedVendor.type}/${props.package.id}/${selectedVendor.id}`
                )
                .then((response) => response.data)
                .then((response: any) => {
                    if (response) {
                        props.handlePopupCallBack({event: "close", prPackage: "created"});
                        setIsLoading(false);
                    }
                })
                .catch((ex) => {
                    dispatch(
                        openSnackbar({
                            message: ex.response?.data || "Oops. Something went wrong",
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                    console.log(ex);
                    setIsLoading(false);
                });
        }
    };

    const handleCreateEvent = () => {
        setOpenEventPopup(true);
    }

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallBack({event: "close"});
    };

    const loadPackageDetails = () => {
        setIsLoading(true);
        api
            .get(`/procurement/package/${props.package.id}`)
            .then((response) => response.data)
            .then((response: any) => {
                setPackageDetails(response);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    const loadVendorRates = () => {
        setIsLoading(true);
        let formData: any = {
            skuIds: packageDetails.items.map((item: any) => item.itemDetail.skuId),
            city: props.project.projectStudio,
            region: props.project.location,
            country: props.project.country,
            entityId: selectedVendor.id
        };

        api
            .post(`/catalogue/sku/entity-rates`, formData)
            .then((response) => response.data)
            .then((response: any) => {
                setVendorRates(response);
            })
            .catch((ex) => {
                console.log(ex);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const loadVendors = () => {
        setIsLoading(true);
        api
            .get(`/procurement/package/entity-details/${props.package.id}`)
            .then((response) => response.data)
            .then((response: any) => {
                setVendors(response);
                if (props.package.type != null && props.package.type == "auto" && props.package?.autoType != null && props.package.autoType == "brand") {
                    setRadioValue("create-direct-pr")
                } else if (!props.package.event && response && response.length > 0) {
                    setRadioValue("create-pr")
                } else {
                    setRadioValue("create-event")
                }
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (vendors.length > 0) {
            const currentVendor = vendors.filter((vendor: any) => defaultVendorId == vendor.id)
                .reduce((acc, curr) => {
                    return curr;
                }, {});
            setSelectedVendor(currentVendor);
        }
    }, [defaultVendorId, vendors]);

    useEffect(() => {
        setSelectedVendors([]);
        setValue(null);
    }, [radioValue]);

    useEffect(() => {
        setVendorRates([]);
        setGsvAmount(0);
        setCogsAmount(0);
        if (selectedVendor.hasOwnProperty("id") && packageDetails.hasOwnProperty("id") && props.package?.autoExecutedVendorId != selectedVendor.id) {
            loadVendorRates();
        }
    }, [selectedVendor]);

    useEffect(() => {
        if (props.package.type == "auto" && props.package?.autoExecutedVendorId != null) {
            setDefaultVendorId(props.package?.autoExecutedVendorId);
        }
    }, [props.package]);

    useEffect(() => {
        if (Object.keys(packageDetails).length > 0) {
            let totalGsvAmount = 0;
            let totalCogsAmount = 0;
            packageDetails.items.map((item: any) => {
                let gsv = item?.gsv > 0 ? item?.gsv + item?.domainGsvCharges?.total : 0;
                let cogs = item?.cogsPrice > 0 ? item?.cogsPrice + item?.domainCogsCharges.total : 0;
                if (vendorRates.length > 0) {
                    vendorRates.map((vendorRate: any) => {
                        if (vendorRate.skuId == item.itemDetail.skuId) {
                            gsv = vendorRate.sellingPrice + item?.domainGsvCharges?.total;
                            cogs = vendorRate.rcPrice + item?.domainGsvCharges?.total;
                        }
                    });
                }
                totalGsvAmount += (gsv * item.totalQuantity)
                totalCogsAmount += (cogs * item.totalQuantity)
            });
            setGsvAmount(totalGsvAmount);
            setCogsAmount(totalCogsAmount);
        }
    }, [packageDetails, vendorRates]);

    useEffect(() => {
        setSelectedVendors(vendors ? vendors : []);
    }, [vendors]);

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
            loadVendors();
            loadPackageDetails();
        }
    }, [props.openPopup]);


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Publish Package - {packageDetails.name}
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            {isLoading &&
                                <Loader/>
                            }
                            <Box>
                                {Object.keys(packageDetails).length > 0 && (
                                    <ListPage
                                        rows={[{...packageDetails, country: props.project.country}]}
                                        columns={publishPackageConfig}
                                        page={page}
                                        size={size}
                                        totalElements={totalElements}
                                        sortColumn={sortColumn}
                                        sortDirection={sortDirection}
                                        handlePagination={handlePagination}
                                        handleSort={handleSort}
                                        hideFooter
                                    />
                                )}
                            </Box>
                            <Box padding={"16px 4px"}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={radioValue}
                                        onChange={handleRadioChange}
                                    >
                                        {packageDetails.type == "auto" && packageDetails.autoType == "brand" ? (
                                            <FormControlLabel
                                                value="create-direct-pr"
                                                control={<Radio/>}
                                                label="Create Direct PR"
                                            />
                                        ) : (
                                            <>
                                                {!packageDetails.event && vendors && vendors.length > 0 &&
                                                    <FormControlLabel
                                                        sx={{mr: 6}}
                                                        value="create-pr"
                                                        control={<Radio/>}
                                                        label="Create PR"
                                                    />}
                                            </>
                                        )}
                                        <FormControlLabel
                                            value="create-event"
                                            control={<Radio/>}
                                            label="Create Event"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box>
                                {radioValue === "create-pr" ? (
                                    <Box sx={{maxWidth: '250px', width: '100%'}}>
                                        <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                            Select Vendor
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name={"entity"}
                                            displayEmpty
                                            onChange={handleVendorChange}
                                            style={{height: '40px'}}
                                            defaultValue={defaultVendorId}
                                        >
                                            {vendors.length > 0 && vendors.map((vendor: Vendor, index: number) => (
                                                <MenuItem key={index} value={vendor.id}>
                                                    {vendor.name + " (" + vendor?.city + ")"}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                ) : radioValue == "create-direct-pr" ? (
                                    <Box sx={{maxWidth: '250px', width: '100%'}}>
                                        <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                            Select Vendor (Auto selected for Direct PR)
                                        </InputLabel>
                                        <Autocomplete
                                            id="vendor-autocomplete"
                                            sx={{width: 300}}
                                            getOptionLabel={(option: Vendor) => option.name}
                                            filterOptions={(x) => x}
                                            options={options}
                                            autoComplete
                                            includeInputInList
                                            filterSelectedOptions
                                            value={value}
                                            noOptionsText="No vendors"
                                            onChange={(event: any, newValue: any) => {
                                                if (newValue) {
                                                    // Check if the selected vendor already exists in the vendors list
                                                    if (
                                                        !selectedVendors.some(
                                                            (vendor: any) =>
                                                                vendor.name === newValue.name
                                                        )
                                                    ) {
                                                        setSelectedVendors([newValue]);
                                                    }
                                                }
                                                setValue(newValue);
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select a vendor"
                                                    fullWidth
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <Box sx={{fontWeight: "regular"}}>
                                                        {option.name}
                                                    </Box>
                                                </li>
                                            )}
                                        />
                                    </Box>
                                ) : (
                                    <Typography sx={{color: '#9D9D9D', fontSize: '12px'}}>
                                        Note : You will be taken to new page to add details
                                    </Typography>
                                )}
                            </Box>
                            <Box>
                                <Typography sx={{pt: 2, pb: 1}} variant={"h5"}>
                                    Package Item Details
                                </Typography>
                                <Grid container sx={{
                                    border: 1,
                                    borderColor: "divider",
                                    backgroundColor: "info.contrastText",
                                    fontWeight: '700'
                                }}>
                                    <Grid item xs={0.6} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>Sr.
                                        No</Grid>
                                    <Grid item xs={1.5} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>Item
                                        Category</Grid>
                                    <Grid item xs={1.5} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>Sku
                                        Name</Grid>
                                    <Grid item xs={1.4}
                                          sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>Specifications</Grid>
                                    <Grid item xs={1}
                                          sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>UOM</Grid>
                                    <Grid item xs={1}
                                          sx={{py: 2, textAlign: "center", borderLeft: 1, borderColor: "divider", px: 1}}>Qty</Grid>
                                    <Grid item xs={1} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>GSV
                                        Rate</Grid>
                                    <Grid item xs={1.5} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>GSV
                                        Amount</Grid>
                                    <Grid item xs={1} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>COGS
                                        Rate</Grid>
                                    <Grid item xs={1.5} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>COGS
                                        Amount</Grid>
                                </Grid>
                                {Object.keys(packageDetails).length > 0 && packageDetails.items.map((item: any, index: number) => {
                                    let gsv = item?.gsv > 0 ? item?.gsv + item?.domainGsvCharges?.total : 0;
                                    let cogs = item?.cogsPrice > 0 ? item?.cogsPrice + item?.domainCogsCharges.total : 0;
                                    if (vendorRates.length > 0) {
                                        vendorRates.map((vendorRate: any) => {
                                            if (vendorRate.skuId == item.itemDetail.skuId) {
                                                gsv = vendorRate.sellingPrice + item?.domainGsvCharges?.total;
                                                cogs = vendorRate.rcPrice + item?.domainGsvCharges?.total;
                                            }
                                        });
                                    }
                                    return (
                                        <Grid container sx={{border: 1, borderTop: 0, borderColor: "divider"}}
                                              alignItems={"stretch"} key={"item-list-" + item.id}>
                                            <Grid item xs={0.6} sx={{py: 2, px: 1, textAlign: 'center'}}>
                                                {index + 1}
                                            </Grid>
                                            <Grid item xs={1.5}
                                                  sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                {item?.itemDetail?.itemCategory.name}
                                            </Grid>
                                            <Grid item xs={1.5}
                                                  sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                <Link to={`/catalogue/skus/details/${item?.itemDetail?.skuId}`}
                                                      target={"_blank"}>
                                                    {item.itemDetail.sku.name}
                                                </Link>
                                            </Grid>
                                            <Grid item xs={1.4}
                                                  sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                {item.itemDetail?.sku.options != null && item.itemDetail?.sku.options.map((option: any, idx: number) => (
                                                    <Typography key={idx}
                                                                variant={"body2"}
                                                                sx={{
                                                                    fontSize: "0.8rem",
                                                                }}>
                                                        <strong>{option.name}:</strong> {option.value}
                                                    </Typography>
                                                ))}
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                {item.itemDetail.sku.uom}
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1, textAlign: "center"}}>
                                                {item.totalQuantity}
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                {getPriceInFormatted(gsv, props.project?.country)}
                                            </Grid>
                                            <Grid item xs={1.5}
                                                  sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                {getPriceInFormatted(gsv * item.totalQuantity, props.project?.country)}
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                {getPriceInFormatted(cogs, props.project?.country)}
                                            </Grid>
                                            <Grid item xs={1.5}
                                                  sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                {getPriceInFormatted(cogs * item.totalQuantity, props.project?.country)}
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <Grid container sx={{
                                    border: 1,
                                    borderTop: 0,
                                    borderColor: "divider",
                                    backgroundColor: "info.contrastText",
                                    fontWeight: '700'
                                }}>
                                    <Grid item xs={7} sx={{py: 2, px: 1}}>Overall Total</Grid>
                                    <Grid item xs={2.5}
                                          sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider", textAlign: 'right'}}>
                                        {getPriceInFormatted(gsvAmount, props.project.country)}
                                    </Grid>
                                    <Grid item xs={2.5}
                                          sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider", textAlign: 'right'}}>
                                        {getPriceInFormatted(cogsAmount, props.project.country)}
                                    </Grid>
                                    <Grid item xs={7}
                                          sx={{py: 2, px: 1, borderTop: 1, borderColor: "divider",}}>NSV</Grid>
                                    <Grid item xs={5}
                                          sx={{py: 2, px: 1, borderTop: 1, borderLeft: 1, borderColor: "divider", textAlign: 'right'}}>
                                        {getPriceInFormatted(gsvAmount - cogsAmount, props.project.country)}
                                    </Grid>
                                    <Grid item xs={7}
                                          sx={{py: 2, px: 1, borderTop: 1, borderColor: "divider",}}>NSV%</Grid>
                                    <Grid item xs={5}
                                          sx={{py: 2, px: 1, borderTop: 1, borderLeft: 1, borderColor: "divider", textAlign: 'right'}}>
                                        {gsvAmount > 0 ? ((gsvAmount - cogsAmount) / gsvAmount * 100).toFixed(2) + "%" : "NA"}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={style.footer}>
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                            <Grid item>
                                <Box>
                                    <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}} onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        sx={{fontSize: "0.8rem"}}
                                        onClick={
                                            radioValue === "create-pr"
                                                ? handleCreatePr
                                                : radioValue == "create-direct-pr" ? handleDirectPr : handleCreateEvent
                                        }
                                    >
                                        {radioValue === "create-pr" ? "Create PR" : radioValue == "create-direct-pr" ? "Create Direct PR" : "Create Event"}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {openEventPopup && (
                    <CreateEventPopup
                        handlePopupCallBack={props.handlePopupCallBack}
                        openPopup={openEventPopup}
                        type={"add"}
                        package={packageDetails}
                        project={props.project}
                    />
                )}
            </Box>
        </Modal>
    );
}

export default PublishPackage;