// actions.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../../utils/Axios";

interface EventDetailsItem {
  id: string | number;
  name: string;
}

interface ApiResponse {
  id: number | string;
  eventPackage: {
    name: string;
    items: EventDetailsItem[]; // Make sure 'items' property is defined
  };
  eventVendors: any;
  totalElements: number;
  name: string;
}


export const loadEventDetails = createAsyncThunk<ApiResponse, string>(
  "events/loadEventDetails",
  async (id: string) => {
    try {
      const response = await api.get(`/procurement/event/${id}`);
      return response.data as ApiResponse;
    } catch (error: any) {
      throw Error(error.message || "Failed to fetch package details");
    }
  }
);


export const getAcceptBid = createAsyncThunk<ApiResponse, any>(
  "event/accept-bid/all",
  async (bidValues: any) => {
    try {
      const response = await api.get(`/procurement/event/accept-bid/all/${bidValues.eventVendorId}/${bidValues.revisionId}`);
      return response.data as ApiResponse;
    } catch (error: any) {
      throw Error(error.message || "Failed to fetch package details");
    }
  }
);


export const postBreakBidsValues = createAsyncThunk<ApiResponse, any>(
  "event/break-bids",
  async (selectedValues: any) => {
    try {
      const response = await api.post('/procurement/event/accept-bid/partial', selectedValues);
      return response.data as ApiResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);