import {Typography} from "@mui/material";
import {getFormattedDate} from "../../../utils/Common";

export const pageConfig = {
    columns: [
        {field: 'id', headerName: 'ID', headerClassName: 'data-grid-header', flex: 1},
        {field: 'name', headerName: 'Name', headerClassName: 'data-grid-header', flex: 1, maxWidth: 200},
        {
            field: "stage", headerName: "Current Stage", headerClassName: "data-grid-header", maxWidth: 150, flex: 1,
            renderCell: (params: any) => {
                let color = '253, 182, 122';
                let name = params.row.stage.toLowerCase();
                if (params.row.stage == 'created') {
                    color = '223, 149, 113';
                }
                if (params.row.stage == 'details') {
                    color = '223, 112, 113';
                }
                if (params.row.stage == 'estimation') {
                    color = '223, 149, 149';
                }
                if (params.row.stage == 'package') {
                    color = '55, 214, 112';
                }
                if (params.row.stage == 'boq') {
                    name = "BoQ";
                }
                return (
                    <Typography sx={{
                        textTransform: 'capitalize',
                        border: 1,
                        borderColor: `rgba(${color}, 1)`,
                        borderRadius: 1,
                        p: 0.6,
                        backgroundColor: `rgba(${color}, 0.5)`,
                        minWidth: '100px',
                        textAlign: 'left'
                    }}>{name}</Typography>
                )
            }
        },
        {
            field: 'projectStudio',
            headerName: 'Studio',
            headerClassName: 'data-grid-header',
            flex: 1,
            maxWidth: 150
        },
        {
            field: 'location',
            headerName: 'Project Location',
            headerClassName: 'data-grid-header',
            maxWidth: 150,
            flex: 1,
            renderCell: (params: any) => (
                <Typography sx={{textTransform: 'capitalize'}}>{params.row?.location}</Typography>)
        },
        {
            field: 'carpetArea',
            headerName: 'Carpet Area',
            headerClassName: 'data-grid-header',
            flex: 1,
            maxWidth: 180
        },
        {
            field: 'estimationType',
            headerName: 'Method',
            headerClassName: 'data-grid-header',
            flex: 1,
            maxWidth: 150,
            renderCell: (params: any) => {
                let methodName = params.row.estimationType;
                if (params.row.estimationType != "" || params.row.estimationType != null) {
                    if (params.row.estimationType == 'scratch') {
                        methodName = "Create From Scratch";
                    } else if (params.row.estimationType == 'clone') {
                        methodName = "Clone BoQ";
                    } else if (params.row.estimationType == 'engine') {
                        methodName = "Generate Estimate"
                    }
                }
                return <Typography sx={{textTransform: 'capitalize'}}>{methodName}</Typography>
            }
        },
        {
            field: 'createdAt',
            headerName: 'Created On',
            headerClassName: 'data-grid-header',
            flex: 1,
            maxWidth: 200,
            valueGetter: (params: any) => getFormattedDate(params.row?.createdAt)
        },
        {
            field: 'createdBy.name',
            headerName: 'Created By',
            headerClassName: 'data-grid-header',
            flex: 1,
            maxWidth: 200,
            valueGetter: (params: any) => params.row?.createdBy?.name
        },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'data-grid-header data-grid-right-aligned-header',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            show: "button",
            buttons: [
                {
                    type: 'details',
                    title: 'View Details',
                    permissions: ['edit-projects', "edit-estimation"],
                    condition: (row: any) => true
                },
            ]
        }
    ]
};