import ConfirmPopupAction from "../../common/ConfirmPopupAction";
import React, {useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import {Box, Button, Checkbox, Grid, Modal, Typography} from "@mui/material";
import PageHeading from "../../page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../Loader";
import {getStyle} from "../../../utils/ModalUtils";
import {Link} from "react-router-dom";
import {getPriceInFormatted} from "../../../utils/Common";

const PublishEvent = (props: any) => {
    const {eventDetails} = props;
    console.log(eventDetails);
    const style = getStyle(80, 85, 10, 10);
    const [isLoading, setIsLoading] = useState(false);
    const [openModalPopup, setOpenModalPopup] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedItemIds, setSelectedItemIds] = useState<any>([]);

    const handleItemSelection = (e: any, item: any) => {
        const selected = e.target.checked;
        if (selected) {
            setSelectedItemIds((prevItemId: any) => [...prevItemId, item.id]);
        } else {
            setSelectedItemIds((prevItemId: any) =>
                prevItemId.filter((id: any) => id !== item.id)
            );
        }
    }
    const handleConfirmPublish = (flag: any) => {
        if (flag == 1) {
            publishEvent();
        }
        setOpenModalPopup(false);
    }

    const handlePublishEvent = () => {
        setOpenModalPopup(true);
    }

    const handleClose = () => {
        setOpenPopup(false);
        props.handleCallBack({event: 'close'});
    }

    const publishEvent = () => {
        setIsLoading(true);
        const form = {
            ids: selectedItemIds
        }
        api.post(
            `/procurement/event/publish/${eventDetails.id}`, form)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                if (res) {
                    props.handleCallBack({event: 'publish'});
                }
            })
            .catch((ex) => {
                console.log(ex);
            }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (props.openPopup) {
            setOpenPopup(true);
        }
    }, [props.openPopup]);

    return (
        <>
            <Modal
                open={openPopup}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style.main}>
                    <Box sx={{position: "relative", height: "100%"}}>
                        <Box sx={style.header}>
                            <Box sx={style.headerContainer}>
                                <Box sx={{width: '95%'}}>
                                    <PageHeading
                                        title={`Event Details: ${eventDetails.name}`}
                                        showButtons={false}
                                    />
                                </Box>
                                <Box onClick={handleClose}
                                     sx={{display: 'flex', justifyContent: 'right', width: '5%', cursor: 'pointer'}}>
                                    <CloseIcon/>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={"modal-body"} sx={style.body}>
                            <Box sx={style.bodyContainer}>
                                {isLoading &&
                                    <Loader/>
                                }
                                <Box>
                                    {eventDetails.eventPackages.map((eventPackage: any) => (
                                        <Box key={"publish-package-" + eventPackage.id}>
                                            <Typography variant={"h4"} sx={{pb: 1}}>{"Package: " + eventPackage?.name}</Typography>
                                            <Grid container sx={{
                                                border: 1,
                                                borderColor: "divider",
                                                backgroundColor: "info.contrastText",
                                                fontWeight: '700'
                                            }}>
                                                <Grid item xs={1} sx={{py: 2, px: 1}}>Show Rate</Grid>
                                                <Grid item xs={1.5} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>Sku
                                                    Name</Grid>
                                                <Grid item xs={1.5}
                                                      sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>Specifications</Grid>
                                                <Grid item xs={1}
                                                      sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>UOM</Grid>
                                                <Grid item xs={1} sx={{py: 2, textAlign: "center", borderLeft: 1, borderColor: "divider", px: 1}}>Qty</Grid>
                                                <Grid item xs={1.5} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>GSV Rate</Grid>
                                                <Grid item xs={1.5} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>GSV Amount</Grid>
                                                <Grid item xs={1.5} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>COGS Rate</Grid>
                                                <Grid item xs={1.5} sx={{py: 2, px: 1, borderLeft: 1, borderColor: "divider"}}>COGS Amount</Grid>
                                            </Grid>
                                            {eventPackage.items.map((item: any) => {
                                                let gsv = item?.gsv > 0 ? item?.gsv + item?.domainGsvCharges?.total : 0;
                                                let cogs = item?.cogsPrice > 0 ? item?.cogsPrice + item?.domainCogsCharges.total : 0;

                                                return (
                                                    <Grid container sx={{border: 1, borderTop: 0, borderColor: "divider"}}
                                                          alignItems={"stretch"} key={"item-list-" + item.id}>
                                                        <Grid item xs={1} sx={{py: 2, px: 1, textAlign: 'center'}}>
                                                            <Checkbox
                                                                color={"info"}
                                                                checked={selectedItemIds.includes(item.id)}
                                                                onChange={(e: any) => handleItemSelection(e, item)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.5}
                                                              sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                            <Link to={`/catalogue/skus/details/${item?.itemDetail?.skuId}`}
                                                                  target={"_blank"}>
                                                                {item.itemDetail.sku.name}
                                                            </Link>
                                                        </Grid>
                                                        <Grid item xs={1.5}
                                                              sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                            {item.itemDetail?.sku.options != null && item.itemDetail?.sku.options.map((option: any, idx: number) => (
                                                                <Typography key={idx}
                                                                            variant={"body2"}
                                                                            sx={{
                                                                                fontSize: "0.8rem",
                                                                            }}>
                                                                    <strong>{option.name}:</strong> {option.value}
                                                                </Typography>
                                                            ))}
                                                        </Grid>
                                                        <Grid item xs={1}
                                                              sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                            {item.itemDetail.sku.uom}
                                                        </Grid>
                                                        <Grid item xs={1}
                                                              sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1, textAlign: "center"}}>
                                                            {item.totalQuantity}
                                                        </Grid>
                                                        <Grid item xs={1.5}
                                                              sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                            {getPriceInFormatted(gsv, props.project?.country)}
                                                        </Grid>
                                                        <Grid item xs={1.5}
                                                              sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                            {getPriceInFormatted(gsv * item.totalQuantity, props.project?.country)}
                                                        </Grid>
                                                        <Grid item xs={1.5}
                                                              sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                            {getPriceInFormatted(cogs, props.project?.country)}
                                                        </Grid>
                                                        <Grid item xs={1.5}
                                                              sx={{borderLeft: 1, borderColor: "divider", py: 2, px: 1}}>
                                                            {getPriceInFormatted(cogs * item.totalQuantity, props.project?.country)}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={style.footer}>
                            <Grid
                                container
                                sx={{px: 2, height: "100%"}}
                                className={"center-aligned"}
                            >
                                <Grid item xs/>
                                <Grid item>
                                    <Box>
                                        <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}} onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant={"contained"}
                                            sx={{fontSize: "0.8rem"}}
                                            onClick={handlePublishEvent}
                                        >
                                            Publish Event
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {openModalPopup && (
                <ConfirmPopupAction openConfirmBox={openModalPopup} handleButtonClick={handleConfirmPublish}
                                    confirmText={"Are you sure you want to publish the changes?"}/>
            )}
        </>
    )
}
export default PublishEvent;