import {getFormattedDate, getPriceInFormatted} from "../../../../utils/Common";

export const publishPackageConfig = [
    {
        field: 'name',
        headerName: 'Package Name',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 200
    },
    {
        field: 'createdAt',
        headerName: 'Created On',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        valueGetter: (params: any) => getFormattedDate(params.row?.createdAt)
    },
    {
        field: 'totalGsv',
        headerName: 'Total GSV',
        type: 'price',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        renderCell: (params: any) => {
            return getPriceInFormatted(parseFloat(params.row?.total) + parseFloat(params.row?.domainGsvCharges?.total), params.row?.country)
        }
    }
];
