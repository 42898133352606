import {
    Box,
    Grid,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {getStyle} from "../../../utils/ModalUtils";
import React, {useEffect, useState} from "react";
import PageHeading from "../../page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import {pageConfig} from "../../../modules/project/package/config/PackageDetailsConfig";
import ListPage from "../../list-page/ListPage";
import {api} from "../../../utils/Axios";
import Loader from "../../Loader";
import {approvePackageRequest, loadPackageDetails} from "../../../modules/project/package/features/actions";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {Doughnut} from "react-chartjs-2";
import {getPriceInFormatted} from "../../../utils/Common";
import {getProjectDomainDetails} from "../../../modules/project/features/project-action";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {APPROVE_PR_FAILED, APPROVE_PR_SUCCESS, ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import PublishPackage from "./PublishPackage";

const PackageDetails = (props: any) => {
    const {project} = props;
    const style = getStyle(100, 85, 0, 0);
    const [openPopup, setOpenPopup] = useState(false);
    const [openPublishPopup, setOpenPublishPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [totalElements, setTotalElements] = useState(1);

    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);

    const [costImpact, setCostImpact] = useState<any>([]);
    const [packageItems, setPackageItems] = useState<any>([]);
    const [costImpactTotal, setCostImpactTotal] = useState<any>(0);
    const [chartData, setChartData] = useState<any>({});
    const [chartOptions, setChartOptions] = useState<any>({});
    const backgroundColors: string[] = ["#fbd474", "#A74462", "#048cdc", "#3F3B4E", "#fdb67a", "#5E33FF", "#33FF6B", "#FF5733",];
    const mainText = "Overall";

    const [headerButtons, setHeaderButtons] = useState<any>([]);

    const [packageTotalCogsPlatform, setPackageTotalCogsPlatform] = useState(0);

    const dispatch = useAppDispatch();

    const domainDetails = useAppSelector(
        (state) => state.project.domainDetails
    );

    const packageDetails = useAppSelector(
        (state) => state.packageDetails.packageDetails
    );

    const handleCallBack = () => {
        return;
    }

    const handleClose = () => {
        setOpenPopup(false);
        props.handleCallBack({event: 'close'});
    }

    const revoke = () => {
        setIsLoading(true);
        api.get(`/procurement/package/revoke/${packageDetails.id}`).then((response) => {
            return response.data;
        }).then(res => {
            loadPackage();
            props.handleCallBack({event: 'revoke'});
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const approvePr = async () => {
        try {
            const response = await dispatch(approvePackageRequest(props.aPackage.id));
            if (response.payload) {
                loadPackage();
                dispatch(
                    openSnackbar({
                        message: APPROVE_PR_SUCCESS,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                props.handleApprovePr();
            }
        } catch (err: any) {
            dispatch(
                openSnackbar({
                    message: APPROVE_PR_FAILED,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    }

    const handleHeaderButtonClick = async (data: any) => {
        if (data === "approve-pr") {
            approvePr();
        }
        if (data === "revoke") {
            revoke();
        }
        if (data === "publish") {
            setOpenPublishPopup(true);
        }
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };

    const handlePopupCallBack = (data: any) => {
        setOpenPublishPopup(false);
        if (data.hasOwnProperty("prPackage")) {
            loadPackage();
            props.handlePublishCallback({event: data.prPackage});
        }
        props.callBackReload(data);
    }

    const loadPackage = () => {
        dispatch(
            loadPackageDetails(props.aPackage.id)
        ).then((response: any) => {
            setTotalElements(response.payload?.totalElements);
        });
    }

    const loadProjectDetails = () => {
        setIsLoading(true);
        api
            .get(`/procurement/project/project-details/${project.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                project.projectDetails = res;
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if(Object.keys(project).length > 0) {
            let proposedFloor = 0;
            const updatedData: any[] = [];
            const projectDetails = project.projectDetails;

            if (domainDetails != "" && Object.keys(domainDetails).length > 0 && Object.keys(packageDetails).length > 0) {
                if (projectDetails != null && Object.keys(projectDetails).length > 0) {
                    proposedFloor = Math.max(...projectDetails?.proposedFloors.split(","));
                    if (projectDetails.premiumCharges) {
                        let premiumChargesTotal = (domainDetails?.premiumCharges * packageTotalCogsPlatform) / 100;
                        updatedData.push({
                            name: 'Premium Charges',
                            total: premiumChargesTotal,
                            impactPercentage: domainDetails?.premiumCharges
                        });
                    }
                    if (projectDetails.areaSlabDiscount) {
                        let areaSlabDiscountTotal = (domainDetails?.areaSlabDiscount * packageTotalCogsPlatform) / 100;
                        updatedData.push({
                            name: 'Area Slab Discount',
                            total: -areaSlabDiscountTotal,
                            impactPercentage: domainDetails?.areaSlabDiscount
                        });
                    }
                    if (projectDetails.workingType) {
                        const workingTypePercentage = domainDetails[projectDetails.workingType] || 0;
                        if (workingTypePercentage > 0) {
                            const workingTypeTotal = (workingTypePercentage * packageTotalCogsPlatform) / 100;
                            updatedData.push({
                                name: 'Working Type',
                                total: workingTypeTotal,
                                impactPercentage: workingTypePercentage
                            });
                        }
                    }
                    if (projectDetails.waterAndPower) {
                        const waterAndPowerPercentage = domainDetails[projectDetails.waterAndPower] || 0;
                        if (waterAndPowerPercentage > 0) {
                            const waterAndPowerTotal = (waterAndPowerPercentage * packageTotalCogsPlatform) / 100;
                            updatedData.push({
                                name: 'Water and Power',
                                total: waterAndPowerTotal,
                                impactPercentage: waterAndPowerPercentage
                            });
                        }
                    }
                    if (projectDetails.unionCharges) {
                        domainDetails.unionRegion.map((region: string, index: number) => {
                            if (region == project.location) {
                                let unionChargePercentage = domainDetails.unionCharge[index];
                                let unionTotal = (unionChargePercentage * packageTotalCogsPlatform) / 100;
                                updatedData.push({
                                    name: 'Union Charges',
                                    total: unionTotal,
                                    impactPercentage: unionChargePercentage
                                });
                            }
                        });
                    }
                    if (projectDetails.serviceLift != null && projectDetails.serviceLift == false) {
                        let serviceLiftTotal = (projectDetails.serviceLiftCharges * packageTotalCogsPlatform) / 100;
                        updatedData.push({
                            name: 'Service Lift Charges',
                            total: serviceLiftTotal,
                            impactPercentage: projectDetails.serviceLiftCharges
                        });
                    }

                    if (projectDetails.outstationProjectLocation != null && projectDetails.outstationProjectLocation == true) {
                        let outstationProjectLocationTotal = (projectDetails.outstationProjectLocationAdditionMarkup * packageTotalCogsPlatform) / 100;
                        updatedData.push({
                            name: 'Outstation Project Location Charges',
                            total: outstationProjectLocationTotal,
                            impactPercentage: projectDetails.outstationProjectLocationAdditionMarkup
                        });
                    }
                }
                if (proposedFloor > 0 && domainDetails.floorRegion != null) {
                    domainDetails.floor.map((floor: any, index: number) => {
                        if (floor == proposedFloor) {
                            let floorChargePercentage = domainDetails.floorCharge[index];
                            let floorTotal = (floorChargePercentage * packageTotalCogsPlatform) / 100;
                            updatedData.push({
                                name: 'Floor Charges',
                                total: floorTotal,
                                impactPercentage: floorChargePercentage
                            });
                        }
                    });
                }

                const updatedChartData = updatedData.filter((item: any) => item.name != "Area Slab Discount");
                let overAllTotal = updatedData.reduce((acc: any, item: any) => acc + item.total, 0);
                let chartTotal = updatedChartData.reduce((acc: any, item: any) => acc + item.total, 0);
                setCostImpact(updatedData);
                setCostImpactTotal(overAllTotal);
                setChartData(
                    {
                        labels: updatedChartData.map((chartData: any) => chartData.name),
                        datasets: [{
                            data: updatedChartData.map((chartData: any) => ((chartData.total as number) / (chartTotal as number) * 100).toFixed(1)),
                            backgroundColor: backgroundColors,
                            borderColor: backgroundColors,
                            borderWidth: 0.2
                        }]
                    }
                );
            }
        }
    }, [domainDetails, packageDetails, packageTotalCogsPlatform, project]);

    useEffect(() => {
        let totalCogs = 0;
        const buttons: any[] = [];
        if (packageDetails?.status === "CREATED" && project.status != "COMPLETED") {
            buttons.push(
                {
                    id: "revoke",
                    label: "Revoke",
                    variant: "outlined",
                    permissions: ["edit-projects"],
                },
                {
                    id: "publish",
                    label: "Publish",
                    variant: "contained",
                    permissions: ["edit-projects"],
                }
            );
        }

        if (packageDetails?.status === "PR") {
            buttons.push({
                id: "approve-pr",
                label: "Approve PR",
                variant: "contained",
                color: "primary",
                permissions: ["edit-projects"],
            });
        }

        if (packageDetails.items.length > 0) {
            let items: any[] = [];
            packageDetails.items.map((item: any) => {
                let categoryCode = "";
                props.categories.map((category: any) => {
                    category.subCategories.map((subCategory: any) => {
                        if (subCategory.id == item.itemDetail.itemCategory.materialSubCategoryId) {
                            categoryCode = category.code;
                        }
                    })
                })
                items.push({
                    ...item,
                    showUpdatedCharges: props.showUpdatedCharges,
                    categoryCode: categoryCode.toLowerCase(),
                    country: props.project.country,
                    vendorAssigned: props.revision?.vendorAssigned,
                    packageType: props.aPackage.type,
                    autoType: props.aPackage.autoType
                });
                totalCogs += (parseFloat(item.cogsPrice) + parseFloat(item?.platformDomainCogsCharges?.total)) * parseInt(item.quantity);
            });
            setPackageItems(items);
        }
        setHeaderButtons(buttons);
        setPackageTotalCogsPlatform(totalCogs);
    }, [packageDetails]);

    useEffect(() => {
        if (props.openPopup) {
            if (project.projectDetails == null) {
                loadProjectDetails();
            }
            dispatch(getProjectDomainDetails({type: 'platform', id: project.id}));
            loadPackage();
            setOpenPopup(true);
        }
    }, [props.openPopup]);

    return (
        <>
            <Modal
                disableScrollLock={false}
                open={openPopup}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{
                    "& > .MuiBackdrop-root": {
                        backdropFilter: "blur(0px)",
                        backgroundColor: "transparent",
                    },
                }}
            >
                <Box sx={{
                    position: "absolute" as "absolute",
                    bottom: 0,
                    width: "100%",
                    height: '90%',
                    border: 0,
                    backgroundColor: "background.paper",
                    boxShadow: 1,
                    '&:focus-visible': {
                        outline: 'none'
                    }
                }}>
                    <Box sx={{position: "relative", height: "100%"}}>
                        <Box sx={style.header}>
                            <Box sx={style.headerContainer}>
                                <Box sx={{width: '95%'}}>
                                    <PageHeading
                                        title={"Package: " + packageDetails.name}
                                        showButtons={true}
                                        buttons={headerButtons}
                                        handleHeaderButtonClick={handleHeaderButtonClick}
                                    />
                                </Box>
                                <Box onClick={handleClose}
                                     sx={{display: 'flex', justifyContent: 'right', width: '5%', cursor: 'pointer'}}>
                                    <CloseIcon/>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={"modal-body"} sx={style.modalFullBody}>
                            <Box sx={style.bodyContainer}>
                                {isLoading &&
                                    <Loader/>
                                }
                                <>
                                    <ListPage
                                        rows={[{
                                            ...packageDetails,
                                            total: parseFloat(packageDetails.total + packageDetails?.domainGsvCharges?.total),
                                            id: packageDetails?.id || 0
                                        }]}
                                        columns={packageDetails?.status == "PR" ? pageConfig.prPackageDetail : pageConfig.packageDetail}
                                        page={page}
                                        size={size}
                                        totalElements={totalElements}
                                        sortColumn={sortColumn}
                                        sortDirection={sortDirection}
                                        handlePagination={handlePagination}
                                        handleSort={handleSort}
                                        handleCallBack={handleCallBack}
                                        hideFooter
                                    />

                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                            <Typography sx={{pt: 2, pb: 1}} variant={"h4"}>
                                                Package Item Details
                                            </Typography>
                                        </Box>
                                        <ListPage
                                            rows={packageItems}
                                            columns={pageConfig.packageDetails}
                                            page={page}
                                            size={size}
                                            totalElements={packageItems.length}
                                            sortColumn={sortColumn}
                                            sortDirection={sortDirection}
                                            handlePagination={handlePagination}
                                            handleSort={handleSort}
                                            handleCallBack={handleCallBack}
                                            getRowId={(row: any) => row.id}
                                            hideFooter
                                            rowHeight={100}
                                        />
                                    </Box>
                                    <Box>
                                        {costImpact.length > 0 &&
                                            <Box sx={{pb: 4}}>
                                                <PageHeading title={"Cost Impact"}/>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={4}>
                                                        {Object.keys(chartData).length > 0 &&
                                                            <Box className="chart-container"
                                                                 sx={{position: 'relative', px: 2}}>
                                                                <Typography style={{
                                                                    textAlign: "center",
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: 0,
                                                                    right: 0
                                                                }}>
                                                                    <Typography component={"span"}
                                                                                sx={{
                                                                                    width: '45%',
                                                                                    fontSize: '20px',
                                                                                    fontWeight: 600,
                                                                                    display: 'inline-block',
                                                                                    wordWrap: 'break-word'
                                                                                }}>
                                                                        {mainText}
                                                                    </Typography>
                                                                </Typography>
                                                                <Doughnut
                                                                    id={"sm-doughnut-chart-project"}
                                                                    data={chartData} options={chartOptions}
                                                                />
                                                            </Box>
                                                        }
                                                    </Grid>
                                                    <Grid item xs>
                                                        <TableContainer>
                                                            <Table aria-label="simple table">
                                                                <TableHead
                                                                    sx={{
                                                                        backgroundColor: "info.contrastText",
                                                                        border: 1,
                                                                        borderColor: 'divider'
                                                                    }}>
                                                                    <TableRow>
                                                                        <TableCell
                                                                            sx={{fontSize: "14px", fontWeight: "600"}}
                                                                        >
                                                                            Name
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{fontSize: "14px", fontWeight: "600"}}
                                                                        >
                                                                            Impact Percent
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{fontSize: "14px", fontWeight: "600"}}
                                                                        >
                                                                            Impact Total
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{fontSize: "14px", fontWeight: "600"}}
                                                                        >
                                                                            Total
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {costImpact.map((data: any, index: number) => {
                                                                        return (
                                                                            <TableRow
                                                                                key={"chart-"+index}
                                                                                sx={{
                                                                                    border: 1,
                                                                                    borderColor: 'divider'
                                                                                }}>
                                                                                <TableCell sx={{p: 2}}>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: "15px",
                                                                                            fontWeight: "500"
                                                                                        }}>
                                                                                        {data.name}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell sx={{p: 2}}>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: "15px",
                                                                                            fontWeight: "500"
                                                                                        }}>
                                                                                        {data.impactPercentage}%
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell sx={{p: 2}}>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: "15px",
                                                                                            fontWeight: "500"
                                                                                        }}>
                                                                                        {getPriceInFormatted(data.total, project.country)}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell sx={{p: 2}}>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: "15px",
                                                                                            fontWeight: "500"
                                                                                        }}>
                                                                                        {getPriceInFormatted(parseFloat(packageDetails.total + packageDetails?.domainGsvCharges?.total), project.country)}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                    <TableRow
                                                                        sx={{
                                                                            backgroundColor: "info.contrastText",
                                                                            border: 1,
                                                                            borderColor: 'divider'
                                                                        }}>
                                                                        <TableCell sx={{p: 2}} colSpan={2}>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "15px",
                                                                                    fontWeight: 700
                                                                                }}>
                                                                                Total
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell sx={{p: 2}} colSpan={2}>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "15px",
                                                                                    fontWeight: 700
                                                                                }}>
                                                                                {getPriceInFormatted(costImpactTotal, project.country)}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }
                                    </Box>
                                </>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {openPublishPopup &&
                <PublishPackage
                    handlePopupCallBack={handlePopupCallBack}
                    openPopup={openPublishPopup}
                    package={packageDetails}
                    project={props.project}
                />
            }
        </>
    )
}
export default PackageDetails;