import {Box, Button, Checkbox, FormControlLabel, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {Link} from "react-router-dom";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import React, {useState} from "react";

const EventTermsAndCondition = (props: any) => {
    const [accepted, setAccepted] = useState(false);

    const handleTermCheckbox = (event: any) => {
        const checked = event.target.checked;
        if (checked) {
            setAccepted(true);
        } else {
            setAccepted(false);
        }
    };
    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box sx={{p: 2}}>
                <DialogTitle id="alert-dialog-title">
                    <Typography variant={"h5"} sx={{px: 0.4}}>
                        Accept Terms and Conditions
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{pb: 0}}>
                    <DialogContentText id="alert-dialog-description">
                        <Box>
                            <Typography sx={{lineHeight: 1.24, px: 0.4}}>
                                All documents and information that follow are the sole
                                property of the organiser (Space Matrix), and can not be
                                copied, distributed or used for purposes other than the
                                request or auction where you have been invited. By accepting
                                to participate in the bidding process you confirm to
                                maintain the confidentiality. You also understand and agree
                                that your participation to the request or auction does not
                                commit Space Matrix in anyway.
                            </Typography>
                            <Box sx={{pt: 2}}>
                                <Typography className={"vertically-center-aligned"}>
                                    <ArticleOutlinedIcon color={"primary"}/>
                                    <Link
                                        style={{marginLeft: 10, color: "primary"}}
                                        to={props.termFile}
                                        target={"_blank"}
                                    >
                                        View Terms and Conditions
                                    </Link>
                                </Typography>
                            </Box>

                            <FormControlLabel
                                sx={{px: 0.2}}
                                label={
                                    <Typography sx={{color: "common.black"}}>
                                        I agree to Space Matrix terms of use and privacy policy
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        onChange={(e: any) => handleTermCheckbox(e)}
                                        name={"terms"}
                                        value={1}
                                    />
                                }
                            />
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{px: 3}}>
                    <Button
                        onClick={() => props.handleAccept()}
                        color="primary"
                        variant={"contained"}
                        disabled={!accepted}
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default EventTermsAndCondition;