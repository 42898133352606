import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useAppDispatch} from "../../../app/hooks";
import {postBreakBidsValues} from "./features/actions";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {
    ERROR_COLOR,
    SAVE_BIDS_FAILURE_MESSAGE,
    SAVE_BIDS_SUCCESS_MESSAGE,
    SELECT_ALL_VALUES_ERROR_MESSAGE,
    SUCCESS_COLOR,
} from "../../../constants";
import {getPriceInFormatted} from "../../../utils/Common";
import {getStyle} from "../../../utils/ModalUtils";
import {interpolateColor} from "./utils";

const EventBreakBidsPopup = (props: any) => {
    const {event, eventVendorsList, eventPackageDetails} = props;
    const style = getStyle(90, 85, 0, 0);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [columnSelectedTotals, setColumnSelectedTotals] = useState<number[]>([]);
    const [eventPackages, setEventPackages] = useState<any>([]);
    const [bidVendors, setBidVendors] = useState<any>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [vendorTotal, setVendorTotal] = useState<any>([]);

    useEffect(() => {
        if (props.showBidsPopup === true) {
            setOpen(true);
            if (eventVendorsList.length > 0) {
                let totalItemCount = 0;
                let priceAvailableVendors: any[] = [];
                let vendorPrices: any = {};

                const updatedEventPackageDetails = eventPackageDetails.map((packageDetails: any) => {
                    totalItemCount += packageDetails.status == "EVENT" ? packageDetails.items.length : 0;
                    const items = packageDetails.items.map((item: any) => {
                        let prices: any[] = [];
                        const vendorPriceDetails: any = {};
                        eventVendorsList.map((eventVendor: any) => {
                            const revisionPrices: any[] = [];

                            eventVendor.vendorRevisions.forEach((vendorRevision: any) => {
                                vendorRevision.eventVendorDetails.map((vendorDetails: any) => {
                                    if (vendorDetails.packageItemId == item.id) {
                                        let vendorPrice = vendorDetails.price + vendorDetails.domainCharges.total;
                                        revisionPrices.push({...vendorDetails, price: vendorPrice, vendorRevision: vendorRevision, eventVendorId: eventVendor.id});
                                        if (!priceAvailableVendors.includes(eventVendor.vendor.id)) {
                                            priceAvailableVendors.push(eventVendor.vendor.id);
                                        }

                                        prices.push((vendorPrice));
                                        let total = vendorPrice * item.totalQuantity;

                                        if (vendorPrices.hasOwnProperty(eventVendor.vendorId)) {
                                            vendorPrices[eventVendor.vendorId] = vendorPrices[eventVendor.vendorId] + total;
                                        } else {
                                            vendorPrices[eventVendor.vendorId] = total;
                                        }
                                    }
                                });
                            });
                            if (priceAvailableVendors.includes(eventVendor.vendor.id)) {
                                vendorPriceDetails[eventVendor.id] = revisionPrices;
                            }

                        });
                        let max, min = 0;

                        if (Object.keys(vendorPriceDetails).length > 0) {
                            max = Math.max(...prices);
                            min = Math.min(...prices);
                        }
                        return {
                            ...item, eventVendors: vendorPriceDetails, min, max
                        }
                    })
                    return {
                        ...packageDetails, items: items
                    }
                });

                const columnTotals: any[] = [];
                priceAvailableVendors.map((bidVendor: any) => {
                    columnTotals.push(vendorPrices[bidVendor]);
                });
                setVendorTotal(columnTotals);
                setTotalItems(totalItemCount);
                setBidVendors(priceAvailableVendors);
                setEventPackages(updatedEventPackageDetails);
            }
        }
    }, [props.showBidsPopup]);

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallBack({event: "close"});
        setSelectedValues([]);
    };

    const handleBiddingCheckbox = (e: any, rowIndex: number, vendorEventDetails: any) => {
        setSelectedValues((prevSelectedValues: any[]) => {
            const updatedValues = [...prevSelectedValues];
            updatedValues[rowIndex] = vendorEventDetails.id;
            return updatedValues;
        });
    };

    const handleSaveBreakBids = async () => {
        const finalSelectedValue = selectedValues.filter(Boolean);
        if (finalSelectedValue.length === totalItems) {
            try {
                const response = await dispatch(
                    postBreakBidsValues({
                        eventVendorIds: selectedValues,
                        eventId: event.id,
                    })
                ).unwrap();
                if (response) {
                    dispatch(
                        openSnackbar({
                            message: SAVE_BIDS_SUCCESS_MESSAGE,
                            backgroundColor: SUCCESS_COLOR,
                        })
                    );
                    setOpen(false);
                    setSelectedValues([]);
                    props.handlePopupCallBack({event: "save"});
                }
            } catch (err) {
                console.error("Error", err);
                dispatch(
                    openSnackbar({
                        message: SAVE_BIDS_FAILURE_MESSAGE,
                        backgroundColor: ERROR_COLOR,
                    })
                );
            }
        } else {
            dispatch(
                openSnackbar({
                    message: SELECT_ALL_VALUES_ERROR_MESSAGE,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
        setSelectedValues([]);
    };

    useEffect(() => {
        const totals: number[] = [];
        eventPackages.map((eventPackage: any) => {
            eventPackage.items.map((item: any, rowIndex: number) => {
                let colTotal = 0;
                Object.keys(item.eventVendors).length > 0 && Object.keys(item.eventVendors).map((eventVendorId: any) => {
                    const eventVendors = item.eventVendors[eventVendorId];
                    eventVendors.map((eventVendor: any) => {
                        if (selectedValues.includes(eventVendor.id)) {
                            colTotal += (eventVendor?.price || 0) * (item.totalQuantity || 1);
                        }
                        // selectedValues.forEach((selectedValue: any) => {
                        //     if (selectedValue.eventVendorId == eventVendor.id && selectedValue.revisionId == eventVendor.vendorRevision.id) {
                        //         colTotal += (eventVendor?.price || 0) * (item.totalQuantity || 1);
                        //     }
                        // });
                    });
                });
                totals.push(colTotal);
            });
        });
    }, [selectedValues]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header} className="sticky-header">
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Break Bids
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body} style={{height: '84%'}}>
                        <Box sx={style.bodyContainer}>
                            <TableContainer
                                component={"div"}
                                sx={{border: "1px solid", borderColor: "divider", height: '100%', overflow: 'auto'}}
                            >
                                <Table aria-label="simple table" sx={{tableLayout: 'fixed'}}>
                                    <TableHead
                                        sx={{
                                            backgroundColor: "info.contrastText",
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 10
                                        }}>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: 700, width: 100}} align={"left"}>
                                                Sr. No.
                                            </TableCell>
                                            <TableCell sx={{fontWeight: 700, width: 150}} align={"left"}>
                                                Sku Name
                                            </TableCell>
                                            <TableCell sx={{fontWeight: 700, width: 100}} align={"left"}>
                                                Quantity
                                            </TableCell>
                                            {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                    return (
                                                        <TableCell sx={{fontWeight: 700}}
                                                                   key={"break-bids-popup-event-vendor-name-" + idx}
                                                                   align={"center"}>
                                                            {vendorDetails?.vendor?.name + " (" + vendorDetails?.vendor?.city + ")"}
                                                        </TableCell>
                                                    )
                                                }
                                            })}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={3}/>
                                            {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                    const cells = [];
                                                    for (let i = 1; i <= event.currentRevision; i++) {
                                                        cells.push(
                                                            <TableCell align={"center"}
                                                                       sx={{p: 0, border: 0}}>R{i}</TableCell>
                                                        );
                                                    }
                                                    return (
                                                        <TableCell key={'bid-vendor-names-revision-' + idx}
                                                                   align={"left"}
                                                                   sx={{fontWeight: 500, minWidth: '150px', p: 0, borderRight: 1, borderColor: 'divider'}}>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            {cells}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                </Table>
                                                            </TableContainer>
                                                        </TableCell>
                                                    )
                                                }
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{zIndex: 1}}>
                                        {eventPackages.map((eventPackage: any, packageIndex: number) => {
                                            return (
                                                (eventPackage && eventPackage.status == "EVENT") &&
                                                eventPackage.items.map((packageItem: any, rowIndex: number) => {
                                                    return (
                                                        <TableRow key={rowIndex + "-" + packageItem.id}>
                                                            <TableCell align="left" sx={{width: 100}}>
                                                                {rowIndex + 1}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{width: 200}}>
                                                                {packageItem.itemDetail.sku.name}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{width: 200}}>
                                                                {packageItem.totalQuantity}
                                                            </TableCell>
                                                            {Object.keys(packageItem.eventVendors).map((vendor: any, vendorIndex: number) => {
                                                                const cells: any[] = [];
                                                                const cellsData: any[] = packageItem.eventVendors[vendor];

                                                                for (let i = 1; i <= event.currentRevision; i++) {
                                                                    let cellDetails = "NA"
                                                                    let color = "darkgrey";
                                                                    let currentVendorDetails: any = {};
                                                                    cellsData.forEach((cellData: any) => {
                                                                        if (i == cellData.vendorRevision.revision) {
                                                                            currentVendorDetails = cellData;
                                                                            cellDetails = getPriceInFormatted(cellData.price, event.project.country);
                                                                            color = interpolateColor(packageItem.min, packageItem.max, Number(cellData.price));
                                                                        }
                                                                    });

                                                                    cells.push(
                                                                        <TableCell align={"center"}
                                                                                   sx={{border: 0, p: 0}}>
                                                                            <Typography
                                                                                sx={{color: color, fontWeight: 600, fontSize: '11px'}}>
                                                                                {cellDetails !== "NA" ? (
                                                                                    <>
                                                                                        <FormControlLabel
                                                                                            label={cellDetails}
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    checked={
                                                                                                        selectedValues && selectedValues[rowIndex]
                                                                                                            ? (selectedValues[rowIndex] == currentVendorDetails.id)
                                                                                                            : false
                                                                                                    }
                                                                                                    name={cellDetails}
                                                                                                    value={currentVendorDetails?.id}
                                                                                                    onChange={(e) =>
                                                                                                        handleBiddingCheckbox(
                                                                                                            e,
                                                                                                            rowIndex,
                                                                                                            currentVendorDetails
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                ) : (
                                                                                    <>{cellDetails}</>
                                                                                )}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                return (
                                                                    <TableCell key={vendorIndex + "-" + vendor.id}
                                                                               align="left" sx={{minWidth: 200}}>
                                                                        <TableContainer>
                                                                            <Table>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        {cells}
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    )
                                                })
                                            )
                                        })}
                                    </TableBody>
                                    <TableFooter
                                        sx={{
                                            position: 'sticky',
                                            bottom: -1,
                                            backgroundColor: 'background.paper',
                                            zIndex: 9999,
                                            boxShadow: '0px 1px 1px rgba(50, 50, 50, 0.75)'
                                        }}>
                                        <TableRow>
                                            <TableCell align={"center"} colSpan={3}
                                                       sx={{
                                                           fontSize: '14px',
                                                           fontWeight: 700,
                                                           color: 'common.black',
                                                           py: 1, px: 2, border: 0
                                                       }}
                                            >
                                                Overall Total:
                                            </TableCell>
                                            {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                    const revisionPrices: any[] = [];
                                                    vendorDetails.vendorRevisions.forEach((vendorRevision: any) => {
                                                        vendorRevision.eventVendorDetails.forEach((eventVendorDetails: any) => {
                                                            eventPackageDetails.map((packageDetails: any) => {
                                                                packageDetails.items.map((item: any) => {
                                                                    if (eventVendorDetails.packageItemId == item.id) {
                                                                        let vendorPrice = ((eventVendorDetails.price + eventVendorDetails.domainCharges.total) * item.totalQuantity);
                                                                        revisionPrices.push({price: vendorPrice, revision: vendorRevision.revision, eventVendorId: vendorDetails.id});
                                                                    }
                                                                });
                                                            });
                                                        });
                                                    });

                                                    const cells: any[] = [];
                                                    for (let i = 1; i <= event.currentRevision; i++) {
                                                        let cellDetails = 0;
                                                        revisionPrices.forEach((cellData: any) => {
                                                            if (i == cellData.revision) {
                                                                cellDetails += cellData.price;
                                                            }
                                                        });
                                                        cells.push(
                                                            <TableCell
                                                                key={"accept-bid-" + idx}
                                                                align="center"
                                                                sx={{
                                                                    border: 0,
                                                                    p: 0,
                                                                }}
                                                            >
                                                                <Typography sx={{fontSize: "12px"}}>
                                                                    {cellDetails > 0 ? getPriceInFormatted(cellDetails, event.project.country) : "NA"}
                                                                </Typography>
                                                            </TableCell>
                                                        );
                                                    }
                                                    return (
                                                        <TableCell key={"accept-bid-for-" + idx} sx={{border: 0, p:0}}>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableRow>
                                                                        {cells}
                                                                    </TableRow>
                                                                </Table>
                                                            </TableContainer>
                                                        </TableCell>
                                                    )
                                                }
                                            })}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{p: 1, px: 2, border: 0}}/>
                                            {/*<TableCell*/}
                                            {/*    colSpan={2}*/}
                                            {/*    align="left"*/}
                                            {/*    sx={{*/}
                                            {/*        fontSize: "14px",*/}
                                            {/*        fontWeight: 700,*/}
                                            {/*        color: 'common.black',*/}
                                            {/*        py: 1,*/}
                                            {/*        px: 2,*/}
                                            {/*        border: 0*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    Selected Total:*/}
                                            {/*</TableCell>*/}
                                            {/*{columnSelectedTotals.map((total: any, idx: any) => (*/}
                                            {/*    <TableCell*/}
                                            {/*        key={idx}*/}
                                            {/*        align="left"*/}
                                            {/*        sx={{fontSize: "14px", fontWeight: 600, py: 1, px: 2, border: 0}}*/}
                                            {/*    >*/}
                                            {/*        {getPriceInFormatted(total, "")}*/}
                                            {/*    </TableCell>*/}
                                            {/*))}*/}
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={style.footer} className="sticky-footer">
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                            <Grid item>
                                <Button variant="outlined" onClick={handleClose} sx={{marginRight: '4px'}}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={handleSaveBreakBids} sx={{marginLeft: '4px'}}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
        ;
};

export default EventBreakBidsPopup;
