import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {getPriceInFormatted} from "../../../../utils/Common";
import skus from "../../../../modules/catalogue/skus/Skus";
import {hasAccess} from "../../../../utils/Access";
import {Link} from "react-router-dom";

const ChangeEntityPrice = (props: any) => {
    const {vendorEntities, brandEntities, sku} = props;

    vendorEntities.forEach((entity: any) => {
        sku?.entityPrices.map((entityPrice: any) => {
            if (entityPrice.entityId == entity.id) {
                entityPrice.name = entity.name;
                entityPrice.vendor = entity;
            }
        })
    });

    brandEntities.forEach((entity: any) => {
        sku?.entityPrices.map((entityPrice: any) => {
            if (entityPrice.entityId == entity.id) {
                entityPrice.name = entity.name;
            }
        })
    });


    const handlePriceSelection = (entityPrice: any) => {
        sku.selectedEntity = entityPrice;
        sku.sellingPrice = entityPrice.sellingPrice;
        props.handleVendorSelection(sku, entityPrice);
    }

    return (
        <Box>
            <Box className={'center-aligned'}>
                <Box sx={{width: '80%'}}>
                    <Typography variant={"h4"} sx={{pb:2}}>Vendor Details</Typography>
                    <Box>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{backgroundColor: "info.light",}}>
                                        <TableCell>Vendor Name</TableCell>
                                        <TableCell>City</TableCell>
                                        <TableCell>GSV</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{border: 1, borderColor: 'divider'}}>
                                    {sku?.entityPrices.map((entityPrice: any) => {
                                        let entityName = "Vendor"
                                        if(entityPrice.entityName == 'brand')
                                            entityName = "Brand";
                                        // let selected = false;
                                        // if (entityPrice.id == props.sku?.selectedEntity?.id) {
                                        //     selected = true;
                                        // }
                                        return (
                                            <TableRow
                                                key={entityPrice.id}
                                                sx={{border: 1, borderColor: 'divider'}}
                                            >
                                                <TableCell
                                                    sx={{border: 1, borderColor: 'divider'}}>{entityPrice.name}</TableCell>
                                                <TableCell sx={{border: 1, borderColor: 'divider'}}>
                                                    {entityPrice.vendor.city}
                                                </TableCell>
                                                <TableCell sx={{border: 1, borderColor: 'divider'}}>
                                                    {getPriceInFormatted(entityPrice.sellingPrice, "")}
                                                </TableCell>
                                                <TableCell sx={{border: 1, borderColor: 'divider'}}>
                                                    {hasAccess(['view-vendors', 'edit-vendors']) && (
                                                        <Link to={`/vendors/details/${entityPrice.entityId}`} target={"_blank"}>
                                                            View Vendor
                                                        </Link>
                                                    )}
                                                </TableCell>
                                                {/*<TableCell sx={{border: 1, borderColor: 'divider'}}>*/}
                                                {/*    {selected ? (*/}
                                                {/*        <Typography>Currently Selected</Typography>*/}
                                                {/*    ) : (*/}
                                                {/*        <Typography*/}
                                                {/*            onClick={() => handlePriceSelection(entityPrice)}*/}
                                                {/*            sx={{cursor: 'pointer', color: 'primary.main'}}>*/}
                                                {/*            Select {entityName}*/}
                                                {/*        </Typography>*/}
                                                {/*    )}*/}
                                                {/*</TableCell>*/}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ChangeEntityPrice;