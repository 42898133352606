import {ESTIMATION_RANGE} from "../constants";

export default function camelize(data: any) {
    if (typeof data !== 'object' || data === null) return data;

    const obj: any = {};
    for (let [key, val] of Object.entries(data)) {
        key = key.replace(/[\-_\s]+(.)?/g, (_, ch) => ch ? ch.toUpperCase() : '');
        key = key.substring(0, 1).toLowerCase() + key.substring(1);

        obj[key] = camelize(val);
    }
    return obj;
}

export const getContentUrl = () => {
    return process.env.REACT_APP_CONTENT_URL;
}

export const getPriceInFormatted = (price: any, country: string) => {
    if (price == undefined || isNaN(price)) {
        price = 0;
    }
    const currencySymbol = getCurrencySymbol(country);

    if (price < 0) {
        return "- " + currencySymbol + " " + (-price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return currencySymbol + " " + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const getFormattedDate = (dateString: string) => {
    if (dateString == undefined) {
        return 'NA';
    }
    const date = new Date(dateString);
    const day = date.toLocaleString('default', {day: '2-digit'});
    const month = date.toLocaleString('default', {month: 'short'});
    const year = date.toLocaleString('default', {year: 'numeric'});
    return day + '-' + month + '-' + year;
}


export const getFormattedTime = (dateString: string) => {
    if (dateString == undefined) {
        return 'NA';
    }

    let dateIST = new Date(dateString);
    let utcEnabled = Number(process.env.REACT_APP_UTC_TIME_ENABLED);
    if (utcEnabled) {
        dateIST.setHours(dateIST.getHours() + 5);
        dateIST.setMinutes(dateIST.getMinutes() + 30);
    }

    let newTime = new Date(dateIST).toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: true});
    return newTime;
}

export const getFormattedDateTime = (dateTimeString: string) => {
    if (dateTimeString == undefined) {
        return 'NA';
    }

    return getFormattedDate(dateTimeString) + " "+ getFormattedTime(dateTimeString);
}

export const getCurrencySymbol = (country: string) => {
    switch (country) {
        case 'India':
            return "₹";
        case 'Singapore':
            return "S$";
        default:
            return "₹"
    }
}

export const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

export const convertToRoman = (num: number) => {
    let numberArr = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    let RomanArr = [
        "M",
        "CM",
        "D",
        "CD",
        "C",
        "XC",
        "L",
        "XL",
        "X",
        "IX",
        "V",
        "IV",
        "I"
    ];
    let result = [];

    const findElement = (e: any) => {
        return e <= num;
    };

    while (num > 0) {
        let nextHighest = numberArr.find(findElement);

        if (nextHighest != null) {
            result.push(RomanArr[numberArr.indexOf(nextHighest)]);
            num -= nextHighest;
        }
    }
    let newResult = result.join("");

    return newResult;
}

export const getSubCategoryRange = (code: string, designType: string) => {
    const allRange: any = ESTIMATION_RANGE;
    let subCategoryDetails = allRange[code];
    let rangeData: any = null;
    if (subCategoryDetails != undefined) {
        switch (designType) {
            case "Cost Effective Design Option":
                if (subCategoryDetails.ranges.costEffective != undefined) {
                    rangeData = subCategoryDetails.ranges.costEffective;
                }
                break;
            case "Standard Design Option":
                if (subCategoryDetails.ranges.standard != undefined) {
                    rangeData = subCategoryDetails.ranges.standard;
                }
                break;
            case "Design Strong Option":
                if (subCategoryDetails.ranges.strong != undefined) {
                    rangeData = subCategoryDetails.ranges.strong;
                }
                break;
        }
    }
    return rangeData;
}