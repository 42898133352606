import {SyntheticEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {loadItem, loadItemCategories} from "../../../modules/project/features/actions";
import CategoryTab from "../../tabs/CategoryTab";
import SubCategoryTab from "../../tabs/SubCategoryTab";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {a11yProps, CustomTabPanel} from "../../../utils/TabsUtils";
import ItemSkuListing from "../../skus/listing/ItemSkuListing";
import SearchFilter from "../../filter/SearchFilter";
import {api} from "../../../utils/Axios";

const BrandCatalogTab = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [brandCategories, setBrandCategories] = useState<any>([]);
    const [categories, setCategories] = useState([]);
    const [brandItemCategories, setBrandItemCategories] = useState<any>([]);
    const [brandItems, setBrandItems] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [categoryTabValue, setCategoryTabValue] = useState("");
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");
    const [itemCategoryTabValue, setItemCategoryTabValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItemCategories, setFilteredItemCategories] = useState([]);
    const itemCategoriesData: any = useAppSelector((state) => state.categories.itemCategories);
    const itemsData = useAppSelector((state: any) => state.categories.items);
    const dispatch = useAppDispatch();

    const handleSearchFilter = (query: any) => {
        const queryString = typeof query === 'string' ? query : '';
        setSearchQuery(queryString);
        if (!queryString) {
            setFilteredItemCategories(itemCategoriesData);
            setItemCategoryTabValue(itemCategoriesData[0]?.id || "");
            return;
        }
        const filtered = itemCategoriesData.filter((category: any) =>
            category.name.toLowerCase().includes(queryString?.toLowerCase())
        );
        setFilteredItemCategories(filtered);
        setItemCategoryTabValue(filtered.length > 0 ? filtered[0]?.id || "" : itemCategoriesData[0]?.id || "");
    };

    const handleCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
    };

    const handleItemCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setItemCategoryTabValue(newValue);
    };

    useEffect(() => {
        if (categoryTabValue !== "") {
            brandCategories.forEach((category: any) => {
                if (category.id === categoryTabValue) {
                    setSelectedCategory(category);
                    setSubCategoryTabValue(category.subCategories[0]?.id || "");
                }
            });
        }
    }, [categoryTabValue]);

    useEffect(() => {
        const fetchItemCategories = async () => {
            if (subCategoryTabValue !== "") {
                dispatch(loadItemCategories(subCategoryTabValue));
            }
        };

        fetchItemCategories();
    }, [subCategoryTabValue]);

    useEffect(() => {
        const fetchItems = async () => {
            if (itemCategoryTabValue !== "") {
                dispatch(loadItem(itemCategoryTabValue));
            }
        };
        fetchItems();
    }, [itemCategoryTabValue]);

    useEffect(() => {
        if (itemCategoriesData && itemCategoriesData.length > 0) {
            const updatedItemCategories: any[] = itemCategoriesData.filter((itemCategory: any) => {
                return itemCategory.brandAvailable;
            });
            if (updatedItemCategories.length > 0) {
                setBrandItemCategories(updatedItemCategories);
                setItemCategoryTabValue(updatedItemCategories[0].id);
            }
        }
    }, [itemCategoriesData]);

    useEffect(() => {
        if (itemsData && itemsData.length > 0) {
            const updatedItems: any[] = itemsData.filter((item: any) => {
                return item.brandAvailable;
            });
            if (updatedItems.length > 0) {
                setBrandItems(updatedItems);
            }
        }
    }, [itemsData]);

    useEffect(() => {
        const updatedCategories = categories.map((category: any) => {
            const subCategories = category.subCategories.filter((subCategory: any) => {
                return subCategory.brandAvailable;
            });
            if (subCategories.length > 0 && categoryTabValue == "") {
                setCategoryTabValue(category.id);
            }
            return {...category, subCategories};
        });

        setBrandCategories(updatedCategories);
    }, [categories]);

    const loadCategories = () => {
        setIsLoading(true);
        api.get("/catalogue/material-master/categories-with-subcategories").then((response) => {
            return response.data;
        }).then((response: any) => {
            setCategories(response);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadCategories();
    }, []);

    return (
        <>
            {categoryTabValue !== "" && (
                <CategoryTab
                    showCount={true}
                    hideZeroSkus={true}
                    includeSummaryTab={false}
                    categories={brandCategories}
                    categoryTabValue={categoryTabValue}
                    handleCategoryTabChange={handleCategoryTabChange}
                />
            )}
            {selectedCategory.hasOwnProperty("id") && categoryTabValue !== "summary" && (
                <SubCategoryTab
                    showCount={true}
                    hideZeroSkus={true}
                    subCategoryTabValue={subCategoryTabValue}
                    subCategories={selectedCategory?.subCategories}
                    categoryTabValue={categoryTabValue}
                    handleSubCategoryTabChange={handleSubCategoryTabChange}
                />
            )}
            <Box sx={{display: "flex"}}>
                {brandItemCategories && brandItemCategories.length > 0 && (
                    <CustomTabPanel
                        width={"15%"}
                        value={subCategoryTabValue}
                        index={subCategoryTabValue}
                    >
                        <Box sx={{pr: 2, borderRight: 1, borderColor: "divider"}}>
                            <Box mt={2}>
                                <SearchFilter query={searchQuery} handleSearchFilter={handleSearchFilter}
                                              showSearch={true}/>
                            </Box>
                            <Typography sx={{py: 3, px: 2, fontWeight: 700}}>
                                Item Category
                            </Typography>
                            <Tabs
                                orientation={"vertical"}
                                variant={"scrollable"}
                                value={itemCategoryTabValue}
                                onChange={handleItemCategoryTabChange}
                                aria-label="Item Category Tabs"
                                className="tab-sidebar"
                            >
                                {filteredItemCategories && filteredItemCategories.length > 0 ? (
                                    filteredItemCategories.map((itemCategory: any, itemCategoryIndex: number) => {
                                        if (itemCategory.totalSkus > 0) {
                                            return (
                                                <Tab
                                                    sx={{
                                                        alignItems: "flex-start",
                                                        minHeight: "35px",
                                                        borderRadius: 1,
                                                        color: "info.main",
                                                        '&.Mui-selected': {
                                                            color: "info.main",
                                                            fontWeight: 700,
                                                        },
                                                    }}
                                                    key={"item-category-tab-" + itemCategoryIndex}
                                                    value={itemCategory.id}
                                                    {...a11yProps(itemCategory.id, false)}
                                                    label={
                                                        <Typography className="tab-sidebar-label">
                                                            {itemCategory.name}&nbsp;
                                                            <Typography component={"sup"}
                                                                        sx={{fontSize: '10px'}}>({itemCategory.totalSkus})</Typography>
                                                        </Typography>
                                                    }
                                                    wrapped
                                                    className="tab-sidebar-item"
                                                />
                                            )
                                        }
                                    })
                                ) : (
                                    itemCategoriesData && itemCategoriesData.length > 0 && itemCategoriesData.map((itemCategory: any, itemCategoryIndex: number) => {
                                        if (itemCategory.totalSkus > 0) {
                                            return (
                                                <Tab
                                                    sx={{
                                                        alignItems: "flex-start",
                                                        minHeight: "35px",
                                                        borderRadius: 1,
                                                        color: "info.main",
                                                        '&.Mui-selected': {
                                                            color: "info.main",
                                                            fontWeight: 700,
                                                        },
                                                    }}
                                                    key={"item-category-tab-" + itemCategoryIndex}
                                                    value={itemCategory.id}
                                                    {...a11yProps(itemCategory.id, false)}
                                                    label={
                                                        <Typography className="tab-sidebar-label">
                                                            {itemCategory.name}&nbsp;
                                                            <Typography component={"sup"}
                                                                        sx={{fontSize: '10px'}}>({itemCategory.totalSkus})</Typography>
                                                        </Typography>
                                                    }
                                                    wrapped
                                                    className="tab-sidebar-item"
                                                />
                                            )
                                        }
                                    })
                                )}
                            </Tabs>
                        </Box>
                    </CustomTabPanel>
                )}

                {itemCategoryTabValue != "" && (
                    <CustomTabPanel
                        width={"85%"}
                        value={itemCategoryTabValue}
                        index={itemCategoryTabValue}
                    >
                        <Box sx={{p: 2}}>
                            {brandItems && brandItems.map((item: any, itemIndex: number) => {
                                return (
                                    <Box key={"item-block-" + itemIndex}>
                                        {item?.id ? (
                                            <>
                                                <ItemSkuListing type={"brand"} item={item}/>
                                            </>
                                        ) : (
                                            <Box className={"center-aligned"}>
                                                <Typography>No Items and SKUs found</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    </CustomTabPanel>
                )}
            </Box>
        </>
    )
}
export default BrandCatalogTab;