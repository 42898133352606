import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import {Grid, Typography} from "@mui/material";
import TableOverview from "../home/TableOverview";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {loadItemCategories} from "./features/action";
import Loader from '../../components/Loader';

const styles = {
    labelHeading: {
        width: '100%',
        marginBottom: '10px',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
        color: 'info.main'
    },
    details: {
        width: '100%',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '15px',
        color: '#525983'
    }
}

function CatalogueList(props: any) {
    const [selectedIndex, setSelectedIndex] = React.useState("");
    const itemCategoriesData = useAppSelector((state) => state.catalog.itemCategories);
    const loading = useAppSelector((state) => state.catalog.loading);
    const dispatch = useAppDispatch();

    const handleListItemClick = (event: any, subCategory: any) => {
        setSelectedIndex(subCategory.id);
    };

    useEffect(() => {
        if (selectedIndex != "")
            dispatch(loadItemCategories(selectedIndex));
    }, [selectedIndex]);

    useEffect(() => {
        if (props.subCategoriesData && props.subCategoriesData.length > 0) {
            setSelectedIndex(props.subCategoriesData[0].id);
        }
    }, [props.subCategoriesData]);

    return (
        <Grid container>
            <Grid item xs={5}>
                <Box className="cat_sum_subcat_tabs">
                    <Typography sx={{fontSize: '0.9rem', margin: '32px 0 16px 0', color: '#8493a3'}}>
                        Material Sub Category
                    </Typography>
                    <Box className={"subcategory_summary_tab_list"}
                         sx={{
                             width: '100%',
                             maxHeight: 424,
                             overflow: 'scroll',
                             bgcolor: 'background.paper',
                             border: '1px solid #f8f8f8'
                         }}>
                        <List component="nav" aria-label="main mailbox folders" sx={{padding: '0'}}>
                            {props.subCategoriesData && props.subCategoriesData.length > 0 && props.subCategoriesData.map((subCategory: any, subCategoryIndex: number) => {
                                if (subCategory.totalSkus > 0) {
                                    return (
                                        <ListItemButton
                                            key={"sub-category-report-" + subCategory.id}
                                            selected={selectedIndex === subCategory.id}
                                            className={selectedIndex === subCategory.id ? 'selected-list' : 'list'}
                                            onClick={(event) => handleListItemClick(event, subCategory)}
                                            sx={{flexDirection: 'colum'}}
                                        >
                                            <Box sx={{display: 'flex', flexDirection: 'column', flex: '100%'}}>
                                                <Box sx={{width: '100%', marginBottom: '10px'}}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "18px",
                                                            textAlign: "left",
                                                            color: '#525983'
                                                        }}>{subCategory.name}</Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flex: '100%',
                                                        width: '100%'
                                                    }}>
                                                    <Box sx={{flex: '30%'}}>
                                                        <Typography sx={styles.labelHeading}>
                                                            Total Skus
                                                        </Typography>
                                                        <Typography sx={styles.details}>
                                                            {subCategory.totalSkus}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{flex: '30%'}}>
                                                        <Typography sx={styles.labelHeading}>
                                                            With RC
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                width: '100%',
                                                                fontSize: '12px',
                                                                fontWeight: 400,
                                                                lineHeight: '15px',
                                                                color: '#525983'
                                                            }}>
                                                            {subCategory.totalRcSkus}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{flex: '30%'}}>
                                                        <Typography sx={styles.labelHeading}>
                                                            Without RC
                                                        </Typography>
                                                        <Typography sx={styles.details}>
                                                            {parseInt(subCategory.totalSkus) - parseInt(subCategory.totalRcSkus)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </ListItemButton>
                                    );
                                }
                            })}
                        </List>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={7}>
                <Box sx={{px: 2, position: 'relative'}}>
                    {loading ? (
                        <Loader/>
                    ) : (
                        <>
                            {(itemCategoriesData && itemCategoriesData.length > 0) &&
                                <Box className="customised_table_view" sx={{width: '100%'}}>
                                    <Typography sx={{fontSize: '0.9rem', margin: '32px 0 16px 0', color: '#8493a3'}}>
                                        Item Category
                                    </Typography>
                                    <TableOverview
                                        source={"catalog"}
                                        hideFooter={true}
                                        width={'100%'}
                                        margin={'0'}
                                        classElem={"catalogue_summary_table"}
                                        data={itemCategoriesData}
                                    />
                                </Box>
                            }
                        </>
                    )}

                </Box>
            </Grid>
        </Grid>
    );
}

export default CatalogueList;