import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import React, {useEffect, useState} from "react";
import {loadCatalogReport} from "../../../modules/catalogue/features/action";
import {Box, Grid, Typography} from "@mui/material";
import CatalogueList from "../../../modules/catalogue/CatalogueList";
import '../../../modules/catalogue/Summary.css';
import {api} from "../../../utils/Axios";
import Loader from "../../Loader";

const SummaryTab = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBreakIndex, setSelectedBreakIndex] = React.useState(0);
    const [selectedCategory, setSelectedCategory] = React.useState<any>({});
    const [categories, setCategories] = useState([]);
    const catalogCount = useAppSelector((state) => state.catalog.catalogCount);
    const dispatch = useAppDispatch();

    const handleBreakupItemClick = (event: any, category: any, categoryIndex: any) => {
        setSelectedBreakIndex(categoryIndex);
        setSelectedCategory(category);
    };

    const loadCategories = () => {
        setIsLoading(true);
        api.get("/catalogue/material-master/categories-with-subcategories").then((response) => {
            return response.data;
        }).then((response: any) => {
            setCategories(response);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if(categories.length > 0) {
            setSelectedCategory(categories[0]);
            dispatch(loadCatalogReport(false));
        }
    }, [categories]);

    useEffect(() => {
        loadCategories();
    }, []);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Grid container spacing={4} className="catalogue_summary_view">
            <Grid item xs>
                <Box
                    sx={{border: 1, borderColor: 'divider', boxShadow: 1, borderRadius: 1}}>
                    <Box className="home-box-title-outer">
                        <Typography className="home-box-title">Catalogue Overview</Typography>
                    </Box>
                    <Box sx={{padding: '32px 45px'}}>
                        <Box
                            sx={{minHeight: '50px', textAlign: 'left', display: 'flex', flexDirection: 'row', width: '65%', minWidth: '600px', margin: '0 0 35px', overflow: 'hidden'}}>
                            <Box sx={{flex: '20%'}}>
                                <Typography className="home-box-elem-title">RC Vendors</Typography>
                                <Typography
                                    className="home-box-elem-count">{catalogCount?.catalogData?.rcVendors}</Typography>
                            </Box>
                            <Box sx={{flex: '20%'}}>
                                <Typography className="home-box-elem-title">ITEM CATEGORY</Typography>
                                <Typography className="home-box-elem-count">{catalogCount?.catalogData?.itemCategories}</Typography>
                            </Box>
                            <Box sx={{flex: '20%'}}>
                                <Typography className="home-box-elem-title">TOTAL ITEMS</Typography>
                                <Typography className="home-box-elem-count">{catalogCount?.catalogData?.items}</Typography>
                            </Box>
                            <Box sx={{flex: '20%'}}>
                                <Typography className="home-box-elem-title">TOTAL SKU'S</Typography>
                                <Typography className="home-box-elem-count">{catalogCount?.catalogData?.skus}</Typography>
                            </Box>
                            <Box sx={{flex: '20%'}}>
                                <Typography className="home-box-elem-title">SKUS WITH RC</Typography>
                                <Typography className="home-box-elem-count">{catalogCount?.catalogData?.rcSkus}</Typography>
                            </Box>
                            <Box sx={{flex: '20%'}}>
                                <Typography className="home-box-elem-title">SKUS WITHOUT RC</Typography>
                                <Typography
                                    className="home-box-elem-count">{(!isNaN(catalogCount?.catalogData?.skus) && !isNaN(catalogCount?.catalogData?.rcSkus)) ? (catalogCount?.catalogData?.skus - catalogCount?.catalogData?.rcSkus) : ""}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{width: 'auto', margin: '0 '}}>
                            <Box sx={{fontSize: '12px', fontWeight: 400, lineHeight: '14.4px', textAlign: 'left'}}>
                                View Breakup:
                                {
                                    categories.map((category: any, categoryIndex: number) => {
                                        return (
                                            <Typography
                                                key={"cat-summary-selection-" + category.id}
                                                className={"cat_summary_selection " + (selectedBreakIndex === categoryIndex ? "selected" : "disabled")}
                                                onClick={(event) => handleBreakupItemClick(event, category, categoryIndex)}>{category.name}
                                            </Typography>
                                        )
                                    })}
                            </Box>
                        </Box>
                        <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            <Box className={"summary_caterogy_details"} sx={{margin: '32px'}}>
                                <Box className={"summary_caterogy_detail_item"}
                                     sx={{backgroundColor: '#fafafa', padding: '10px 25px'}}>
                                    <Typography sx={{ margin: '0 0 10px', fontSize: '12px', fontWeight: 400, lineHeight: '14.4px', color: '#7A8A9C'}}>Material
                                        Category</Typography>
                                    <Typography
                                        sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '24px', color: '#1E2B51'}}>{selectedCategory && Object.keys(selectedCategory).length > 0 && selectedCategory?.name}</Typography>
                                    <Box sx={{display: 'flex', flexDirection: 'column', flex: '100%', width: '100%'}}>
                                        <Box sx={{flex: '30%', margin: '10px 0'}}>
                                            <Typography sx={{width: '100%', fontSize: '12px', fontWeight: 400, lineHeight: '24px', color: '#1E2B51'}}>Total
                                                Skus</Typography>
                                            <Typography
                                                sx={{width: '100%', fontSize: '12px', fontWeight: 700, lineHeight: '24px', color: '#1E2B51'}}>{selectedCategory?.totalSkus}</Typography>
                                        </Box>
                                        <Box sx={{flex: '30%', margin: '10px 0'}}>
                                            <Typography sx={{width: '100%',fontSize: '12px', fontWeight: 400, lineHeight: '24px', color: '#1E2B51'}}>With
                                                RC</Typography>
                                            <Typography
                                                sx={{width: '100%', fontSize: '12px', fontWeight: 700, lineHeight: '24px', color: '#1E2B51'}}>{selectedCategory?.totalRcSkus}</Typography>
                                        </Box>
                                        <Box sx={{flex: '30%', margin: '10px 0'}}>
                                            <Typography sx={{width: '100%', fontSize: '12px', fontWeight: 400, lineHeight: '24px', color: '#1E2B51'}}>Without
                                                RC</Typography>
                                            <Typography
                                                sx={{width: '100%', fontSize: '12px', fontWeight: 700, lineHeight: '24px', color: '#1E2B51'}}>{parseInt(selectedCategory?.totalSkus) - parseInt(selectedCategory?.totalRcSkus)}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{width: '79%', display: 'flex'}}>
                                {selectedCategory && Object.keys(selectedCategory).length > 0 &&
                                    <CatalogueList subCategoriesData={selectedCategory?.subCategories}/>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default SummaryTab;